import {
    GET_RESERVATION_CALENDER_DATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function reservationDateSettingReducer(reservationsDateSettings = initialState, action) {
    const { type, payload } = action;
    // console.log(type, payload);
    switch (type) {
  
      case GET_RESERVATION_CALENDER_DATES:
        return payload;
  
      default:
        return reservationsDateSettings;
    }
  }
  
  export default reservationDateSettingReducer;
  
  