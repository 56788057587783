import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "../common/message";
import { Row,  Form} from "react-bootstrap";
import moment from "moment-timezone";
// import { _pre } from "../../utils/vlogs";
import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import { createReservationByQr,  getRestaurantByTableId } from "../../actions/qr-booking";
import Cookie from 'universal-cookie';


class QrBookingStep3 extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    data: {
        title: "0",
        first_name: "",
        last_name: "",
        phone_number: localStorage.getItem("phone"),
        email: "",
        plz: "",
        delete_check: "",
        privacy_policy: "",
        
    },

      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const cookies = new Cookie();
      this.getRecord(parseInt(this.props.match.params.id.substring(4)));
      let status = cookies.get('is_verified');
      let step = cookies.get('step');
      if(status){
        this.props.history.push("/qr-booking/step" + step + "/tid=" +parseInt(this.props.match.params.id.substring(4)));
      }
    }
    //
  }

  getRecord(id){
    this.setState({
      loading: true,
    });
    let values = {
      table_id: id,
    };
    this.props
      .getRestaurantByTableId(values)
      .then((data) => {
        console.log(data.data)
        if (data.status) {
          // const { detail: currentRecord } = data;
          this.setState({
            restaurant: data.data,
          });
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  schema =  yup.object().shape({
    title: yup.string().required('qrcodeBooking.addformstep3.validation.title'),
    first_name: yup.string().required('qrcodeBooking.addformstep3.validation.first_name'),
    last_name: yup.string().required('qrcodeBooking.addformstep3.validation.last_name'),
    email: yup.string().email('qrcodeBooking.addformstep3.validation.validemail').required('qrcodeBooking.addformstep3.validation.email'),
    plz: yup.string().required('qrcodeBooking.addformstep3.validation.plz'),
    delete_check: yup.string().required(),
    privacy_policy: yup.string().required(),
    
    
    });
  
    async doSubmit(values){
      console.log(values);
      values.table_id = parseInt(this.props.match.params.id.substring(4));
      
      await this.props
      .createReservationByQr(values)
      .then((data) => {
        // console.log(data, "sssssssssssssss");
        // //console.log(data.status, data.message);
        if (data.status) {
          toast.success(data.message);
          const cookies = new Cookie();
          cookies.set('is_verified', true, { path: '/' })
          cookies.set('step', 4, { path: '/' })
          this.props.history.push("/qr-booking/step4/tid=" +parseInt(this.props.match.params.id.substring(4)));
        } else {
          toast.error(data.message);
          // this.props.history.push("/qr-booking/step3/tid=" +parseInt(this.props.match.params.id.substring(4)));
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        //console.log(e);
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.message);
        // console.log(e.response.data,"ddddddddddddddddddddd");
      });
      // this.props.history.push("/qr-booking/step4/tid="+parseInt(this.props.match.params.id.substring(4)));

    }
  render() {
    const { message } = this.props;
    const { restaurant } = this.state;
    console.log(this.state);
    if(restaurant){
        return (
          <div className="signUP-admin1">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 card my-3">
                  <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                    
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-12">
                        <div className="mt-0">
                          <div className="card border-0">
                            <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                              <div className="edit-profile__title">
                                <h6>
                                {this.props.t('qrcodeBooking.step3title')}{" "}
                                
                                </h6>
                                <p className="font-weight-bold">  {this.props.t('qrcodeBooking.table')} {restaurant.table_number} / {restaurant.restaurant_name}</p>
                                <p> {moment.tz("Europe/Berlin").format('DD.MM.YYYY')}  {moment.tz("Europe/Berlin").format("HH:mm:ss")} </p>
                              </div>
                            </div>
                            <div className="card-body">
                            <div className="form-group mb-20">
                            <Message message={message} />
                            
                          </div>
                            <Formik
                            validationSchema={this.schema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize="true"
                            onSubmit={(values) => {
                              console.log(values);
                              this.doSubmit(values);
                            }}
                            initialValues={{
                                title: this.state.data.title,
                                first_name: this.state.data.first_name,
                                last_name: this.state.data.last_name,
                                phone_number: this.state.data.phone_number,
                                email: this.state.data.email,
                                plz: this.state.data.plz,
                                delete_check:this.state.data.delete_check,
                                privacy_policy: this.state.data.privacy_policy,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            
                            }) => (
                            
                              <Form noValidate onSubmit={handleSubmit}>
                              <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lableTitle"
                                    )}
                                  </Form.Label>
                                  <Form.Select
                                    name="title"
                                    className="form-control col-md-8"
                                    onChange={handleChange}
                                    value={values.title}
                                    isInvalid={!!errors.title}
                                  >
                                  <option value="0">{this.props.t("user.addForm.usertitleOption.mr")}</option>
                                  <option value="1">{this.props.t("user.addForm.usertitleOption.mrs")}</option>
                                  </Form.Select>
                                  {console.log(errors)}
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                    { this.props.t(errors.title)}
                                  </Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("qrcodeBooking.addformstep3.addForm.lablefirst")}
                                  </Form.Label>
                                  <Form.Control
                                    className="col-md-8"
                                    type="text"
                                    name="first_name"
                                    placeholder={this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lablefirst"
                                    )}
                                    value={values.first_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.first_name}
                                  />
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                    { this.props.t(errors.first_name)}
                                  </Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("qrcodeBooking.addformstep3.addForm.lablelast")}
                                  </Form.Label>
                                  <Form.Control
                                    className="col-md-8"
                                    type="text"
                                    name="last_name"
                                    placeholder={this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lablelast"
                                    )}
                                    value={values.last_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.last_name}
                                  />
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                      { this.props.t(errors.last_name)}
                                  
                                  </Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("qrcodeBooking.addformstep3.addForm.lablePlz")}
                                  </Form.Label>
                                  <Form.Control
                                    className="col-md-8"
                                    type="text"
                                    name="plz"
                                    placeholder={this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lablePlz"
                                    )}
                                    value={values.plz}
                                    onChange={handleChange}
                                    isInvalid={!!errors.plz}
                                  />
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                      { this.props.t(errors.plz)}
                                  
                                  </Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("qrcodeBooking.addformstep3.addForm.lableMobile")}
                                  </Form.Label>
                                  <Form.Control
                                    className="col-md-8"
                                    name="phone_number"
                                    placeholder={this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lableMobile"
                                    )}
                                    value={values.phone_number}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone_number}
                                    readOnly
                                    type="text"
                                  />
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                  { this.props.t(errors.phone_number)}
                                  </Form.Control.Feedback>
                                </Row>
                                <Row className="mb-3">
                                  <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("qrcodeBooking.addformstep3.addForm.lableEmail")}
                                  </Form.Label>
                                  <Form.Control
                                    className="col-md-8"
                                    name="email"
                                    placeholder={this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lableEmail"
                                    )}
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                    type="email"
                                    autoComplete="new-password"
                                  />
                                  <Form.Control.Feedback
                                    className="col-md-8 offset-4"
                                    type="invalid"
                                  >
                                  { this.props.t(errors.email)}
                                  </Form.Control.Feedback>
                                </Row>

                                <Form.Group className="col-md-8 offset-4 mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" name="delete_check" value={values.delete_check}
                                    onChange={handleChange}  isInvalid={!!errors.delete_check} />
                                    <Form.Label >
                                    {this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lableDeleteCheckbox"
                                    )}  
                                    </Form.Label>
                                </Form.Group>


                                <Form.Group className="col-md-8 offset-4 mb-3" controlId="formBasicCheckbox1">
                                    <Form.Check type="checkbox"  name="privacy_policy" value={values.privacy_policy}
                                    onChange={handleChange}  isInvalid={!!errors.privacy_policy} />
                                    <Form.Label >
                                    {this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.lableAcceptCheckbox"
                                    )}  
                                    <Link to="/">{this.props.t(
                                      "qrcodeBooking.addformstep3.addForm.privacy_policy"
                                    )}</Link>
                                    </Form.Label>
                                </Form.Group>
                                                                                    
                                <div className="row mt-4">
                                  <div className="col-md-4 offset-4 p-0">
                                    <button
                                    className="btn btn-primary btn-default btn-squared mr-15 lh-normal px-40 py-15 signIn-createBtn "
                                    disabled={this.state.loading}
                                    >
                                    {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>{this.props.t('qrcodeBooking.addformstep3.buttonSave')}</span>
                                    </button>
                                </div>
                              </div>
                              </Form>
                            )}
                          </Formik>

                            
                            </div>
                          </div>
                        {/* <p className="social-connector text-right mb-sm-25 mb-15  mt-sm-30 mt-20">
                            <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                        </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }else{
        return(
          <>
          </>
        )
      }
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);
export default compose(withTranslation() ,connect(mapStateToProps, {
  createReservationByQr,
  getRestaurantByTableId
  //deleteAllTutorials,
}))(QrBookingStep3);

