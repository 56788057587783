import {
    CREATE_NOTES,
    RETRIEVE_NOTES,
    UPDATE_NOTES,
    DELETE_NOTES,
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function notesReducer(notes = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_NOTES:
        return [notes, payload];
  
      case RETRIEVE_NOTES:
        return payload;
  
      case UPDATE_NOTES:
        return [];
        // rates.map((rate) => {
        //   if (rate.id === payload.id) {
        //     return {
        //       ...rate,
        //       ...payload,
        //     };
        //   } else {
        //     return rate;
        //   }
        // });
  
      case DELETE_NOTES:
        return [payload]; //return guests.filter(({ id }) => id !== payload.id);
  
    //   case DELETE_ALL_RATES:
    //     return [];
  
      default:
        return notes;
    }
  }
  
  export default notesReducer;
  
  