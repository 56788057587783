import React, { Component } from "react";
//import { Link } from "react-router-dom";
//import { _pre } from "../../utils/vlogs";
//import CommonHelper from "../../utils/CommonHelper";
//import $ from "jquery";

export default class AppFooter extends Component {
  /* constructor(props) {
    super(props);
  } */

  componentDidMount() {}

  render() {
  
    const {  toggled } = this.props;
    // const { currentUser } = this.props;
    let buttonClass = toggled ? "add-padding-footer" : "";
    //_pre(" buttonClass ", buttonClass);
    //_pre(" t ", this.props.toggled);
    return (
      <>
        {/* {currentUser ? (
          <> */}
            <footer className={"footer-wrapper " + buttonClass}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="footer-copyright">
                      <p>
                        2014-2022 @<a href="#!">3W TECHNOLOGIES</a>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="footer-menu text-right">
                      {/* <ul>
                        <li>
                          <a href="#!">About</a>
                        </li>

                        <li>
                          <a href="#!">Team</a>
                        </li>

                        <li>
                          <a href="#!">Contact</a>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          {/* </>
        ) : (
          ""
        )} */}
      </>
    );
  }
}

//export default Header;
