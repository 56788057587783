import {
  CREATE_RESERVATION,
  RETRIEVE_RESERVATIONS,
  UPDATE_RESERVATION,
  DELETE_RESERVATION,
  // DELETE_ALL_RATES,
} from "../actions/types";

const initialState = [];

function reservationReducer(reservations = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_RESERVATION:
      return [reservations, payload];

    case RETRIEVE_RESERVATIONS:
      return payload;

    case UPDATE_RESERVATION:
      return payload;
    // rates.map((rate) => {
    //   if (rate.id === payload.id) {
    //     return {
    //       ...rate,
    //       ...payload,
    //     };
    //   } else {
    //     return rate;
    //   }
    // });

    case DELETE_RESERVATION:
      return [payload]; //return guests.filter(({ id }) => id !== payload.id);

    //   case DELETE_ALL_RATES:
    //     return [];

    default:
      return reservations;
  }
}

export default reservationReducer;
