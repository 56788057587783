import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Dashboard extends Component {
  render() {
    const { user: currentUser } = this.props;
    console.log("Dashboard Data", currentUser);
    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="social-dash-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-main">
                  <h4 className="text-capitalize breadcrumb-title">
                    Dashboard
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <p className="color-dark fw-500 fs-20 mb-0">
                      Welcome, {currentUser.fullName}!{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Dashboard);
