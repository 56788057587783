import React , { Component }from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Row,  Form, Button } from "react-bootstrap";
// import { _pre } from "../../utils/vlogs";
import Message from "../common/message";
import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import {
  createUser,
  getUser,
  updateUser,
  getUserRole,

} from "../../actions/user";
// import { Empty } from "antd";


class AddUser extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      loading: false,
      roles: [],
      data: {
        role_id: "0",
        title: "0",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        user_name: "",
        password: "",
       
    },
      errors: {},
    };
  }


  schema =  yup.object().shape({
    role_id: yup.number().required('user.addForm.validation.role_id'),
    title: yup.string().required('user.addForm.validation.title'),
    first_name: yup.string().required('user.addForm.validation.first_name'),
    last_name: yup.string().required('user.addForm.validation.last_name'),
    email: yup.string().email('user.addForm.validation.validemail').required('user.addForm.validation.email'),
    phone_number: yup.number().typeError('user.addForm.validation.phone_number_valid').required('user.addForm.validation.phone_number'),
    password: yup.string().when('min_space', (min_space) => {
      if ( this.props.match.params.id === undefined) {
        return  yup.string().required('user.addForm.validation.password')
      }
    }).typeError('user.addForm.validation.password'),
    });

 
  async componentDidMount() {
    // console.log('hhhhhhhhhhhhhhhhhhhhhh');
    if (this.props.match.params.id) {
      await this.getRecord(this.props.match.params.id);
    }
    await this.getRoleId();

  }

  getRoleId(){
    this.setState({
      loading: true,
    });
    this.props
      .getUserRole()
      .then((data) => {
    
        if (data.status) {
          this.setState({
            roles: [...data.data],
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/users");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
    
  }
  getRecord(id) {
    
    this.setState({
      loading: true,
    });
    this.props
      .getUser(id)
      .then((data) => {
    
        if (data.status) {
          const { detail: currentRecord } = data.data;
          this.setState({
            data: {
              role_id:
              currentRecord.user_role_id !== ""
                ? currentRecord.user_role_id
                : "0",
              title:
                currentRecord.prefix !== ""
                  ? currentRecord.prefix.toString()
                  : "0",
              first_name: currentRecord.first_name,
              last_name: currentRecord.last_name,
              phone_number: currentRecord.phone_number,
              email: currentRecord.email,
              user_name: currentRecord.user_name,
              password: "",
            },
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/users");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
    
  }

  doSubmit(values) {
  
    this.setState({
      loading: true,
    });
  
    if (this.props.match.params.id > 0) {
      //Update
      this.props
        .updateUser(this.props.match.params.id, values)
        .then((data) => {
        
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/users");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      this.props
        .createUser(values)
        .then((data) => {

          console.log(data.status, data.message);
        
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/users");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    }

   
  };

  render() {

    // console.log(this.state.roles, 'hhhhhhhhhhhhhhhhhhhhhh');
    let optionRoles  =   [{ id: "0", name: "select Roles" }];
    if(this.state.roles !== undefined)
    {
     optionRoles = this.state.roles;
    }
    const { message } = this.props;
    
    
  
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {
                          this.props.match.params.id ?
                          this.props.t('user.updateUser')
                          :  this.props.t('user.AddUser')
                        }
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div  className="col-md-4 col-sm-12-cus p-10 horizontal-form">
                      <div className="form-group mb-20">
                        <Message message={message} />
                      </div>
                      <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          this.doSubmit(values);
                        }}
                        initialValues={{
                          role_id: this.state.data.role_id,
                          title: this.state.data.title,
                          first_name: this.state.data.first_name,
                          last_name: this.state.data.last_name,
                          phone_number: this.state.data.phone_number,
                          email: this.state.data.email,
                          user_name: this.state.data.user_name,
                          password: this.state.data.password,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "user.addForm.usertype"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                              
                                name="role_id"
                                className="form-control"
                                onChange={handleChange}
                                value={values.role_id}
                                isInvalid={!!errors.role_id}
                              >
                               {optionRoles.map((r, index) => {
                              return (
                                <>
                                 { r.slug !== 'user' ?
                                  <option key={r.id} value={r.id}>
                                    {this.props.t(
                                      "user.addForm.usertypeOption." + r.slug
                                    )}
                                  
                                  </option>
                                 : ''
                                  }
                                </>
                              );
                            })}
                              </Form.Select>
                              <Form.Control.Feedback
                              
                                type="invalid"
                              >
                                { this.props.t(errors.role_id)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "user.addForm.lableTitle"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="title"
                                className="form-control"
                                onChange={handleChange}
                                value={values.title}
                                isInvalid={!!errors.title}
                              >
                               <option key="10" value="0">{this.props.t("user.addForm.usertitleOption.mr")}</option>
                               <option key="20" value="1">{this.props.t("user.addForm.usertitleOption.mrs")}</option>
                              </Form.Select>
                              
                              <Form.Control.Feedback
                              
                                type="invalid"
                              >
                                 { this.props.t(errors.title)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("user.addForm.lablefirst")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                                
                                type="text"
                                name="first_name"
                                placeholder={this.props.t(
                                  "user.addForm.lablefirst"
                                )}
                                value={values.first_name}
                                onChange={handleChange}
                                isInvalid={!!errors.first_name}
                              />
                              <Form.Control.Feedback
                             
                                type="invalid"
                              >
                                 { this.props.t(errors.first_name)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("user.addForm.lablelast")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                               
                                type="text"
                                name="last_name"
                                placeholder={this.props.t(
                                  "user.addForm.lablelast"
                                )}
                                value={values.last_name}
                                onChange={handleChange}
                                isInvalid={!!errors.last_name}
                              />
                              <Form.Control.Feedback
                              
                                type="invalid"
                              >
                                   { this.props.t(errors.last_name)}
                               
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("user.addForm.lablePhone")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                                
                                name="phone_number"
                                placeholder={this.props.t(
                                  "user.addForm.lablePhone"
                                )}
                                value={values.phone_number}
                                onChange={handleChange}
                                isInvalid={!!errors.phone_number}
                                type="text"
                              />
                              <Form.Control.Feedback
                                type="invalid"
                              >
                               { this.props.t(errors.phone_number)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("user.addForm.lableEmail")}
                              </Form.Label>
                              </div>
                            <div className="col-md-9">

                              <Form.Control
                             
                                name="email"
                                placeholder={this.props.t(
                                  "user.addForm.lableEmail"
                                )}
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                                type="email"
                                autoComplete="new-password"
                              />
                              <Form.Control.Feedback
                                type="invalid"
                              >
                               { this.props.t(errors.email)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex ant-form-item-label ">

                              <Form.Label className=" color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("user.addForm.lablepassword")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                                type="password"
                                name="password"
                                placeholder={this.props.t(
                                  "user.addForm.lablepassword"
                                )}
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}         
                                autoComplete="new-password"
                              />
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                               { this.props.t(errors.password)}
                              </Form.Control.Feedback>
                              </div>
                            </Row>

                            <div className="mb-3 row">
                              <div className="col-md-3 d-flex align-items-center"></div>
                              <div className="col-md-9 d-flex justify-content-between">
                              <Button
                                  className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                                  type="submit"
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  {this.props.t("button.save")}
                                </Button>

                                <Link
                                  to="/users"
                                  className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                                >
                                  <span>{this.props.t("button.cancel")}</span>
                                </Link>

                              </div>
                            </div>
                            
                           
                          </Form>
                        )}
                      </Formik>                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);

export default compose(withTranslation() ,connect(mapStateToProps, {
  createUser,
  getUser,
  updateUser,
  getUserRole,
  //deleteAllTutorials,
}))(AddUser);
