import {
    
    RETRIEVE_CONFIGURATION,

  } from "../actions/types";
  
  const initialState = [];
  
  function configurationReducer(configurations = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
    //   case CREATE_RESTAURANT:
    //     return [restaurants, payload];
  
      case RETRIEVE_CONFIGURATION:
        return payload;
  
    //   case UPDATE_RESTAURANT:
    //     return [];
    //     // rates.map((rate) => {
    //     //   if (rate.id === payload.id) {
    //     //     return {
    //     //       ...rate,
    //     //       ...payload,
    //     //     };
    //     //   } else {
    //     //     return rate;
    //     //   }
    //     // });
  
    //   case DELETE_RESTAURANT:
    //     return [payload]; //return guests.filter(({ id }) => id !== payload.id);
  
    //   case DELETE_ALL_RATES:
    //     return [];
  
      default:
        return configurations;
    }
  }
  
  export default configurationReducer;
  
  