import React, { Component }  from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Row,  Form } from "react-bootstrap"; 
// import { _pre } from "../../utils/vlogs";
import Message from "../common/message";
import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import {
  createRate,
  updateRate,
  getRate
  //findGuestByKeyword,
  //deleteAllTutorials,
} from "../../actions/rate.action";

class AddRate extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      loading: false,
      data: {
        rate_name: "",
        abbreviation: "",
      },
      errors: {},
    };
  }


  schema =  yup.object().shape({
    rate_name: yup.string().required(this.props.t('rate.validation.rate_name')),
    abbreviation: yup.string().required(this.props.t('rate.validation.abbreviation')),
  });


  async componentDidMount() {
    //alert(this.props.match.params.id);

    if (this.props.match.params.id) {
      await this.getRecord(this.props.match.params.id);
    }
    
  }

  getRecord(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getRate(id)
      .then((data) => {
      
        if (data.status) {
          //toast.success(data.message);
          //this.props.history.push("/guests");
          const { detail: currentRecord } = data.data;
          this.setState({
            data: {
              rate_name: currentRecord.rate_name,
              abbreviation: currentRecord.abbreviation,
            },
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/rates");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
   
  }



  doSubmit(values){
   

    this.setState({
      loading: true,
    });
    
    if (this.props.match.params.id > 0) {
      //Update
      this.props
        .updateRate(this.props.match.params.id, values)
        .then((data) => {
         
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/rates");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      this.props
        .createRate(values)
        .then((data) => {

          console.log(data.status, data.message);
         
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/rates");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    }

   
  };

  

  render() {
    const { message } = this.props;
   
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                      {
                          this.props.match.params.id ?
                          this.props.t('rate.updateRate')
                          :  this.props.t('rate.AddRate')
                        }
                        {/* <button onClick={notify}>Notify!</button> */}
                      </h4>

                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>

                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
              
                <div className="card-body">
              
                  <div className="row justify-content-center">
                    
                    <div className="col-md-4 col-sm-12-cus p-10 horizontal-form">
                      <div className="form-group mb-20">
                        <Message message={message} />
                      </div>
                      <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          this.doSubmit(values);
                        }}
                        initialValues={{
                          rate_name: this.state.data.rate_name,
                          abbreviation: this.state.data.abbreviation,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "rate.addForm.lableName"
                                )}
                              </Form.Label>
                              </div>
                            <div className="col-md-9">

                              <Form.Control
                                
                                type="text"
                                name="rate_name"
                                placeholder={this.props.t(
                                  "rate.addForm.lableName"
                                )}
                                value={values.rate_name}
                                onChange={handleChange}
                                isInvalid={!!errors.rate_name}
                              />
                              
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                { this.props.t(errors.rate_name)}
                              </Form.Control.Feedback>
                              </div>
                             
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">
                                  
                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "rate.addForm.lableAbbreviation"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                              type="text"
                                name="abbreviation"
                                onChange={handleChange}
                                placeholder={this.props.t('rate.addForm.lableAbbreviation')}
                                value={values.abbreviation}
                                isInvalid={!!errors.abbreviation}
                              />                              
                              <Form.Control.Feedback
                                
                                type="invalid"
                              >
                                 { this.props.t(errors.abbreviation)}
                              </Form.Control.Feedback>
                              </div>
                              
                             
                            </Row>     
                                  
                            <div className="mb-3 row">
                              <div className="col-md-3 d-flex align-items-center"></div>
                              <div className="col-md-9 d-flex justify-content-between">
                              <button
                                  className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn "
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  <span>{this.props.t('button.save')}</span>
                                </button>

                                <Link
                                  to="/rates"
                                  className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                                >
                                  <span>{this.props.t("button.cancel")}</span>
                                </Link>

                              </div>
                            </div>
                            
                            
                          </Form>
                        )}
                      </Formik>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);
export default compose(withTranslation() ,connect(mapStateToProps, {
  createRate,
  updateRate,
  getRate
  //deleteAllTutorials,
}))(AddRate);

