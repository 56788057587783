  import {
    CREATE_RATE,
    RETRIEVE_RATES,
    UPDATE_RATE,
    DELETE_RATE,
    DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function rateReducer(rates = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_RATE:
        return [rates, payload];
  
      case RETRIEVE_RATES:
        return payload;
  
      case UPDATE_RATE:
        return [];
        // rates.map((rate) => {
        //   if (rate.id === payload.id) {
        //     return {
        //       ...rate,
        //       ...payload,
        //     };
        //   } else {
        //     return rate;
        //   }
        // });
  
      case DELETE_RATE:
        return [payload]; //return guests.filter(({ id }) => id !== payload.id);
  
      case DELETE_ALL_RATES:
        return [];
  
      default:
        return rates;
    }
  }
  
  export default rateReducer;
  
  