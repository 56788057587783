import { RETRIEVE_RESERVATIONS_CALENDER } from "./types";
//import CommonHelper from "../utils/CommonHelper";
import ReservationDataService from "../services/reservation.service";
// import { _pre } from "../utils/vlogs";

export const retrieveReservationCalender =
  (data) => async (dispatch) => {
    try {
      const res = await ReservationDataService.getCalender(
        data
      );
      // console.log("ACTION TU : retrieveRestaurants " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_RESERVATIONS_CALENDER,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };
