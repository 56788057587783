export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const CREATE_TUTORIAL = "CREATE_TUTORIAL";
export const RETRIEVE_TUTORIALS = "RETRIEVE_TUTORIALS";
export const UPDATE_TUTORIAL = "UPDATE_TUTORIAL";
export const DELETE_TUTORIAL = "DELETE_TUTORIAL";
export const DELETE_ALL_TUTORIALS = "DELETE_ALL_TUTORIALS";

export const CREATE_GUEST = "CREATE_GUEST";
export const RETRIEVE_GUESTS = "RETRIEVE_GUESTS";
export const UPDATE_GUEST = "UPDATE_GUEST";
export const DELETE_GUEST = "DELETE_GUEST";
export const DELETE_ALL_GUESTS = "DELETE_ALL_GUESTS";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_RATE = "CREATE_RATE";
export const RETRIEVE_RATES = "RETRIEVE_RATES";
export const UPDATE_RATE = "UPDATE_RATE";
export const DELETE_RATE = "DELETE_RATE";
export const DELETE_ALL_RATES = "DELETE_ALL_RATES";

export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const CREATE_RESTAURANT = "CREATE_RESTAURANT";
export const RETRIEVE_RESTAURANTS = "RETRIEVE_RESTAURANTS";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const DELETE_RESTAURANT = "DELETE_RESTAURANT";

export const CREATE_RESTAURANT_HOUR = "CREATE_RESTAURANT_HOUR";
export const RETRIEVE_RESTAURANT_HOURS = "RETRIEVE_RESTAURANT_HOURS";
export const DELETE_RESTAURANT_HOUR = "DELETE_RESTAURANT_HOUR";
export const UPDATE_RESTAURANT_HOUR = "UPDATE_RESTAURANT_HOUR";

export const CREATE_RESTAURANT_TABLE = "CREATE_RESTAURANT_TABLE";
export const RETRIEVE_RESTAURANT_TABLE = "RETRIEVE_RESTAURANT_TABLE";
export const DELETE_RESTAURANT_TABLE = "DELETE_RESTAURANT_TABLE";
export const UPDATE_RESTAURANT_TABLE = "UPDATE_RESTAURANT_TABLE";

export const RETRIEVE_CONFIGURATION = "RETRIEVE_CONFIGURATION";

export const CREATE_RESERVATION = "CREATE_RESERVATION";
export const CREATE_RESERVATION_WALKIN = "CREATE_RESERVATION_WALKIN";
export const RETRIEVE_RESERVATIONS = "RETRIEVE_RESERVATIONS";
export const DELETE_RESERVATION = "DELETE_RESERVATION";
export const UPDATE_RESERVATION = "UPDATE_RESERVATION";
export const CURRENT_RESERVATION = "CURRENT_RESERVATION";
export const RETRIEVE_TABLE_RESERVATIONS = "RETRIEVE_TABLE_RESERVATIONS";
export const GET_RESERVATION_RESTAURANT_HOURS = "GET_RESERVATION_RESTAURANT_HOURS";
export const GET_RESERVATION_CALENDER_DATES = "GET_RESERVATION_CALENDER_DATES";


export const RETRIEVE_RESERVATIONS_CALENDER = "RETRIEVE_RESERVATIONS_CALENDER";

export const CREATE_NOTES = "CREATE_NOTES";
export const RETRIEVE_NOTES = "RETRIEVE_NOTES";
export const DELETE_NOTES = "DELETE_NOTES";
export const UPDATE_NOTES = "UPDATE_NOTES";


export const CREATE_CODE = "CREATE_CODE";
export const VERIFY_CODE = "VERIFY_CODE";
export const CREATE_RESERVATION_QR= "CREATE_RESERVATION_QR";




// export const DELETE_ALL_RATES = "DELETE_ALL_RATES";

/* export const CREATE_GUESTUSER = "CREATE_GUEST_USER";
export const RETRIEVE_GUESTUSERS = "RETRIEVE_GUEST_USERS";
export const UPDATE_GUESTUSER = "UPDATE_GUEST_USER";
export const DELETE_GUESTUSER = "DELETE_GUEST_USER";
export const DELETE_ALL_GUESTUSERS = "DELETE_ALL_GUEST_USERS"; */
