import {
  CREATE_GUEST,
  RETRIEVE_GUESTS,
  UPDATE_GUEST,
  DELETE_GUEST,
  DELETE_ALL_GUESTS,
} from "../actions/types";

const initialState = [];

function guestReducer(guests = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_GUEST:
      return [guests, payload];

    case RETRIEVE_GUESTS:
      return payload;

    case UPDATE_GUEST:
      //console.log(guests);
      return [];
    /* return guests.map((guest) => {
        console.log(guest);
        if (guest.id === payload.id) {
          return {
            ...guest,
            ...payload,
          };
        } else {
          return guest;
        }
      }); */

    case DELETE_GUEST:
      return [payload]; //return guests.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_GUESTS:
      return [];

    default:
      return guests;
  }
}

export default guestReducer;
