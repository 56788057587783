import {
    CREATE_NOTES,
    RETRIEVE_NOTES,
    // DELETE_NOTES,
    UPDATE_NOTES,
    //RETRIEVE_TUTORIALS,
    //UPDATE_TUTORIAL,
    //DELETE_TUTORIAL,
    // DELETE_ALL_TUTORIALS,
    SET_MESSAGE,
  } from "./types";
  import CommonHelper from "../utils/CommonHelper";
  
  import NotesDataService from "../services/notes.service";
  // import { _pre } from "../utils/vlogs";
  
  export const createNote = (formData) => async (dispatch) => {
    try {
      const res = await NotesDataService.create(formData);
  
      dispatch({
        type: CREATE_NOTES,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };


  export const uploadPdfNotes = (formData) => async (dispatch) => {
    try {
      const res = await NotesDataService.uploadpdf(formData);
  
      // dispatch({
      //   type: CREATE_NOTES,
      //   payload: res.data,
      // });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of create restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  
  
  export const retriveNotes = (date, id) => async (dispatch) => {
    try {
      const res = await NotesDataService.getAll(date, id);
      // console.log("ACTION TU : retrieveRestaurants " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_NOTES,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };

  
  export const UpdateNote = (id, data) => async (dispatch) => {
    try {
      const res = await NotesDataService.update(id, data);
  
      dispatch({
        type: UPDATE_NOTES,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (error) {

      // console.log('in error of update restaurant');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };
  
  
  

  