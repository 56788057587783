// import jwtDecode from "jwt-decode";
import axios from "axios";
import configData from "../config.json";
// import { _pre } from "../utils/vlogs";
//const API_URL = "http://localhost:8080/api/auth/";
// console.log(configData);
const apiEndpoint = configData.apiUrl + "/";
const tokenKey = "user";
class AuthService {
  login(email, password) {
    return axios
      .post(apiEndpoint + "login", { email, password })
      .then((response) => {
        // _pre("AuthService-> Login then", response.data.data);
        if (response.data.data) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          return response.data;
        }
      });
    /* .catch((error, response) => {
        _pre("AuthService-> Login catch", error);
        _pre("AuthService-> Login catch res", response);
        //return error.response;
        if (error.response) {
          console.log("error respone", error.response);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.log("erros request: ", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error message", error.message);
        }
        console.log("errors config : ", error.config);
      })
      .finally((response) => {
        _pre("finally", response);
      }); */
  }

  logout() {
    localStorage.removeItem("user");
  }

  forgotpassword(data) {
    return axios.post(apiEndpoint + "forgot-password", data, );
  }

  resetpassword(data) {
    return axios.post(apiEndpoint + "reset-password", data, );
  }
  

  register(username, email, password) {
    return axios.post(apiEndpoint + "signup", {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    try {
      const jwt = JSON.parse(localStorage.getItem(tokenKey));
      // _pre("getCurrentUser ", jwtDecode(jwt.accessToken));
      // console.log(jwt.accessToken);
      return jwt; //jwtDecode(jwt.accessToken);
    } catch (ex) {
      return null;
    }
  }
}

export default new AuthService();
