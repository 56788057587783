import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Row, Form } from "react-bootstrap";
// import { _pre } from "../../utils/vlogs";
import Message from "../common/message";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { createGuest, updateGuest, getGuest } from "../../actions/guest-user";

class AddGuestUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    
      data: {
        title: "0",
        first_name: "",
        last_name: "",
        zip_code: "",
        phone: "",
        mobile: "",
        email: "",
        remarks: "",
        is_newsletter: "",
        last_resevation: {},
        
      },
      
      errors: {},
    };
  }

  schema = yup.object().shape({
    title: yup.string().required("guest.validation.title"),
    first_name: yup.string().required("guest.validation.first_name"),
    last_name: yup.string().required("guest.validation.last_name"),
    email: yup
      .string()
      .email("guest.validation.validemail")
      .required("guest.validation.email"),
    zip_code: yup
      .number("guest.validation.zip_code_valid")
      .required("guest.validation.zip_code"),
    phone: yup
      .number()
      .typeError("guest.validation.phone_number_valid")
      .required("guest.validation.phone"),
    mobile: yup
      .number()
      .typeError("guest.validation.mobile_valid")
      .required("guest.validation.mobile"),
    // remarks: yup.string().required('guest.validation.remarks'),
    // is_newsletter: yup.string().required(this.props.t('guest.validation.is_newsletter')),
  });

  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.getRecrod(this.props.match.params.id);
    }
  }
  getRecrod(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getGuest(id)
      .then((data) => {
        if (data.status) {
          console.log(data);
          const { detail: currentRecord } = data.data;
          console.log(currentRecord.last_resevation_data);
          this.setState({
            
            data: {
              title:
                currentRecord.prefix !== ""
                  ? currentRecord.prefix.toString()
                  : "0",
              first_name: currentRecord.first_name,
              last_name: currentRecord.last_name,
              zip_code: currentRecord.zip_code,
              phone: currentRecord.phone,
              mobile: currentRecord.mobile,
              email: currentRecord.email,
              remarks: currentRecord.remarks,
              is_newsletter: currentRecord.is_newsletter.toString(),
              last_resevation: currentRecord.last_resevation_data,
            }
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/guests");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        //console.log("Add-Guest getRecrod", e);
        this.setState({
          loading: false,
        });
      });
  }

  doSubmit(values) {
    this.setState({
      loading: true,
    });

    if (this.props.match.params.id > 0) {
      this.props
        .updateGuest(this.props.match.params.id, values)
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/guests");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          //console.log("Add-Guest doSubmit", e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      this.props
        .createGuest(values)
        .then((data) => {
          //console.log("Add-Guest doSubmit", data.status, data.message);

          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/guests");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          //console.log("Add-Guest getRecrod", e);
          this.setState({
            loading: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;

    console.log(this.state.data.last_resevation);
    //const options = ["Mr.", "Mrs."];
    // const options = [
    //   { id: "0", name: "Mr." },
    //   { id: "1", name: "Mrs." },
    // ];
    //const notify = () => toast.error("Wow so easy!");
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.match.params.id
                          ? this.props.t("guest.updateGuest")
                          : this.props.t("guest.AddGuest")}

                        {/* <button onClick={notify}>Notify!</button> */}
                      </h4>

                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>

                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12-cus col-xs-12  p-10 horizontal-form">
                      <div className="form-group mb-20">
                        <Message message={message} />
                      </div>
                      <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          //console.log(values);
                          this.doSubmit(values);
                        }}
                        initialValues={{
                          title: this.state.data.title,
                          first_name: this.state.data.first_name,
                          last_name: this.state.data.last_name,
                          zip_code: this.state.data.zip_code,
                          phone: this.state.data.phone,
                          mobile: this.state.data.mobile,
                          email: this.state.data.email,
                          remarks: this.state.data.remarks,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lableTitle")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Select
                                  className="form-control"
                                  type="text"
                                  name="title"
                                  placeholder={this.props.t(
                                    "guest.addForm.lableTitle"
                                  )}
                                  value={values.title}
                                  onChange={handleChange}
                                  isInvalid={!!errors.title}
                                >
                                  <option value="0">
                                    {this.props.t("guest.guesttitleOption.mr")}
                                  </option>
                                  <option value="1">
                                    {this.props.t("guest.guesttitleOption.mrs")}
                                  </option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.title)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lablefirst")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="text"
                                  name="first_name"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lablefirst"
                                  )}
                                  value={values.first_name}
                                  isInvalid={!!errors.first_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.first_name)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lablelast")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lablelast"
                                  )}
                                  value={values.last_name}
                                  isInvalid={!!errors.last_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.last_name)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lablezipcode")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="text"
                                  name="zip_code"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lablezipcode"
                                  )}
                                  value={values.zip_code}
                                  isInvalid={!!errors.zip_code}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.zip_code)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lablePhone")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lablePhone"
                                  )}
                                  value={values.phone}
                                  isInvalid={!!errors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.phone)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>

                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lableMobile")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="text"
                                  name="mobile"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lableMobile"
                                  )}
                                  value={values.mobile}
                                  isInvalid={!!errors.mobile}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.mobile)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3 ">
                              <div className="col-md-2 d-flex align-items-center ant-form-item-label ">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lableEmail")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  placeholder={this.props.t(
                                    "guest.addForm.lableEmail"
                                  )}
                                  value={values.email}
                                  isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {this.props.t(errors.email)}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <div className="col-md-2 d-flex align-items-center">
                                <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                  {this.props.t("guest.addForm.lableRemarks")}
                                </Form.Label>
                              </div>
                              <div className="col-md-10">
                                <Form.Control
                                  name="remarks"
                                  placeholder={this.props.t(
                                    "guest.addForm.lableRemarks"
                                  )}
                                  value={values.remarks}
                                  onChange={handleChange}
                                  isInvalid={!!errors.remarks}
                                  as="textarea"
                                  rows={3}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.hint_text}
                                </Form.Control.Feedback>
                              </div>
                            </Row>
                            { this.state.data.last_resevation ?
                            <>
                            <Row >
                                <div className="col-md-2 d-flex align-items-center">
                                  <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                    {this.props.t("guest.addForm.lastReservation")}:
                                  </Form.Label>
                                </div>
                            </Row>
                          
                            <Row className="mb-3">
                                <div className="col-md-2 d-flex align-items-center">
                                  <label>{ this.state.data.last_resevation.reservation_date}</label>
                                </div>
                                <div className="col-md-10">
                                  <span> { this.state.data.last_resevation.restaurant_name} / {this.props.t("restaurantTable.addForm.table_no")} { this.state.data.last_resevation.table_number}  / {this.props.t("reservation.addForm.lableRoom")} { this.state.data.last_resevation.room_no}  </span>
                                </div>
                                {/* <div className="col-md-2 d-flex align-items-center">
                                  <label>type</label>
                                </div>
                                <div className="col-md-10">
                                 
                                </div> */}
                             </Row> 
                             </>
                              :''
                            }
                            <div className="mb-3 row">
                              <div className="col-md-2 d-flex align-items-center"></div>
                              <div className="col-md-10 d-flex justify-content-between">
                                <button
                                  className="btn btn-primary btn-default btn-squared text-capitalize lh-normal px-40 py-15"
                                  type="submit"
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  {this.props.t("button.save")}
                                </button>

                                <Link
                                  to="/guests"
                                  className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                                >
                                  <span>{this.props.t("button.cancel")}</span>
                                </Link>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    createGuest,
    updateGuest,
    getGuest,
    //deleteAllTutorials,
  })
)(AddGuestUser);
