import {
    GET_RESERVATION_CALENDER_DATES,
  } from "./types";
  import ReservationDateSettingDataService from "../services/reservation.date.setting.service";
  // import { _pre } from "../utils/vlogs";
  
  export const getReservationCalenderdates = (id, start_date) => async (dispatch) => {
    try {
      const res = await ReservationDateSettingDataService.getRecordDate(id, start_date);
   // console.log("ACTION TU : retrieveRestaurants " + page);
      // console.log(res.data.data);
      dispatch({
        type: GET_RESERVATION_CALENDER_DATES,
        payload: res.data.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };


  export const getReservationCalenderdatesExternal = (data) => async (dispatch) => {
    try {
      const res = await ReservationDateSettingDataService.getRecordDateExternal(data);
   // console.log("ACTION TU : retrieveRestaurants " + page);
      // console.log(res.data.data);
      dispatch({
        type: GET_RESERVATION_CALENDER_DATES,
        payload: res.data.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };



  
  

  

  
  

  