import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/reservation";

class ReservationService {
  getAll(data) {
    // reservation?per_page=&page=&keyword=dan&start_date=&end_date=&status=&restaurant_id=1&shift_id=
    return axios.post(apiEndpoint, data, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }
  getTableReservations(data) {
    // reservation?per_page=&page=&keyword=dan&start_date=&end_date=&status=&restaurant_id=1&shift_id=
    return axios.post(apiEndpoint + "/tables", data, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getTableReservationsExternal(data) {
  return axios.post(apiEndpoint + "-external/tables", data, {
    headers: authHeader(),
  });
  //return axios.get(apiEndpoint, { headers: authHeader() } );
}

  getUserApi(page, countPerPage) {
    //return axios.get('https://reqres.in/api/users?page='+page+'&per_page='+countPerPage+'&delay=1' + "", { headers: authHeader() });
    //return axios.get(apiEndpoint, { headers: authHeader() } );

    return axios
      .get(
        "https://reqres.in/api/users?page=" +
          page +
          "&per_page=" +
          countPerPage +
          "&delay=1" +
          "",
        {}
      )
      .then((response) => {
        //console.log("ReservationService then", response.data.data);
        if (response.data.data) {
          //localStorage.setItem("user", JSON.stringify(response.data.data));
          return response.data;
        }
      })
      .catch((error) => {
       // console.log("ReservationService error ", error);
      });
  }

  createWalkIn(data) {
    return axios.post(apiEndpoint + "/walk-in", data, {
      headers: authHeader(),
    });
  }

  getRecord(id) {
    return axios.get(apiEndpoint + `/get-reservation-details?reservation_id=${id}`, { headers: authHeader() });
  }

  getUserRole() {
    return axios.get(apiEndpoint + `/roles`, { headers: authHeader() });
  }

  create(data) {
    return axios.post(apiEndpoint + "/create", data, { headers: authHeader() });
  }

  update(data) {
    return axios.post(apiEndpoint + `/update`, data, { headers: authHeader() });
  }

  delete(id, data) {
    return axios.delete(apiEndpoint + `/${id}`, { headers: authHeader() });
  }

  deleteMultiple(data) {
    return axios.delete(apiEndpoint + `/delete-reservations`, {
      headers: authHeader(),
      data: data,
    });
  }

  deleteAll() {
    return axios.delete(apiEndpoint, { headers: authHeader() });
  }

  findByTitle(title) {
    return axios.get(apiEndpoint + `?title=${title}`, {
      headers: authHeader(),
    });
  }

  getCalender(data){
    return axios.post(apiEndpoint + `/calendar-list`, data, {
      headers: authHeader(),
    });
  }

  makeGroupReservation(data){
    return axios.post(apiEndpoint + `/make-group`, data, {
      headers: authHeader(),
    });
  }

  changeStatusReservation(data){
    return axios.post(apiEndpoint + `/change-status-reservation`, data, {
      headers: authHeader(),
    });
  }

  getNextPrevreservation(data){
    return axios.post(apiEndpoint + `/get-next-prev-reservation`, data, {
      headers: authHeader(),
    });
  }

  

}

export default new ReservationService();
