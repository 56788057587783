import {
    CREATE_RESTAURANT_TABLE,
    RETRIEVE_RESTAURANT_TABLE,
    UPDATE_RESTAURANT_TABLE,
    DELETE_RESTAURANT_TABLE,
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function restaurantTableReducer(restaurantTables = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_RESTAURANT_TABLE:
        return [restaurantTables, payload];
  
      case RETRIEVE_RESTAURANT_TABLE:
        return payload;
  
      case UPDATE_RESTAURANT_TABLE:
        return [];
       /* return  restaurantHours.map((rate) => {
          if (rate.id === payload.id) {
            return {
              ...rate,
              ...payload,
            };
          } else {
            return rate;
          }
        }); */
  
      case DELETE_RESTAURANT_TABLE:
        return [payload]; //return guests.filter(({ id }) => id !== payload.id);
  
    //   case DELETE_ALL_RATES:
    //     return [];
  
      default:
        return restaurantTables;
    }
  }
  
  export default restaurantTableReducer;
  
  