import { combineReducers } from "redux";
import auth from "./auth";
import authUser from "./auth-user";
import message from "./message";
import tutorials from "./tutorials";
import guests from "./guests";
import rates from "./rates";
import users from "./user";
import guestUser from "./guest-user";
import restaurants from "./restaurant";
import restaurantHours from "./restaurant-hour";
import restaurantTables from "./restaurant-table";
import configurations from "./configuration";
import reservations from "./reservation";
import reservationsSettings from "./reservation-setting";
import reservationsDateSettings from "./reservation-date-setting";
import reservationsCalender from "./reservation-calender";
import currentReservation from "./current_reservation";
import notes from "./notes";





export default combineReducers({
  auth,
  message,
  authUser,
  tutorials,
  guests,
  guestUser,
  rates,
  users,
  restaurantHours,
  restaurantTables,
  restaurants,
  configurations,
  reservations,
  reservationsDateSettings,
  reservationsSettings,
  reservationsCalender,
  currentReservation,
  notes,
 
});
