import {
  CREATE_RESERVATION_WALKIN,
  RETRIEVE_RESERVATIONS,
  DELETE_RESERVATION,
  UPDATE_RESERVATION,
  CURRENT_RESERVATION,
  //RETRIEVE_TUTORIALS,
  //UPDATE_TUTORIAL,
  //DELETE_TUTORIAL,
  // DELETE_ALL_TUTORIALS,
  //RETRIEVE_TABLE_RESERVATIONS,
  SET_MESSAGE,
} from "./types";
import CommonHelper from "../utils/CommonHelper";

import ReservationDataService from "../services/reservation.service";
// import { _pre } from "../utils/vlogs";

export const createReservation = (formData) => async (dispatch) => {
  try {
    const res = await ReservationDataService.create(formData);

    // dispatch({
    //   type: CREATE_RESERVATION,
    //   payload: res.data,
    // });

    /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of create restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};

export const retrieveReservations = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.getAll(data);
    // console.log("ACTION TU : retrieveRestaurants " + page);
    dispatch({
      type: RETRIEVE_RESERVATIONS,
      payload: res.data.data,
    });
  } catch (err) {
    // console.log(err);
  }
};

export const retrieveTableReservations = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.getTableReservations(data);

    /* dispatch({
      type: RETRIEVE_TABLE_RESERVATIONS,
      payload: res.data,
    }); */

    return Promise.resolve(res.data.data);
  } catch (error) {
    // console.log('in error of update restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};


export const retrieveTableReservationsExternal = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.getTableReservationsExternal(data);

    /* dispatch({
      type: RETRIEVE_TABLE_RESERVATIONS,
      payload: res.data,
    }); */

    return Promise.resolve(res.data.data);
  } catch (error) {
    // console.log('in error of update restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};


export const getReservation = (id) => async (dispatch) => {
  try {
    const res = await ReservationDataService.getRecord(id);

    dispatch({
      type: CURRENT_RESERVATION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const addWalkInReservation = (formData) => async (dispatch) => {
  try {
    const res = await ReservationDataService.createWalkIn(formData);

    dispatch({
      type: CREATE_RESERVATION_WALKIN,
      payload: res.data,
    });

    /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of create restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};

export const updateReservations = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.update(data);

    dispatch({
      type: UPDATE_RESERVATION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of update restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};

export const deleteReservation = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.delete(data);
    dispatch({
      type: DELETE_RESERVATION,
      //payload: { id },
      payload: res.data,
    });
    // _pre(" test ", res.data);
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of delete restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    // console.log(error);
  }
};

export const deleteReservationMultiple = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.deleteMultiple(data);
    dispatch({
      type: DELETE_RESERVATION,
      //payload: { id },
      payload: res.data,
    });
    // _pre(" test ", res.data);
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of delete restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    // console.log(error);
  }
};

export const makeGroupReservation = (data) => async (dispatch) => {
  try {
    const res = await ReservationDataService.makeGroupReservation(data);
    // dispatch({
    //   type: DELETE_RESERVATION,
    //   //payload: { id },
    //   payload: res.data,
    // });
    // _pre(" test ", res.data);
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of delete restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    // console.log(error);
  }
};

export const changeStatusReservation = (formData) => async (dispatch) => {
  try {
    const res = await ReservationDataService.changeStatusReservation(formData);

    // dispatch({
    //   type: CREATE_RESERVATION,
    //   payload: res.data,
    // });

    /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of create restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};


export const getNextPrevreservation = (formData) => async (dispatch) => {
  try {
    const res = await ReservationDataService.getNextPrevreservation(formData);

    // dispatch({
    //   type: CREATE_RESERVATION,
    //   payload: res.data,
    // });

    /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of create restaurant');

    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant');

    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
    return Promise.reject(error);
  }
};


