import React, { Component, createRef  } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";
import CommonHelper from "../../utils/CommonHelper";
import { DatePicker } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { Form, Button, InputGroup, Modal , Row} from "react-bootstrap";
import { clearMessage } from "../../actions/message"
import {
  _generateTablesForReservation,
  _getArrayOfTableOption,
  // _getDateBlocked,
  _getformatDate,
} from "../../utils/vlogs";
import { Oval } from "react-loader-spinner";
import { retrieveRestaurantsTables } from "../../actions/restaurant-tables";
import { retrieveRestaurants, getRestaurant } from "../../actions/restaurant";
import { retrieveRestaurantsHours } from "../../actions/restaurant-hours";
import { retrieveConfigurations } from "../../actions/configuration";
import ReservationCalender from "../../components/reservation/Reservation_calender";
import {
  retrieveReservations,
  addWalkInReservation,
  deleteReservation,
  deleteReservationMultiple,
  retrieveTableReservations,
  getReservation,
  makeGroupReservation,
  changeStatusReservation,
  getNextPrevreservation,
} from "../../actions/reservation";
import {
  retrieveGuestsQr,
  //deleteAllTutorials,
} from "../../actions/guest-user";
import {
  createNote,
  UpdateNote,
  retriveNotes,
  uploadPdfNotes,
}
from "../../actions/notes";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getReservationCalenderdates } from "../../actions/reservation-date-setting";
import { retrieveReservationRestaurantHours } from "../../actions/reservation-setting";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component"; //{ createTheme }
import Message from "../common/message";
import { compose } from "redux";
import Addreservation from "./add-reservation";
import Updatereservation from "./update-reservation";

import { withTranslation } from "react-i18next";
const ImgSrc = "../../default_table_map.jpg";




class Reservation extends Component {

  constructor(props) {
    super(props);
    // const qsParams = qs.parse(props.location.search);
    this.state = {
      lang:
        localStorage.getItem("lan") === null
          ? "de"
          : localStorage.getItem("lan"),
      table_ids: [],
      list_table_ids: [],
      list_table_dates:[],
      isCurrent: 0,
      isCurrentTableId: 0,
      restaurant: "",
      isCurrentReservation: 0,
      data: {
        title: "0",
        first_name: "",
        last_name: "",
        zip_code: "",
        phone: "",
        mobile: "",
        email: "",
        remarks: "",
        is_newsletter: "",
      },
      note_text: "",
      isArrayOfTable: [],
      // arrayOfTableNext: [],
      errors: {},
      selectedRestaurantId: 0,
      currentShift: 0,
      currentCategory: "",
      searchKeyword: "",
      attachment_file:"",
      startDate: moment(),
      selectedTime: "",
      endDate: moment(),
      selectedStartDate: moment().format("YYYY-MM-DD"),
      selectedEndDate: "",
      status: null,
      shift_id: 0,
      loading: false,
      loadingTable: false,
      page: 1, //qsParams.page ? qsParams.page : 1,
      perPage: 10,
      open: false,
      openUpdate: false,
      openDeleteModal: false,
      openModalNote: false,
      openModalPdf:false,
      tableMapOpen: true,
      cancel_reason: "",
      initial: "",
      requiredIntial: null,
      tableList: {},
      group_id: "",
      booking_id: "",
      isDateVisible: true,
      isCurrentReservationData: [],
      guestListQr:[],
      buttonText: 'Assign',
      defaultSortingReservation: "",
      defaultSort: "ASC",
      confirmationEmail: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRestaurantChange = this.handleRestaurantChange.bind(this);
    this.myP = createRef();
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.onTableSelectChange = this.onTableSelectChange.bind(this);
    this.OnNextClick = this.OnNextClick.bind(this);
    this.OnPreviousClick = this.OnPreviousClick.bind(this);
    this.OnNextPrevClick =  this.OnNextPrevClick.bind(this);

    this.onTableListSelectChange = this.onTableListSelectChange.bind(this);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.search = this.search.bind(this);
    this.handleChangeShift = this.handleChangeShift.bind(this);
    this.handleChangeStatusFilter = this.handleChangeStatusFilter.bind(this);
    this.DefaultDateTime = this.DefaultDateTime.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleChangeInitial = this.handleChangeInitial.bind(this);
    this.openModalNote = this.openModalNote.bind(this);
    this.handleChangeCancelReason = this.handleChangeCancelReason.bind(this);
    this.deleteSaveData = this.deleteSaveData.bind(this);
    this.tableReservationList = this.fetchTableReservation.bind(this);
    this.GroupConfirm = this.GroupConfirm.bind(this);
    this.pdfUploadForm = this.pdfUploadForm.bind(this);
    this.UpdateReservationDateTime = this.UpdateReservationDateTime.bind(this);
    this.print = this.print.bind(this);
    this.ExportExcel = this.ExportExcel.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.commonAction = this.commonAction.bind(this);
    this.commonStatus = this.commonStatus.bind(this);
  }

  ExportExcel(excelData, fileName){
    // console.log("fffffffff")
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8";
      const fileExtension = ".xlsx";

      // const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      // }
  }

  async onGroupButton(groupId) {
    // console.log(window.location);
    //alert(window.location.pathname);
    /* this.props.history.push(
      `${window.location.pathname}?page=11&group_id=` + groupId
    ); */
    this.setState({ group_id: groupId, page: 1 });
    await this.setState({
      list_table_ids: [],
    });

    if (groupId > 0) {
      this.fetchReservationGroupAndRepeating(groupId);
    } else {
      this.fetchReservation(this.state.page, this.state.perPage);
    }
  }

  async onRepeatingButton(bookingId) {
    await this.setState({ booking_id: bookingId, page: 1, list_table_ids: [] });
    if (bookingId > 0) {
      await this.setState({ isDateVisible: false });
      this.fetchReservationGroupAndRepeating("", bookingId);
    } else {
      await this.setState({ isDateVisible: true });
      this.fetchReservation(this.state.page, this.state.perPage);
    }
  }

  async componentDidMount() {
    // console.log(" Reservation componentDidMount");
    // const query = new URLSearchParams(this.props.location.search);
    //const token = query.get("group_id");
    // console.log("group_id ", token); //123
    await this.fetchConfiguration(this.state.lang);
    let filtered = "";
    let filteredShifts = "";
    let filteredCategory = "";
    let filteredIntial = "";
    let filteredSortingReservation = "";
    let filteredConfirmationReservation = "";
    if (this.props.configurations.list) {
      filtered = this.props.configurations.list.filter((m) =>
        m.slug.includes("STANDARD-RESTAURANT-SELECTION")
      );
      filteredShifts = this.props.configurations.list.filter((m) =>
        m.slug.includes("STANDARD-RESERVATION-STATUS")
      );
      filteredCategory = this.props.configurations.list.filter((m) =>
        m.slug.includes("CHILDREN-CATEGORY")
      );
      filteredIntial = this.props.configurations.list.filter((m) =>
        m.slug.includes("STAFF-INITIALS")
      );
      filteredSortingReservation = this.props.configurations.list.filter((m) =>
        m.slug.includes("DEFAULT-SORTING")
      );

      filteredConfirmationReservation = this.props.configurations.list.filter((m) =>
        m.slug.includes("CONFIRMATION-SMS-EMAIL")
      );
    }
    // console.log(filteredConfirmationReservation, "filteredConfirmationReservation");
    await this.setState({
      selectedRestaurantId: filtered[0].value,
      status: parseInt(filteredShifts[0].value),
      currentCategory: filteredCategory[0].value,
      initial: filteredIntial[0].value,
      requiredIntial: filteredIntial[0].is_enable,
      defaultSortingReservation: filteredSortingReservation[0].value,
      confirmationEmail: filteredConfirmationReservation[0].is_enable ? true : false
      //selectedStartDate :  this.state.startdate.format("YYYY-MM-DD")
    });
    await this.fetchRestaurant();
    await this.fetchRestaurantDateHour();
    await this.fetchRestaurantHour(); //to get shift name
    await this.fetchRestaurantHourTime();
    await this.fetchRestaurantTable();
    await this.getRecord(filtered[0].value);
    await this.getDailyNotes(this.state.selectedStartDate, filtered[0].value);
    await this.getGuestListQR();
    /* 
   
    await this.fetchReservation(
      this.state.page,
      this.state.perPage,
      this.state.startDate
    );
    await this.fetchRestaurantTable("", "", filtered[0].value);
    
    await this.fetchRestaurantHourTime(filtered[0].value, this.state.startDate);

    await this.getRecord(filtered[0].value); */

    //
  }


  schemaPdf  =  yup.object().shape({
    attachment_file: yup
      .string()
      .required(
        this.props.t("reservation.PdfForm.validation.attachment_file")
      ),
    
  });

  schemaNotes =  yup.object().shape({
    note_text: yup
      .string()
      .required(
        this.props.t("reservation.NotesForm.validation.note_text")
      ),
    
  });

  schema = yup.object().shape({
    cancel_reason: yup
      .string()
      .required(
        this.props.t("reservation.DeleteForm.validation.cancel_reason")
      ),
    initials: yup
      .string()
      .required(this.props.t("reservation.DeleteForm.validation.initial")),
  });

  schemaOther = yup.object().shape({
    initials: yup
      .string()
      .required(this.props.t("reservation.DeleteForm.validation.initial")),
  });


  getGuestListQR = async() => {
    this.setState({ loading: true });
    await this.props.retrieveGuestsQr();
    this.setState({ loading: false });
  }
  //Fecth Configurations

  fetchConfiguration = async (lang) => {
    this.setState({ loading: true });
    await this.props.retrieveConfigurations(lang);
    this.setState({ loading: false });
  };
  //Restaurant List

  fetchRestaurant = async () => {
    await this.props.retrieveRestaurants();
  };

  //Restaurant wise Date list from selected start Date
  fetchRestaurantDateHour = async () => {
    //alert(this.state.selectedRestaurantId);
    await this.props.getReservationCalenderdates(
      this.state.selectedRestaurantId,
      this.state.selectedStartDate
    );
    this.fetchRestaurantHourTime();
  };

  //Restaurant wise time hours list from selected start Date
  fetchRestaurantHourTime = async () => {
    //let selectedDate = format(new Date(), "yyyy/MM/dd");
    await this.props.retrieveReservationRestaurantHours(
      this.state.selectedRestaurantId,
      this.state.selectedStartDate
    );
    await this.fetchReservation(this.state.page, this.state.perPage);
    await this.getRecord(this.state.selectedRestaurantId);
    await this.fetchRestaurantTable();
    if(parseInt(this.state.selectedRestaurantId) !== 0){
      // console.log('ssssssssssss');
    await this.fetchTableReservation();
    }
    await this.getDailyNotes(this.state.selectedStartDate, this.state.selectedRestaurantId);

    //check for restaurant timing list in time exist if exist then set standard time other first slot of time
    let filtered = [];
    let isResSettingDataAvail = Object.keys(
      this.props.reservationsSettings
    ).length;

    if (isResSettingDataAvail > 0) {
      filtered = this.props.reservationsSettings.time_slot.filter((m) =>
        m.time.includes(this.props.reservationsSettings.standard_time)
      );
    }

    // console.log("reservationsSettings ", this.props.reservationsSettings);

    let timeSlot = "";
    let isExistTImeSlot = "";
    if (isResSettingDataAvail > 0) {
      if (this.props.reservationsSettings.time_slot.length > 0) {
        timeSlot = this.props.reservationsSettings.time_slot[0].time;
      }
      isExistTImeSlot = this.props.reservationsSettings.time_slot.filter(
        (i) => i.time >  moment.tz("Europe/Berlin").format("HH:mm:ss")
      );
    }

    // console.log(isExistTImeSlot);
    if (isExistTImeSlot.length > 0) {
      timeSlot = isExistTImeSlot[0].time;
    } else {
      //timeSlot = this.props.reservationsSettings.standard_time + ":00";
      timeSlot =
        filtered.length > 0
          ? this.props.reservationsSettings.standard_time + ":00"
          : timeSlot;
    }

    this.setState({ selectedTime: timeSlot }, function () {
      // console.log(this.state.selectedTime);
    });
    
    //alert(this.state.selectedTime);
    //alert(timeSlot);
  };

  //Restaurant wise get shift name
  fetchRestaurantHour = async () => {
    this.setState({ loading: true });
    await this.props.retrieveRestaurantsHours(
      "",
      "",
      this.state.selectedRestaurantId
    );
    this.setState({ loading: false });
  };

  // NOT USED
  async handleChange(e) {
    await this.setState({ selectedRestaurantId: e.target.value }, () => {
      //this.fetchReservation(this.state.page, this.state.perPage);
    });
    this.fetchRestaurantDateHour();
    //this.fetchRestaurantTable("", "", e.target.value);
    //this.getRecord(e.target.value);
  }

  //Change resturant then update dates and hours
  async handleRestaurantChange(e) {
    await this.setState(
      {
        selectedRestaurantId: e.target.value,
        list_table_ids: [],
        table_ids: [],
        openDeleteModal: false,
      },
      () => {
        //this.fetchReservation(this.state.page, this.state.perPage);
      }
    );
    await this.fetchRestaurantHour();
    await this.fetchRestaurantDateHour();
  }

  //Change dates then update hours
  async handleChangeDate(date, dateString) {
    await this.setState({
      startDate: date,
      selectedStartDate: date.format("YYYY-MM-DD"),
      // list_table_ids: [],
      table_ids: [],
      openDeleteModal: false,
    });
    await this.fetchRestaurantHourTime(
      this.state.selectedRestaurantId,
      this.state.selectedStartDate
    );
  }

  //fetch reservation list
  fetchReservation = async (page = 1, perPage = 10) => {
    //alert(" in fetchReservation ");

    // let user = "";
    // if (localStorage.getItem("user")) {
    //   user = JSON.parse(localStorage.getItem("user"));
    // }
    this.setState({
      group_id: "",
      loadingTable: true,
    });

    let filtered = [];
    let isResSettingDataAvail = Object.keys(
      this.props.reservationsSettings
    ).length;

    if (isResSettingDataAvail > 0) {
      filtered = this.props.reservationsSettings.time_slot.filter((m) =>
        m.time.includes(this.props.reservationsSettings.standard_time)
      );
    }

    let timeSlot = "";
    let isExistTImeSlot = "";
    if (isResSettingDataAvail > 0) {
      if (this.props.reservationsSettings.time_slot.length > 0) {
        timeSlot = this.props.reservationsSettings.time_slot[0].time;
      }
      // console.log(moment.tz("Europe/Berlin").format("HH:mm:ss"))
      isExistTImeSlot = this.props.reservationsSettings.time_slot.filter(
        (i) => i.time > moment.tz("Europe/Berlin").format("HH:mm:ss")
      );
    }

    // console.log(isExistTImeSlot);
    if (isExistTImeSlot.length > 0) {
      timeSlot = isExistTImeSlot[0].time;
    } else {
      //timeSlot = this.props.reservationsSettings.standard_time + ":00";
      timeSlot =
        filtered.length > 0
          ? this.props.reservationsSettings.standard_time + ":00"
          : timeSlot;
    }

    this.setState({ selectedTime: timeSlot}, function () {
      // console.log(this.state.selectedTime);
    });
    // let filtered = [];
    // if (this.props.reservationsSettings.length > 0) {
    //   filtered = this.props.reservationsSettings.time_slot.filter((m) =>
    //     m.time.includes(this.props.reservationsSettings.standard_time)
    //   );
    // }
    // console.log(this.state.selectedTime, "aaaaaaaaaaaaaaaaaaaaaaaaa");
    let data = {
      page: page,
      perPage: perPage,
      keyword: this.state.searchKeyword,
      start_date: this.state.startDate.format("YYYY-MM-DD"),
      end_date: this.state.startDate.format("YYYY-MM-DD"),
      time:timeSlot,
      status: this.state.status,
      restaurant_id: this.state.selectedRestaurantId,
      shift_id: this.state.currentShift,
      reservation_ids:this.state.list_table_ids,
      defaultSortingReservation: this.state.defaultSortingReservation,

    };
    await this.props.retrieveReservations(data);
    this.setState({
      // list_table_ids: [],
      loadingTable: false,
    });

    //alert("V1");
    //await this.tableReservationList(data);
    //await this.props.retrieveTableReservations(data);
  };

  fetchTableReservation = async (data = null) => {
    //alert(" in fetchReservation ");
    let user = "";
    if (localStorage.getItem("user")) {
      user = JSON.parse(localStorage.getItem("user"));
    }
    this.setState({
      //loadingTable: true,
      loading: true,
      initial:
        user.fullName !== undefined
          ? CommonHelper._nameInitials(user.fullName)
          : "",
    });

    data = {
      page: 1,
      perPage: 10,
      keyword: this.state.searchKeyword,
      start_date: this.state.startDate.format("YYYY-MM-DD"),
      end_date: this.state.startDate.format("YYYY-MM-DD"),
      time:
        this.state.selectedTime !== ""
          ? this.state.selectedTime
          : this.props.reservationsSettings.standard_time + ":00",
      status: this.state.status,
      restaurant_id: this.state.selectedRestaurantId,
      shift_id: this.state.currentShift,
      dates: this.state.list_table_dates,
    };
    await this.props
      .retrieveTableReservations(data)
      .then((data) => {
        this.setState({
          tableList: data,
          isArrayOfTable: [],
        });
        //toast.success(this.props.t("messages.delsucmessage"));
        //this.props.history.push("/guests");
        //this.fetchReservation(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        /* this.setState({
          loading: false,
        }); */
        alert("error");
      });

    this.setState({
      //loadingTable: false
      loading: false,
    });
  };

  fetchReservationGroupAndRepeating = async (groupId = "", bookingId = "") => {
    let data = {
      page: 1,
      perPage: 1000,
      keyword: this.state.searchKeyword,
      start_date: this.state.startDate.format("YYYY-MM-DD"),
      end_date: this.state.startDate.format("YYYY-MM-DD"),
      time:
        this.state.selectedTime !== ""
          ? this.state.selectedTime
          : this.props.reservationsSettings.standard_time + ":00",
      status: this.state.status,
      restaurant_id: this.state.selectedRestaurantId,
      shift_id: this.state.currentShift,
      group_id: groupId,
      booking_id: bookingId,
    };
    this.setState({
      loadingTable: true,
    });
    await this.props.retrieveReservations(data);
    this.setState({
      loadingTable: false,
    });
    //alert("V1");
    //await this.tableReservationList(data);
    //await this.props.retrieveTableReservations(data);
  };

  // change status filter
  async handleChangeStatusFilter(e) {
    await this.setState({
      status: parseInt(e.target.value),
      list_table_ids: [],
      table_ids: [],
      booking_id: "",
      isDateVisible: true,
      openDeleteModal: false,
    });
    await this.fetchReservation(this.state.page, this.state.perPage);
    await this.fetchTableReservation();
  }

  // Change Shift Filter
  async handleChangeShift(e) {
    await this.setState({
      currentShift: parseInt(e.target.value),
      list_table_ids: [],
      table_ids: [],
      openDeleteModal: false,
    });
    await this.fetchReservation(this.state.page, this.state.perPage);
    await this.fetchTableReservation();
  }

  //fet Resturant NOT USED
  async getRecord(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getRestaurant(this.state.selectedRestaurantId)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          this.setState({
            restaurant: currentRecord,
          });
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  async getDailyNotes(date, id){
    this.setState({
      loading: true,
    });
    this.props
      .retriveNotes(date, id)
      .then((data) => {
        if (data.status) {
       
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  searchKeyword(e) {
    this.setState({ searchKeyword: e.target.value });
  }

  search(e) {
    this.fetchReservation(this.state.page, this.state.perPage);
  }

  async DefaultDateTime(e) {
    await this.setState(
      {
        startDate: moment(),
        list_table_ids: [],
        table_ids: [],
        openDeleteModal: false,
      },
      () => {
        this.fetchReservation(this.state.page, this.state.perPage);
      }
    );
    this.fetchRestaurantHourTime(
      this.state.selectedRestaurantId,
      moment().format("YYYY-MM-DD")
    );
  }

  async handleChangeTime(e) {
    await this.setState({
      selectedTime: e.target.value,
    });
    // await this.fetchReservation(this.state.page, this.state.perPage);
    await this.fetchTableReservation();
  }

  handleChangeInitial(e) {
    this.setState({ initial: e.target.value });
  }

  handleChangeCancelReason(e) {
    this.setState({ cancel_reason: e.target.value });
  }

  async deleteSaveData(values) {
    this.setState({
      loading: true,
    });

    await this.props
      .deleteReservationMultiple(values)
      .then((data) => {
        this.setState({
          loading: false,
          openDeleteModal: false,
          list_table_ids: [],
        });
        toast.success(this.props.t("messages.delsucmessage"));
        //this.props.history.push("/guests");
        this.fetchReservation(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  handlePerRowsChange = async (perPage) => {
    //alert(" HAbd PER PAGE " + perPage);
    this.setState({ perPage: perPage, list_table_ids: [] });
    //_pre(" newPerPage ", perPage);
    await this.fetchReservation(this.state.page, perPage);
  };

  handlePageChange = async (page) => {
    this.setState({ page: page, list_table_ids: [] });
    //this.fetchUsers(page);
    await this.fetchReservation(page, this.state.perPage);
  };

  fetchRestaurantTable = async (page, perPage) => {
    this.setState({ loading: true });
    if(this.state.selectedRestaurantId !== 0){
    await this.props.retrieveRestaurantsTables(
      "",
      "",
      this.state.selectedRestaurantId
    );
    }
    this.setState({ loading: false });
  };

  doSubmit = (e) => {
    this.setState({
      loading: true,
    });

    //this.form.validateAll();

    //this.props.history.push("/movies");
  };

  deleteData(values) {
    
    if (this.state.list_table_ids.length > 0) {
      // console.log(values, this.state.list_table_ids, "deletedata");
      this.deleteConfirm(this.state.list_table_ids[0], values);
    }
  }

  deleteConfirm(id, values) {
    // return false;
    this.setState({
      loading: true,
    });
    this.props
      .deleteReservation(id, values)
      .then((data) => {
        this.setState({
          loading: false,
          openDeleteModal: false,
          list_table_ids:[],
        });
        toast.success(this.props.t("messages.delsucmessage"));
        //this.props.history.push("/guests");
        this.fetchReservation(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  async onTableSelectChange(e) {
    let arraytableid = this.state.table_ids;
    // //console.log(e.target.checked);
    if (e.target.checked) {
      arraytableid.push(e.target.getAttribute("id"));
    } else {
      let index = arraytableid.indexOf(e.target.getAttribute("id"));
      arraytableid.splice(index, 1);
      // arraytableid.pop(e.target.getAttribute("id"));
    }

    await this.setState({
      table_ids: arraytableid,
    });
  }

  async OnNextClick(e,arrayOfTableNext) {


    let arrayNext = this.state.arrayOfTableNext.filter((b) =>
          b.isCurrentTableId.includes(e.currentTarget.getAttribute("data-id"))
      );
      // console.log(arrayNext.length );
      let arrayOfTable;
    if(arrayNext.length === 0){
      arrayOfTable = {
        isCurrentTableId: e.currentTarget.getAttribute("data-id"),
        isCurrent: 1,
        showReservation: 1,
      };
      this.setState({
        arrayOfTableNext: [...this.state.arrayOfTablePrevious, arrayOfTable],
      });
    }else{
      arrayOfTable = {
        isCurrentTableId: e.currentTarget.getAttribute("data-id"),
        isCurrent: 1,
        showReservation: arrayNext.length,
      };

      this.setState({
        arrayOfTableNext: [arrayOfTable],
      });
    }

    // console.log(this.state.arrayOfTableNext, e.currentTarget.getAttribute('data-id'));
    // arrayOfTableNext
    // console.log(e.currentTarget.getAttribute('data-id'));

    
    // this.setState({
    //   isCurrent:1,
    //   isCurrentTableId:e.currentTarget.getAttribute('data-id')
    // });
  }

  async OnPreviousClick(e, data, type) {
    // console.log("next");
    // console.log(data, e);
    // return false;
    // let tempArray = [];
    // let isCurentReservation = [];
   
    let tempIndex = 0;

    if(type === "next"){

    
      const { isArrayOfTable } = this.state;

      const findExistingItem = isArrayOfTable.find((item) => {
        // To find by 'label', replace 'uniqueId' below with 'label'.
        return item.isCurrentTableId === e.currentTarget.getAttribute("data-id");
      })

      // console.log(findExistingItem);
      // let arrayPrevious = this.state.isArrayOfTable.filter((b) =>
      //     b.isCurrentTableId.includes(e.currentTarget.getAttribute("data-id"))
      // );
      const index = this.state.isArrayOfTable.findIndex((item) => item.isCurrentTableId === e.currentTarget.getAttribute("data-id"))
      const newArray = [...this.state.isArrayOfTable]

     
      if(findExistingItem ===  undefined ){
       
        
         let arrayOfTable = {
            isCurrentTableId: e.currentTarget.getAttribute("data-id"),
            isCurrent: tempIndex + 1, //1
            isCurrentReservationData: data.reservation_next[tempIndex] ,
          };
         
          await this.setState(state => ({
            isArrayOfTable: [...this.state.isArrayOfTable, arrayOfTable]
          }));
          // console.log(arrayOfTable,tempIndex, "if");

      }else{
        // console.log(findExistingItem, "else");
          newArray.splice(index, 1);
         
          let lastIndex = findExistingItem.isCurrent;
          console.log(lastIndex, "next");
          let arrayOfTable;
           if(lastIndex < 0){
           
            let checkIndexAvailable = Math.abs(lastIndex);
            let totalcountNr = data.reservation_previous.length;
            console.log(checkIndexAvailable , totalcountNr)
         
             arrayOfTable = {
              isCurrentTableId: e.currentTarget.getAttribute("data-id"),
              isCurrent: checkIndexAvailable === totalcountNr ? lastIndex + 2 : lastIndex + 1,
              isCurrentReservationData: checkIndexAvailable === totalcountNr ? data.reservation_previous[Math.abs(lastIndex) - 2 ] : lastIndex == -1 && data.reservation_next.length == undefined && data.reservation_current !== undefined ? data.reservation_current : data.reservation_previous[Math.abs(lastIndex) - 1 ],
            };
          

            newArray.push(arrayOfTable)

            await this.setState({
              isArrayOfTable: newArray,
            });
          

           }else if( lastIndex > 0){

            let checkIndexAvailable = Math.abs(lastIndex); // 1 // 2
            console.log(lastIndex, "rser" );
            let arrayOfTable;
            let totalcountNr = data.reservation_next.length;
            console.log(totalcountNr, checkIndexAvailable, this.state.isArrayOfTable[0].isCurrentReservationData.guest_id , data.reservation_current.guest_id);
            if(checkIndexAvailable === totalcountNr || this.state.isArrayOfTable[0].isCurrentReservationData.reservation_id === data.reservation_current.reservation_id){
              console.log("SSSSs")
              arrayOfTable = {
                isCurrentTableId: e.currentTarget.getAttribute("data-id"),
                isCurrent: lastIndex,
                isCurrentReservationData: data.reservation_next[lastIndex - 1],
              };
            }else{
              console.log(e.currentTarget.getAttribute("data-id"), lastIndex);
            
              arrayOfTable = {
                isCurrentTableId: e.currentTarget.getAttribute("data-id"),
                isCurrent: ((lastIndex + 1) === totalcountNr) ? lastIndex : lastIndex +  1 ,
                isCurrentReservationData: data.reservation_next[lastIndex],
              };
            }
              // arrayOfTable = {
              //   isCurrentTableId: e.currentTarget.getAttribute("data-id"),
              //   isCurrent: lastIndex + 1,
              //   isCurrentReservationData: data.reservation_next[lastIndex - 1],
              // };
           

            // let arrayOfTable = {
            //   isCurrentTableId: e.currentTarget.getAttribute("data-id"),
            //   isCurrent: lastIndex + 1,
            //   isCurrentReservationData: data.reservation_next[lastIndex - 1],
            // };
            // console.log(arrayOfTable);
            newArray.push(arrayOfTable)

            await this.setState({
              isArrayOfTable: newArray,
            });
          

           }else if (lastIndex === 0){
            // console.log(lastIndex);
            let arrayOfTable = {
              isCurrentTableId: e.currentTarget.getAttribute("data-id"),
              isCurrent: data.reservation_current !== undefined ? lastIndex + 1 : lastIndex + 2 ,
              isCurrentReservationData: data.reservation_current !== undefined ? data.reservation_current : data.reservation_next[lastIndex] ,
            };
          
            newArray.push(arrayOfTable);
            // console.log(newArray);
            await this.setState({
              isArrayOfTable: newArray,
            });
            

           }



      }


    }else if(type === "previous"){

      const { isArrayOfTable } = this.state;

      const findExistingItem = isArrayOfTable.find((item) => {
        // To find by 'label', replace 'uniqueId' below with 'label'.
        return item.isCurrentTableId === e.currentTarget.getAttribute("data-id");
      })
      // let arrayPrevious = this.state.isArrayOfTable.filter((b) =>
      //     b.isCurrentTableId.includes(e.currentTarget.getAttribute("data-id"))
      // );
      const index = this.state.isArrayOfTable.findIndex((item) => item.isCurrentTableId === e.currentTarget.getAttribute("data-id"))
      const newArray = [...this.state.isArrayOfTable]


      if(findExistingItem ===  undefined ){

        
        let arrayOfTable = {
           isCurrentTableId: e.currentTarget.getAttribute("data-id"),
           isCurrent: tempIndex - 1,
           isCurrentReservationData: data.reservation_previous[tempIndex],
         };
        
         await this.setState(state => ({
           isArrayOfTable: [...this.state.isArrayOfTable, arrayOfTable]
         }));
       

     }else{
         
         newArray.splice(index, 1);
        
         let lastIndex = findExistingItem.isCurrent;
         console.log(lastIndex, "previous");
          if(lastIndex < 0){
            let checkIndexAvailable = Math.abs(lastIndex);
            let arrayOfTable
            if(checkIndexAvailable === data.reservation_previous.length || this.state.isArrayOfTable[0].isCurrentReservationData.reservation_id === data.reservation_current.reservation_id){
            //  console.log(lastIndex);
              arrayOfTable = {
                isCurrentTableId: e.currentTarget.getAttribute("data-id"),
                isCurrent: lastIndex ,
                isCurrentReservationData: data.reservation_previous[Math.abs(lastIndex) - 1],
              };
            }else{
              arrayOfTable = {
                isCurrentTableId: e.currentTarget.getAttribute("data-id"),
                isCurrent: lastIndex - 1,
                isCurrentReservationData: data.reservation_previous[Math.abs(lastIndex)],
              };
            }
          
         

           newArray.push(arrayOfTable)

           await this.setState({
             isArrayOfTable: newArray,
           });
         

          }else if( lastIndex > 0){

            console.log(data.reservation_previous.length > 0, "rser" );
           let arrayOfTable = {
             isCurrentTableId: e.currentTarget.getAttribute("data-id"),
             isCurrent:  lastIndex - 1 ,
             isCurrentReservationData: data.reservation_previous.length === undefined && lastIndex == 2 ? data.reservation_next[lastIndex - 2] : data.reservation_next[lastIndex - 1] ,
           };
           newArray.push(arrayOfTable)

           await this.setState({
             isArrayOfTable: newArray,
           });
         

          }else if (lastIndex === 0){
            console.log(data.reservation_current);
          //  findExistingItem.isCurrentTableId = e.currentTarget.getAttribute("data-id");
          //  findExistingItem.isCurrent = lastIndex + 1;
          //  findExistingItem.isCurrentReservationData = data.reservation_current;
         
           let arrayOfTable = {
             isCurrentTableId: e.currentTarget.getAttribute("data-id"),
             isCurrent: data.reservation_current !== undefined ? lastIndex - 1 : lastIndex - 2 ,
             isCurrentReservationData: data.reservation_current !== undefined ? data.reservation_current : data.reservation_previous[lastIndex] ,
           };
           newArray.push(arrayOfTable)
           await this.setState({
             isArrayOfTable: newArray,
           });
           

          }



     }

    }
    // let arrayPrevious = this.state.arrayOfTablePrevious.filter((b) =>
    //       b.isCurrentTableId.includes(e.currentTarget.getAttribute("data-id"))
    //   );
    //   let arrayOfTable;
    //   if(arrayPrevious.length === 0){
    //     arrayOfTable = {
    //       isCurrentTableId: e.currentTarget.getAttribute("data-id"),
    //       isCurrent: 2,
    //       showReservation: 0,
    //     };
    //     this.setState({
    //       arrayOfTablePrevious: [...this.state.arrayOfTablePrevious, arrayOfTable],
    //     });
    // }else{
    //     arrayOfTable = {
    //       isCurrentTableId: e.currentTarget.getAttribute("data-id"),
    //       isCurrent: 2,
    //       showReservation: arrayPrevious.length,
    //     };
    //     this.setState({
    //       arrayOfTablePrevious: [arrayOfTable],
    //     });
    // }
    // console.log(this.state.arrayOfTablePrevious, e.currentTarget.getAttribute('data-id'));

    // console.log(e.currentTarget.getAttribute('data-id'));
    
    // this.setState({
    //   arrayOfTable:2,
    //   isCurrentTableId:e.currentTarget.getAttribute('data-id')
    // });
    // }
  }

  async OnNextPrevClick(e, data, type){

    console.log(data);
    let formdata = {
      type: type,
      table_id: e.currentTarget.getAttribute("data-id"),
      date: data.reservation_date !== undefined ? data.reservation_date : this.state.selectedStartDate,
      time: data.reservation_time !== undefined ? data.reservation_time : this.state.selectedTime,
      restaurant_id: this.state.selectedRestaurantId,
    };
    // this.setState({
    //   loading: true,
    // });
      await this.props
        .getNextPrevreservation(formdata)
        .then((data) => {
          if(data.status){
            console.log(data.data);
          const { isArrayOfTable } = this.state;

          const findExistingItem = isArrayOfTable.find((item) => {
            // To find by 'label', replace 'uniqueId' below with 'label'.
            return item.isCurrentTableId === formdata.table_id;
          })
          const index = this.state.isArrayOfTable.findIndex((item) => item.isCurrentTableId === formdata.table_id)
          const newArray = [...this.state.isArrayOfTable]

          console.log(findExistingItem);
          if(findExistingItem ===  undefined ){
          
            
            let arrayOfTable = {
              isCurrentTableId: formdata.table_id,
              isPreviousCount: data.data.prevReservationCount,
              isNextCount: data.data.nextReservationCount, //1
              isCurrentReservationData: type == "next" ? data.data.nextReservation : data.data.previousReservation,
            };
            
            this.setState(state => ({
              isArrayOfTable: [...this.state.isArrayOfTable, arrayOfTable]
            }));
            console.log(arrayOfTable, "if");

        }else{
          newArray.splice(index, 1);
          let arrayOfTable = {
            isCurrentTableId: formdata.table_id,
            isPreviousCount: data.data.prevReservationCount,
            isNextCount: data.data.nextReservationCount,
            isCurrentReservationData: type == "next" ? data.data.nextReservation : data.data.previousReservation,
          };

          newArray.push(arrayOfTable);
          console.log(arrayOfTable);
          this.setState({
            isArrayOfTable: newArray,
          });
          

        }
      }
          // this.setState({
          //   loading: false,
          //   openDeleteModal: false,
          //   list_table_ids:[],
          // });
          // toast.success(this.props.t("messages.delsucmessage"));
          // //this.props.history.push("/guests");
          // this.fetchReservation(this.state.page, this.state.perPage);
        })
        .catch((e) => {
          //console.log(e);
          this.setState({
            loading: false,
          });
        });

  }

  async onTableListSelectChange(e) {
    console.log(e.target.getAttribute('data-id'));
    let arrayTableDate =  this.state.list_table_dates; 
    if(this.state.booking_id !== "" ){
      if (e.target.checked) {
        arrayTableDate.push(e.target.getAttribute('data-id'));
     

      } else {
        let index = arrayTableDate.indexOf(e.target.getAttribute('data-id'));
        arrayTableDate.splice(index, 1);
        // arraytableid.pop(e.target.getAttribute("id"));
      }
    }
    let arraytableListid = this.state.list_table_ids;
    // //console.log(e.target.checked);
    if (e.target.checked) {
      arraytableListid.push(e.target.value);
    } else {
      let index = arraytableListid.indexOf(e.target.value);
      arraytableListid.splice(index, 1);
      // arraytableid.pop(e.target.getAttribute("id"));
    }
// console.log(this.state.list_table_ids.length);
    if(this.state.list_table_ids.length > 0){
      this.setState({
        list_table_ids: arraytableListid,
        list_table_dates:arrayTableDate,
      });
    }else{
      this.setState({
        list_table_ids: arraytableListid,
        list_table_dates:arrayTableDate,
        openDeleteModal: false,
        buttonText: 'Assign',
      });
    }
    // console.log(e.target.getAttribute('data-id'), );
    if( this.state.booking_id !== "" ){
      await this.fetchTableReservation();
    }
  }

  addWalkInReservation(e) {
    this.setState({
      loading: true,
    });

    let values = {
      restaurant_id: this.state.selectedRestaurantId,
      table_id: this.state.table_ids[0],
    };

    //Update
    this.props
      .addWalkInReservation(values)
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          this.props.history.push("/reservation/map");
          this.onGetList();
        } else {
          toast.error(data.message);
        }
        this.setState({
          loading: false,
          table_ids: [],
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  TableMap(e) {
    if (this.state.tableMapOpen) {
      this.setState({
        tableMapOpen: false,
      });
    } else {
      this.setState({
        tableMapOpen: true,
      });
    }
  }

  disabledDate = (current) => {
    let disbledDate =
      current < Date.now() ||
      new Date(current).getDay() === 0 ||
      new Date(current).getDay() === 6;

    return disbledDate;
  };

  onExachangeTable(e) {
    if (this.state.list_table_ids.length >= 2) {
      confirmAlert({
        title: this.props.t("messages.exchangeReservationMsg"),
        message: this.props.t("messages.doyouwantcon"),
        buttons: [
          {
            label: this.props.t("messages.yes"),
            onClick: () => this.ExchangeConfirm(),
          },
          {
            label: this.props.t("messages.no"),
            onClick: () => "",
          },
        ],
      });
    } else {
      toast.error(this.props.t("messages.errorForTableSelectExchange"));
    }
  }

  ExchangeConfirm() {}

  onGroupTable(e) {

    //console.log(this.state.list_table_ids);console.log(this.state.table_ids);return false;
    if (
      this.state.list_table_ids.length >= 1 &&
      this.state.table_ids.length >= 1
    ) {

      confirmAlert({
        title: this.props.t("messages.groupTableReservationMsg"),
        message: this.props.t("messages.doyouwantcon"),
        buttons: [
          {
            label: this.props.t("messages.yes"),
            onClick: () => this.GroupConfirm(),
          },
          {
            label: this.props.t("messages.no"),
            onClick: () => "",
          },
        ],
      });
    } else {
      toast.error(this.props.t("messages.errorForTableSelectGroup"));
    }
  }

  GroupConfirm() {
    let formdata = {
      time_slot:
        this.state.selectedTime !== ""
          ? this.state.selectedTime
          : this.props.reservationsSettings.standard_time + ":00",
      restaurant_id: this.state.selectedRestaurantId,
      table_ids: this.state.table_ids,
      reservation_ids: this.state.list_table_ids,
      reservation_date: this.state.selectedStartDate
    };

    this.props
      .makeGroupReservation(formdata)
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          // this.props.history.push("/reservation/map");
          this.onGetList();
        } else {
          toast.error(data.message);
        }
        this.setState({
          loading: false,
          table_ids: [],
          list_table_ids: []
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });

    // console.log(data);
  }


  handleChangeChk(e){
    // console.log(e.target.checked);
     if(e.target.checked){
      if( e.target.value === "Delete"){
        // this.onToggle();
      this.setState({
        buttonText: e.target.value,
        openDeleteModal: true,
      });
      // this.onToggle();
    }else{
      this.setState({
        buttonText: e.target.value,
        openDeleteModal: false,
      });
    }
     }
  }


 UpdateReservationDateTime() {

  if (
    this.state.list_table_ids.length >= 1 &&
    this.state.table_ids.length >= 1
  ){ 
    let formdata = {

      time_slot:
        this.state.selectedTime !== ""
          ? this.state.selectedTime
          : this.props.reservationsSettings.standard_time + ":00",
      restaurant_id: this.state.selectedRestaurantId,
      table_ids: this.state.table_ids,
      reservation_ids: this.state.list_table_ids,
      reservation_date: this.state.selectedStartDate
    };

    this.props
      .makeGroupReservation(formdata)
      .then((data) => {
        if (data.status) {
          toast.success(data.message);
          // this.props.history.push("/reservation/map");
          this.onGetList();
        } else {
          toast.error(data.message);
        }
        this.setState({
          loading: false,
          table_ids: [],
          list_table_ids: []
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  } else {
    toast.error(this.props.t("messages.errorForTableUpdate"));
  
  }

    // console.log(data);
  }

  onToggle(e) {
    if (this.state.openDeleteModal) {
      this.setState({
        openDeleteModal: false,
      });
    } else {
      this.setState({
        openDeleteModal: true,
      });
    }
  }

  //TODO if form reset then use this open filed to reset form
  onSwitch(e) {
    if (this.state.open) {
      this.setState({
        open: false,
      });
      this.props.clearMessage();
    } else {

     
      this.setState({
        open: true,
      });
      // console.log(this.container1);
      this.container1.scrollTop = 0;
      // const section = document.querySelector('#addReservetion');
      // section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
      // this.myP.current.scrollIntoView({ behavior: "smooth", block: 'start' });
    }
  }

  onSwitchUpdate(e) {
 
       this.setState({
        openUpdate: true,
      });

      this.container1.scrollTop = 0;
    
  }

  onSwitchUpdateClose(e) {
 
    this.setState({
     openUpdate: false,
     isCurrentReservation: 0,
   });
   this.props.clearMessage();
 
  }

  openModalNote(e){
    if (this.state.openModalNote) {
      this.setState({
        openModalNote: false,

      });
    } else {
      this.setState({
        openModalNote: true,
      });
    }
  }

  pdfUploadForm(e){
    if (this.state.openModalPdf) {
      this.setState({
        openModalPdf: false,

      });
    } else {
      this.setState({
        openModalPdf: true,
      });
    }
  }

  handfileUpload( values, e){
    values.attachment_file = e.target.files[0];
  }
  
  async addNoteData(values){
    this.setState({
      loading: true,
    });

    this.props
      .createNote(values)
      .then((data) => {
        if (data.status) {

          this.setState({
            openModalNote: false,
          });
          this.getDailyNotes(values.note_date, values.restaurant_id);
          toast.success(data.message);
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });

  }

  async addpdfData(values){
    this.setState({
      loading: true,
    });
    // console.log(values);
    // return false;
    let formData = new FormData();
    formData.append("note_date", values.note_date); 
    formData.append("restaurant_id", values.restaurant_id);
    formData.append("attachment_file", values.attachment_file);


    this.props
      .uploadPdfNotes(formData)
      .then((data) => {
        if (data.status) {

          this.setState({
            openModalPdf: false,
          });
          this.getDailyNotes(values.note_date, values.restaurant_id);
          toast.success(data.message);
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  async onGetList(e) {
    await this.setState({

      table_ids: [],
      isCurrentReservation: 0,
    });
    await this.fetchReservation(this.state.page, this.state.perPage);
    await this.fetchTableReservation();
  }

  async onEditReservation(id) {
    this.setState({
      loading: true,
    });
    await this.props
      .getReservation(id)
      .then((data) => {
        if (data.status) {
          // const { detail: currentRecord } = data.data;
          this.setState({
            isCurrentReservation: 1,
          });
          this.onSwitchUpdate();
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  async print(){
    var css = '@page { size: landscape; }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet){
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    await this.TableMap();
    window.print();
  }


  commonAction(values){

    // console.log(values, this.state.buttonText, this.state.buttonText === 'Delete');
    if(this.state.buttonText === "Assign" ){

      this.GroupConfirm()
   

    }else if(this.state.buttonText === "Group"){
      
      this.onGroupTable()

    }else if(this.state.buttonText === "Exchange"){

      this.UpdateReservationDateTime();

    }else if(this.state.buttonText === 'Delete'){
      
      this.deleteData(values);
    }
  }

  async commonStatus(e, row){
     
  
      if(row.table_id === 0){
        toast.warning("Please assign a table before continuing");
      
     }else{

      this.setState({
        loading: true,
      });

      let formData = new FormData();
      formData.append("reservation_id", row.reservation_id);
      await this.props
        .changeStatusReservation(formData)
        .then((data) => {
          
          if (data.status) {
              // console.log(data.message);
              toast.success(data.message);
              this.onGetList()
          } else {
            toast.error(data.message);
          
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
        
          this.setState({
            loading: false,
          });
        });

     }
  }

  render() {
    //console.log(typeof this.state.selectedTime, "reservations");
    // const paginationComponentOptions = {
    //   rowsPerPageText: this.props.t("datatable.rowperpage"),
    //   rangeSeparatorText: this.props.t("datatable.of"),
    //   selectAllRowsItem: true,
    // };

    const CustomLoader = () => (
      <div>
        <h2 className="text-center">{this.props.t("datatable.loading")}</h2>
      </div>
    );
    const { loadingTable, tableList , list_table_ids} = this.state;
    const {
      restaurants,
      reservations,
      reservationsSettings,
      reservationDateSettings,
      restaurantHours,
      notes,
      guests,
      // restaurantTables,
      message,
      currentReservation,
    } = this.props;

    // console.log("*******INNNN");
    // console.log(currentReservation);
    // console.log("*******EEEEEEEEEEEEEEND");
    // return false;
    let buttonClass = this.state.open
      ? "show"
      : this.state.openUpdate
      ? "show"
      : "";
    // console.log(buttonClass);
      // let modalbuttonClass = this.state.openModalNote
      // ? "show"
      // : "";
    // let toggleClass = this.state.open ? " ": "d-none";
    // buttonClass = this.state.openUpdate ? "show" : " ";
    let tableMapShow =
      this.state.tableMapOpen &&
      this.props.history.location.pathname === "/reservation/map"
        ? "show"
        : " ";

    let tableMapShowData =
      this.state.tableMapOpen &&
      this.props.history.location.pathname === "/reservation/map"
        ? "w-50"
        : "";
    let showArrow =
      this.state.tableMapOpen &&
      this.props.history.location.pathname === "/reservation/map"
        ? "d-none"
        : this.props.history.location.pathname === "/reservation/list"
        ? "d-none"
        : "d-block";

    let showIconText =
      this.state.tableMapOpen &&
      this.props.history.location.pathname === "/reservation/map"
        ? "d-none"
        : this.props.history.location.pathname === "/reservation/list"
        ? ""
        : "";

    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
          //background: "#cb4b16",
        },
      },
    };
    // const format = "HH:mm";
    if (reservationDateSettings !== undefined) {
      // let blockdate = _getDateBlocked(reservationDateSettings);
    }

    let top = reservations.list ? "59%" : "420%";

    // console.log(guests, "euuu");
    // //console.log(this.state);
    // const {  } = this.props;
    //const { message } = this.props;
    //const notify = () => toast.error("Wow so easy!");
    // let blockDate = ["2022-09-22", "2022-09-23"];
// console.log(buttonClass,this.state, "ssssaq22");
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                   
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.t("pagetitle.reservations")}
                        {/* <button onClick={notify}>Notify!</button> */}
                      </h4>

                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                    <Link to="map" type="button" className="active" data-toggle="tooltip" title={this.props.t('reservation.title.tableView')}>
                      {this.props.history.location.pathname ===
                      "/reservation/map" ? (
                        <img
                          src={`${window.location.origin}/img/table_map.png`}
                          alt="tableMapActive"
                          height={"40px"}
                          width={"40px"}
                        />
                      ) : (
                        <img
                          src={`${window.location.origin}/img/table_map.svg`}
                          alt="tableMap"
                          height={"40px"}
                          width={"40px"}
                        />
                      )}
                    </Link>
                    <Link to="list" type="button" className="ml-4" data-toggle="tooltip" title={this.props.t('reservation.title.listView')}>
                      {this.props.history.location.pathname ===
                      "/reservation/list" ? (
                        <img
                          src={`${window.location.origin}/img/list_table.png`}
                          alt="tableListActive"
                          height={"40px"}
                          width={"40px"}
                        />
                      ) : (
                        <img
                          src={`${window.location.origin}/img/list_table.svg`}
                          alt="tableList"
                          height={"40px"}
                          width={"40px"}
                        />
                      )}
                    </Link>
                    <Link to="calender" type="button" className="ml-4" data-toggle="tooltip" title={this.props.t('reservation.title.calenderView')}>
                      {this.props.history.location.pathname ===
                      "/reservation/calender" ? (
                        <img
                          src={`${window.location.origin}/img/calender_table_active.png`}
                          alt="tableCalenderActive"
                          height={"40px"}
                          width={"40px"}
                        />
                      ) : (
                        <img
                          src={`${window.location.origin}/img/calender_table.png`}
                          alt="tableCalender"
                          height={"40px"}
                          width={"40px"}
                        />
                      )}
                    </Link>
                  </div>
                {   this.props.history.location.pathname !==
                  "/reservation/calender" && this.state.selectedRestaurantId !== 0 && notes.detail !== undefined ?
                 <div role="alert" className="alert alert-success alert-dismissible fade show mb-0 pr-0">
                  {notes.detail.note_text}
                  <button type="button" onClick={this.pdfUploadForm} data-dismiss="alert" aria-label="Close" className="btn">
                  <i className="far fa-file-pdf text-success"></i>
                   {/* <i className="fas fa-file-pdf text-white" /> */}
                  </button>
                </div>
                 : ""
                }

                  
                  {this.props.history.location.pathname !==
                  "/reservation/calender" ? (

                    
                    <div className="d-inline-flex float-right">
                      { parseInt(this.state.selectedRestaurantId)!== 0 ?
                      <button
                        type="button"
                        className="btn btn-sm btn-success padding-button-res"
                        data-toggle="tooltip" title="Note of the day"
                        onClick={(e) => this.openModalNote(e)}
                      >
                        <img
                          src={`${window.location.origin}/img/notes-icon.png`}
                          alt="Notes"
                          height={"20px"}
                          width={"20px"}
                        />
                      </button>
                      : ""
                      }
                      {this.state.restaurant &&
                      this.state.table_ids.length === 1 &&
                      this.state.startDate.format("YYYY-MM-DD") ===
                        moment().format("YYYY-MM-DD") ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ml-4 padding-button-res"
                          onClick={(e) => this.addWalkInReservation(e)}
                          data-toggle="tooltip"  title={this.props.t('reservation.title.walkIn')}
                        >
                          <i
                            className="fas fa-walking mr-0"
                            aria-hidden="true"
                          ></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ml-4 padding-button-res disabled"
                          data-toggle="tooltip" title={this.props.t('reservation.title.walkIn')}
                        >
                          <i
                            className="fas fa-walking mr-0"
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}

                      {this.state.restaurant &&
                      this.state.startDate.format("YYYY-MM-DD") >=
                        moment().format("YYYY-MM-DD") ? (
                        <button
                          className="btn btn-primary btn-default ml-4 btn-squared"
                          onClick={(e) => this.onSwitch(e)}
                          data-toggle="tooltip" title={this.props.t('reservation.title.addReservation')}
                        >
                          {" "}
                          <i className="fa fa-plus mr-0" aria-hidden="true"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={"drawer-basic-wrap account " + buttonClass}>
            <div id="addReservetion"   ref={el => (this.container1 = el)}
              className={"atbd-drawer drawer-account "}
              style={{ overflow: "auto" }}
            >
              <div className="atbd-drawer__header d-flex aling-items-center justify-content-between add-reservetion">
                <h6 className="drawer-title">
                  {this.state.isCurrentReservation === 1
                    ? this.props.t("reservation.updateReservation")
                    : this.props.t("reservation.addReservation")}
                </h6>
                {this.state.isCurrentReservation === 1 ? (
                  <a
                    href="#!"
                    className="btdrawer-close"
                    onClick={(e) => this.onSwitchUpdateClose(e)}
                  >
                    <i className="la la-times text-white" />
                  </a>
                ) : (
                  <a
                    href="#!"
                    className="btdrawer-close"
                    onClick={(e) => this.onSwitch(e)}
                  >
                    <i className="la la-times text-white" />
                  </a>
                )}
              </div>
              <div className="atbd-drawer__body">
                <div className="drawer-content">
                  {this.state.currentCategory !== " " &&
                  this.state.selectedRestaurantId !== 0 &&
                  reservationDateSettings !== undefined &&
                  reservationsSettings !== undefined &&
                  this.state.isCurrentReservation === 0 &&
                  this.state.table_ids.length > 0 ? (
                    <Addreservation
                      childrencategory={this.state.currentCategory}
                      restaurant_id={this.state.selectedRestaurantId}
                      switch={() => this.onSwitch()}
                      restaurantDate={reservationDateSettings}
                      selectedDate={this.state.startDate}
                      selectedTime={this.state.selectedTime}
                      restaurantTime={reservationsSettings}
                      table_id={this.state.table_ids}
                      currentShift={this.state.currentShift}
                      otherData={this.props}
                      reservationList={() => this.onGetList()}
                      confirmationEmail={this.state.confirmationEmail}
                    />
                  ) : this.state.isCurrentReservation === 0 ? (
                    <Addreservation
                      childrencategory={this.state.currentCategory}
                      history={this.props.history}
                      switch={() => this.onSwitch()}
                      restaurant_id={this.state.selectedRestaurantId}
                      selectedDate={this.state.startDate}
                      selectedTime={this.state.selectedTime}
                      restaurantDate={reservationDateSettings}
                      restaurantTime={reservationsSettings}
                      table_id={this.state.table_ids}
                      currentShift={this.state.currentShift}
                      reservationList={() => this.onGetList()}
                      confirmationEmail={this.state.confirmationEmail}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.isCurrentReservation === 1 ? (
                    <Updatereservation
                      childrencategory={this.state.currentCategory}
                      history={this.props.history}
                      switch={() => this.onSwitchUpdateClose()}
                      restaurant_id={this.state.selectedRestaurantId}
                      selectedDate={this.state.startDate}
                      selectedTime={this.state.selectedTime}
                      restaurantDate={reservationDateSettings}
                      restaurantTime={reservationsSettings}
                      table_id={this.state.table_ids}
                      currentShift={this.state.currentShift}
                      reservationList={() => this.onGetList()}
                      currentData={currentReservation.data.reservation}
                      confirmationEmail={this.state.confirmationEmail}
                    />
                  ) : (
                    ""
                  )}
                   
                </div>
              </div>
            </div>
            {this.state.isCurrentReservation === 1 ? (
                  <div
                  className={"overlay-dark " + buttonClass}
                  onClick={(e) => this.onSwitchUpdateClose(e)}
                ></div>
                ) : (
                  <div
                  className={"overlay-dark " + buttonClass}
                  onClick={(e) => this.onSwitch(e)}
                ></div>
                )}
           
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body minHeight">
                  {this.props.history.location.pathname !==
                  "/reservation/calender" ? (
                    <div className="row">
                      <div className="col-lg-4 col-md-5">
                        <div className="action-btn  form-inline-action d-flex ">
                          {this.state.selectedRestaurantId >= 0 ? (
                            <Form.Select
                              name="resturantList"
                              defaultValue={this.state.selectedRestaurantId}
                              /* value={this.state.selectedRestaurantId} */
                              onChange={this.handleRestaurantChange}
                              className="form-control col-md-4"
                            >
                              {this.props.history.location.pathname ===
                              "/reservation/list" ? (
                                <option key={"all"} value="0">
                                  {this.props.t(
                                    "reservation.restuarantOption.allRestaurant"
                                  )}
                                </option>
                              ) : (
                                ""
                              )}
                              {restaurants.list
                                ? restaurants.list.map((r, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={r.id}
                                        selected={
                                          this.state.selectedRestaurantId ===
                                          r.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {r.restaurant_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </Form.Select>
                          ) : (
                            ""
                          )}

                          {restaurantHours.list &&
                          restaurantHours.list.length > 0 ? (
                            <Form.Select
                              name=""
                              defaultValue={0}
                              onChange={this.handleChangeShift}
                              className="form-control col-md-4 ml-3"
                            >
                             
                                <option key={"all"} selected value="0">
                                  All shifts
                                </option>
                             
                              {restaurantHours.list.map((r, index) => {
                                return (
                                  <option key={index} value={r.id}>
                                    {r.shift_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          ) : (
                            ""
                          )}
                          {this.state.status !== null ? (
                            <Form.Select
                              name="status"
                              onChange={this.handleChangeStatusFilter}
                              defaultValue={this.state.status}
                              className="form-control col-md-4 ml-3"
                            >
                              {_getArrayOfTableOption().map((r, index) => {
                                return (
                                  <option key={index} value={r.id}>
                                    {this.props.t(
                                      "reservation.TableOption." + r.value
                                    )}
                                  </option>
                                );
                              })}
                            </Form.Select>
                           ) : (
                            ""
                          )} 
                        </div>
                      </div>
                      <div className=" col-lg-2 col-md-2 ml-3">
                        <InputGroup>
                          <Form.Control
                            className=""
                            type="text"
                            onChange={this.searchKeyword}
                            name="keyword"
                            placeholder={this.props.t(
                              "reservation.addForm.seek"
                            )}
                          />
                          <Button
                            onClick={this.search}
                            className="btn btn-sm btn-primary padding-button-res"
                          >
                            <i
                              className="fa fa-search mr-0"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        </InputGroup>
                      </div>
                      {/* <div className="col-md-2"> */}

                      <div className="col-lg-2 col-md-2">
                        <div className="form-group row form-group-calender">
                          <DatePicker
                            type="text"
                            format={"YYYY/MM/DD"}
                            value={this.state.startDate}
                            allowClear={false}
                            //disabledDate={this.disabledDate}
                            size="large"
                            onChange={this.handleChangeDate}
                          />

                          <img
                            src={`${window.location.origin}/img/circle-aarow.png`}
                            onClick={this.DefaultDateTime}
                            alt="circle-arrow"
                            className="ml-3 cursor-pointer"
                            height={"30px"}
                            width={"30px"}
                          />
                          {/* </a> */}

                          {/* </div> */}
                        </div>
                      </div>

                      {reservationsSettings.time_slot ? (
                        <div className="col-lg-2 col-md-2">
                          <Form.Select
                            name="time"
                            defaultValue={this.state.selectedTime}
                            onChange={this.handleChangeTime}
                            className="form-control"
                          >
                            {reservationsSettings.time_slot.map((r, index) => {
                              return (
                                <option
                                  key={index}
                                  value={r.time}
                                  selected={
                                    r.time === this.state.selectedTime
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  {r.time.substring(0, r.time.length - 3)}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-1 col-md-2">
                        <div className="d-flex">
                        <button className="btn btn-sm btn-primary padding-button-res" onClick={this.print}>
                          {" "}
                          <i
                            className="fa fa-print mr-0"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <button className="btn btn-sm btn-primary padding-button-res ml-3" onClick={(e) => this.ExportExcel(guests, "guest_list")}>
                          {" "}
                          <i
                            className="fa fa-qrcode mr-0"
                            aria-hidden="true"
                          ></i>
                        </button>
                        </div>
                      </div>
                      
                    </div>
                  ) : (
                    ""
                  )}

                  {/* </div> */}

                  {/* <div className="" style={{ position: 'absolute', zIndex: 999, padding: '20px', borderRadius: '10px', top: '47%', left: '40%', right: '0' }}>   

                          <a href="#!" className="drawer-trigger showTableMap" data-drawer="area">

                              <i className="fa fa-angle-right"></i>

                          </a>

                      </div> */}

                  {this.props.history.location.pathname ===
                  "/reservation/calender" ? (
                    this.state.selectedRestaurantId !== 0 ? (
                      <ReservationCalender
                        restaurants={restaurants}
                        selectedRestaurantId={this.state.selectedRestaurantId}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    <>
                     { buttonClass === "" ?
                      <div className="row">
                        <div className="col-12 ">
                          <div className="">
                            <Message message={message} />
                          </div>
                        </div>
                      </div>
                      : ""
                  }
                      <div className="col-md-12">
                        <div
                          id="tableReservation"
                          className={
                            "userDatatable global-shadow border-0 bg-white " +
                            tableMapShowData
                          }
                        >
                          <div className="table-responsive">
                            <DataTable
                              // title="Users"
                              customStyles={customStyles}
                              columns={[
                                {
                                  name: "",
                                  cell: (row) => (
                                    
                                    <div>
                                       {
                                           list_table_ids.indexOf(row.reservation_id) !== -1 ?
                                        <div className="checkbox-theme-default custom-checkbox ">
                                          <input
                                            name="reservation_id"
                                            className="checkbox"
                                            type="checkbox"
                                            id={"check-un" + row.reservation_id}
                                            value={row.reservation_id}
                                            data-id={row.reservation_date}
                                            onChange={
                                              this.onTableListSelectChange
                                            }
                                            checked
                                          />
                                          <label
                                            htmlFor={
                                              "check-un" + row.reservation_id
                                            }
                                          >
                                            <span className="checkbox-text"></span>
                                          </label>
                                        </div>
                                        :   
                                        <div className="checkbox-theme-default custom-checkbox ">
                                        <input
                                          name="reservation_id"
                                          className="checkbox"
                                          type="checkbox"
                                          id={"check-un" + row.reservation_id}
                                          value={row.reservation_id}
                                          data-id={row.reservation_date}
                                          onChange={
                                            this.onTableListSelectChange
                                          }
                                        
                                        />
                                        <label
                                          htmlFor={
                                            "check-un" + row.reservation_id
                                          }
                                        >
                                          <span className="checkbox-text"></span>
                                        </label>
                                      </div>
                                }
                                    </div>
                                  ),
                                  sortable: false,
                                  width: "5%",
                                },
                                {
                                  name: this.props.t("reservation.table.time"),
                                  selector: (row) =>
                                    row.reservation_time.substring(0, 5),
                                  sortable: this.state.defaultSortingReservation === "Datum/Zeit" ? true : false,
                                  width: "10%",
                                },
                                {
                                  name: this.props.t("reservation.table.date"),
                                  selector: (row) =>
                                    _getformatDate(
                                      row.reservation_date,
                                      "DD.MM.YYYY"
                                    ),
                                  sortable: false,
                                  width: "12%",
                                  omit: this.state.isDateVisible,
                                },
                                /*  (() => {
                                    {
                                      name: this.props.t(
                                        "reservation.table.time"
                                      ),
                                      selector: (row) =>
                                        row.reservation_time.substring(0, 5),
                                      sortable: false,
                                      width: "10%",
                                    
                                  })(),
                                   */
                                {
                                  name: this.props.t("reservation.table.name"),
                                  selector: (row) =>
                                  row.first_name + " " + row.last_name,
                                  sortable: this.state.defaultSortingReservation === "Name" ? true : false,
                                  width: "10%",
                                },
                                {
                                  name: this.props.t("reservation.table.pers"),
                                  cell: (row) => (
                                    <div>
                                      {row.adults + "E"}
                                      {row.ehp === 1 ? <sub>HP</sub> : ""}
                                      {row.children_1 +
                                        row.children_2 +
                                        row.children_3 +
                                        row.children_4 !==
                                      0 ? (
                                        <span>
                                          <br />
                                          {parseInt(row.children_1) +
                                            parseInt(row.children_2) +
                                            parseInt(row.children_3) +
                                            parseInt(row.children_4)}
                                          K
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ),
                                  sortable: false,
                                  width: "7%",
                                },
                                {
                                  name: this.props.t("reservation.table.table"),
                                  selector: (row, index) => (
                                    <>
                                      {(() => {
                                        if (row.group_id !== 0) {
                                          //for group record
                                          if (this.state.group_id === "") {
                                            return (
                                              <span>
                                                {row.table_numbers}
                                                {/* &nbsp;
                                                <br />
                                                <i
                                                  className="fas fa-eye text-success custom-link"
                                                  onClick={(e) =>
                                                    this.onGroupButton(
                                                      row.group_id
                                                    )
                                                  }
                                                ></i> */}
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span>
                                                {row.table_number}
                                                {/* &nbsp; <br />
                                                {index === 0 ? ( //for group record wise list
                                                  <i
                                                    className="fas fa-eye-slash text-success custom-link"
                                                    onClick={(e) =>
                                                      this.onGroupButton("")
                                                    }
                                                  ></i>
                                                ) : (
                                                  ""
                                                )} */}
                                              </span>
                                            );
                                          }
                                        } else if (row.is_repeating === 1) {
                                          //repeating List
                                          if (this.state.booking_id === "") {
                                            return (
                                              <span>
                                                {row.table_number}
                                                &nbsp;
                                                <br />
                                                {/* {index === 0 ? ( //for group record wise list
                                                  <i
                                                    className="fas fa-eye text-warning custom-link"
                                                   
                                                  ></i>
                                                ) : (
                                                  ""
                                                )} */}
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span>
                                                {row.table_number}
                                                &nbsp;
                                                {/* <br />
                                                {index === 0 ? ( //for group record wise list
                                                  <i
                                                    className="fas fa-eye-slash text-warning custom-link"
                                                    onClick={(e) =>
                                                      this.onRepeatingButton("")
                                                    }
                                                  ></i>
                                                ) : (
                                                  ""
                                                )} */}
                                              </span>
                                            );
                                          }
                                        } else {
                                          //normal list
                                          return (
                                            <span>{row.table_number}</span>
                                          );
                                        }
                                      })()}
                                    </>
                                  ),
                                  sortable: false,
                                  width: "7%",
                                },
                                {
                                  name: this.props.t("reservation.table.room"),
                                  selector: (row) => row.room_no,
                                  sortable: false,
                                  width: "10%",
                                },
                                {
                                  name: this.props.t(
                                    "reservation.table.remark"
                                  ),
                                  cell: (row) => (
                                    <div className="d-grid">
                                      {row.note !== "" ? (
                                        <div>
                                          <i
                                            className="far fa-comment mr-2"
                                            aria-hidden="true"
                                            title="note"
                                          ></i>
                                          <span className={showIconText}>
                                            {row.note}{" "}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {row.note_local !== "" ? (
                                        <div>
                                          <i
                                            className="far fa-comment-dots mr-2"
                                            aria-hidden="true"
                                            title="local note"
                                          ></i>
                                          <span className={showIconText}>
                                            {row.note_local}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {row.remarks !== "" ? (
                                        <div>
                                          <i
                                            className="far fa-user mr-2"
                                            aria-hidden="true"
                                            title="gast-info"
                                          ></i>
                                          <span className={showIconText}>
                                            {row.remarks}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ),
                                  sortable: false,
                                  width: "10%",
                                },
                                {
                                  name: this.props.t(
                                    "reservation.table.status"
                                  ),
                                  cell: (row) => (
                                    <div>
                                      {row.status === 0 ? (
                                        <i
                                          className="fas fa-ban text-danger cursor-pointer"
                                          aria-hidden="true"
                                          onClick={(e) => this.commonStatus(e, row)}
                                        ></i>
                                      ) : row.status === 1 ? (
                                        <i
                                          className="fas fa-check-circle text-success cursor-pointer"
                                          aria-hidden="true"
                                          title={this.props.t('reservation.status.confirmed')}
                                          onClick={(e) => this.commonStatus(e, row)}
                                        ></i>
                                      ) : row.status === 2 ? (
                                        <i style={{color: '#5BABA7', fontSize: 12}}  onClick={(e) => this.commonStatus(e, row)} className="fa fa-circle-notch cursor-pointer" aria-hidden="true" title={this.props.t('reservation.status.unallocated')}  />

                                      ) : row.status === 3 ? (
                                        
                                        <i style={{color: '#D5AAFF'}}  onClick={(e) => this.commonStatus(e, row)} className="fa fa-hourglass-half cursor-pointer" aria-hidden="true"  title={this.props.t('reservation.status.waitingList')} />



                                      ) : row.status === 4 ? (
                                        <img
                                          className="w-25 cursor-pointer"
                                          alt="placeholder"
                                          title={this.props.t('reservation.status.placed')}
                                          onClick={(e) => this.commonStatus(e, row)}
                                          src={`${window.location.origin}/img/placeholder_1.png`}
                                        />
                                      ) : row.status === 5 ? (
                                        <img
                                          className="w-25 cursor-pointer"
                                          alt="placeholder"
                                          onClick={(e) => this.commonStatus(e, row)}
                                          src={`${window.location.origin}/img/placeholder_1.png`}
                                        />
                                        ) : row.status === 19 ? (
                                          <i style={{color: '#ddb343'}} onClick={(e) => this.commonStatus(e, row)} className="fa fa-location-dot cursor-pointer" aria-hidden="true"   title={this.props.t('reservation.status.placed')}/>
                                        
                                        ) : row.status === 20 ? (
                                          <i style={{color: '#FF8080'}} onClick={(e) => this.commonStatus(e, row)} className="far fa-circle cursor-pointer" aria-hidden="true"  title={this.props.t('reservation.status.notAssigned')} />
                                        
                                      ) : row.status === 21 ? (
                                        <i style={{color: 'grey'}} className="fas fa-eye-slash cursor-pointer" title={this.props.t('reservation.status.notShow')} onClick={(e) => this.commonStatus(e, row)} ></i>)
                                        :""
                                      }
                                    </div>
                                  ),
                                  sortable: false,
                                  width: "10%",
                                },
                                {
                                  name: this.props.t("reservation.table.type"),
                                  cell: (row, index) => (
                                   
                                      <>
                                        {(() => {
                                          if (row.group_id !== 0) {
                                            return (
                                              <>
                                              {this.state.group_id === "" ? ( //for group record wise list
                                                 <img
                                                 src={`${window.location.origin}/img/group_ck.png`}
                                                 alt="circle-arrow"
                                                 onClick={(e) =>
                                              this.onGroupButton(
                                                row.group_id
                                              )}
                                                 
                                               />
                                                ) : (
                                                  <img
                                            src={`${window.location.origin}/img/group.png`}
                                            alt="circle-arrow"
                                            
                                            
                                          
                                          />
                                              )}
                                              </>
                                         
                                            )
                                          }else if (row.is_repeating === 1) {

                                            
                                            //repeating List
                                            return (
                                              
                                                <Link
                                                  
                                                  className="color-primary1 color-extra-light w-100"
                                                >
                                                  <i
                                                    className={
                                                      "fa fa-retweet " +
                                                      (row.repeating_total_days_remaining ===
                                                      row.repeating_total_days
                                                        ? " text-danger "
                                                        : row.repeating_total_days_remaining ===
                                                          1
                                                        ? " color-extra-light"
                                                        : " text-warning  ")
                                                    }
                                                    onClick={(e) =>
                                                      this.onRepeatingButton(
                                                        row.booking_id
                                                      )
                                                    }></i>{" "}
                                                  {row.repeating_days_str}
                                                </Link>
                                             
                                            );
                                          }
                                        })()}
                                          </>
                                     
                                  ),
                                  sortable: false,
                                  width: this.state.isDateVisible ? "10%" : "5%",
                                },

                                {
                                  name: this.props.t("restaurant.table.action"),
                                  //selector: (row) => row.dob,
                                  cell: (row, index) => (
                                    <ul className="orderDatatable_actions table_actions mb-0 d-flex flex-wrap">
                                      {/* <li>
                                        <a
                                          href="#!"
                                          className="view color-info1 feather feather-eye color-extra-light"
                                        >
                                          <i className="fa fa-eye"></i>
                                        </a>
                                      </li> */}
                                     
                                      <li>
                                        <a
                                          href="#!"
                                          onClick={(e) =>
                                            this.onEditReservation(
                                              row.reservation_id
                                            )
                                          }
                                          className="edit color-primary color-extra-light1"
                                        >
                                          <i className="fa fa-pencil-alt"></i>
                                        </a>
                                      </li>
                                    </ul>
                                  ),
                                  ignoreRowClick: true,
                                  allowOverflow: true,
                                  button: true,
                                  key: "action",
                                  width: "15%",
                                },
                              ]}
                              data={reservations.list}
                              progressPending={loadingTable}
                              progressComponent={<CustomLoader />}
                              /* pagination
                                paginationComponentOptions={
                                  paginationComponentOptions
                                } */
                              paginationServer
                              /* paginationTotalRows={reservations.total} */
                              //selectableRows
                              /* onChangeRowsPerPage={this.handlePerRowsChange} */
                              onChangePage={this.handlePageChange}
                              highlightOnHover
                              dense
                            />
                          </div>

                          {this.state.list_table_ids.length > 0 ? (
                            <Formik

                            validationSchema={ this.state.buttonText === "Delete" ? this.schema : this.state.requiredIntial === 1 ? this.schemaOther : ""}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize="true"
                            onSubmit={(values) => {
                              // console.log(values);
                              this.commonAction(values);
                              // this.UpdateReservationDateTime(values);
                            }}
                            initialValues={{
                              reservation_ids: this.state.list_table_ids,
                              initials: this.state.initial,
                              cancel_reason: this.state.cancel_reason,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <div className="row mt-10">
                                  <div className="col-md-4">
                                    <div className="d-flex ">

                                  
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" data-value="Assign" type="radio"  defaultChecked={this.state.buttonText === "Assign" ? true : false } onChange={this.handleChangeChk}  name="inlineRadioOptions" id="inlineRadio1" defaultValue="Assign" />
                                          <label className="form-check-label" htmlFor="inlineRadio1">
                                          { this.state.buttonText === "Assign" ? 
                                          <img
                                          src={`${window.location.origin}/img/assign_table_ck.png`}
                                          alt="circle-arrow"
                                      
                                        />
                                        : <img
                                          src={`${window.location.origin}/img/assign_table.png`}
                                          alt="circle-arrow"
                                         
                                        />
                                        }
                                          </label>
                                        </div>
                                        { this.state.booking_id === "" ?
                                        <div className="form-check form-check-inline ml-4">
                                          <input className="form-check-input" data-value="Delete"  type="radio" defaultChecked={this.state.buttonText === "Delete" ? true : false } onChange={this.handleChangeChk}  name="inlineRadioOptions" id="inlineRadio2" defaultValue="Delete" />
                                          <label className="form-check-label" htmlFor="inlineRadio2">
                                          { this.state.buttonText === "Delete" ? 
                                            <img
                                              src={`${window.location.origin}/img/delete_ck.png`}
                                              alt="circle-arrow"
                                           
                                            />
                                        :  <img
                                              src={`${window.location.origin}/img/delete.png`}
                                              alt="circle-arrow"
                                             
                                            />
                                        } 
                                          </label>
                                        </div>
                                         :""
                                      }
                                        {this.state.restaurant && this.state.booking_id === "" ? (
                                        <div className="form-check form-check-inline ml-4">
                                          <input className="form-check-input" data-value="Exchange"  type="radio"  defaultChecked={this.state.buttonText === "Exchange" ? true : false } onChange={this.handleChangeChk}  name="inlineRadioOptions" id="inlineRadio3" defaultValue="Exchange" />
                                          <label className="form-check-label" htmlFor="inlineRadio3">
                                          { this.state.buttonText === "Exchange" ? 
                                          <img
                                          src={`${window.location.origin}/img/exchange_ck.png`}
                                          alt="circle-arrow"
                                         
                                        />
                                         : <img
                                         src={`${window.location.origin}/img/exchange.png`}
                                         alt="circle-arrow"
                                    
                                       />
                                        }
                                          </label>
                                          </div> )
                                          : ""
                                        }
                                        { this.state.booking_id === "" ?
                                          <div className="form-check form-check-inline ml-4">
                                          <input className="form-check-input" data-value="Group"  type="radio"  defaultChecked={this.state.buttonText === "Group" ? true : false } onChange={this.handleChangeChk} name="inlineRadioOptions" id="inlineRadio4" defaultValue="Group" />
                                          <label className="form-check-label" htmlFor="inlineRadio4">
                                          { this.state.buttonText === "Group" ? 
                                          <img
                                            src={`${window.location.origin}/img/group_ck.png`}
                                            alt="circle-arrow"
                                          
                                          />
                                        :
                                          <img
                                            src={`${window.location.origin}/img/group.png`}
                                            alt="circle-arrow"
                                          
                                          />
                                        }
                                          </label>
                                        </div>
                                         :""
                                      }
                                     


                                      {/* <button
                                        type="button"
                                        className="btn btn-sm btn-primary padding-button-res"
                                        onClick={(e) => this.onToggle(e)}
                                      >
                                        <i
                                          className="fa fa-trash mr-0"
                                          aria-hidden="true"
                                        ></i>
                                      </button> */}
                                      {/* {this.state.restaurant ? (
                                        <button
                                          type="button"
                                          onClick={(e) =>
                                            this.onExachangeTable(e)
                                          }
                                          className="btn btn-sm btn-primary ml-4 padding-button-res"
                                        >
                                          <i
                                            className="fas fa-exchange-alt mr-0"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      ) : (
                                        ""
                                      )} */}

                                      {/* <button
                                        className="btn btn-sm btn-primary btn-default ml-4 btn-squared"
                                        onClick={(e) => this.onGroupTable(e)}
                                      >
                                        {" "}
                                        <img
                                          src={`${window.location.origin}/img/table-white.png`}
                                          alt="circle-arrow"
                                          height={"20px"}
                                          width={"20px"}
                                        />
                                        
                                      </button> */}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    {this.state.openDeleteModal ? (
                                      <div>
                                        <Form.Control
                                          type="text"
                                          id="exampleFormControlTextarea1"
                                          placeholder={this.props.t(
                                            "reservation.DeleteForm.cancel_reason"
                                          )}
                                          defaultValue={values.cancel_reason}
                                          name="cancel_reason"
                                          isInvalid={!!errors.cancel_reason}
                                          onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {this.props.t(errors.cancel_reason)}
                                        </Form.Control.Feedback>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-md-2">
                                    <Form.Control
                                      type="text"
                                      name="initials"
                                      onChange={handleChange}
                                      placeholder={this.props.t(
                                        "reservation.DeleteForm.initial"
                                      )}
                                      className="form-control"
                                      defaultValue={values.initials}
                                      isInvalid={!!errors.initials}
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {this.props.t(errors.initials)}
                                    </Form.Control.Feedback>
                                  </div>
                                  <div className="col-md-1">
                                  
                                      <button
                                        type="submit"
                                        // onClick={this.UpdateReservationDateTime}
                                        // onClick={this.commonAction}
                                        className="btn btn-sm btn-primary btn-default btn-squared"
                                      >
                                        {this.props.t("button."+this.state.buttonText)}
                                      </button>
                                    
                                  </div>
                                </div>
                                {/* {this.state.openDeleteModal ?
                                <div className="row mt-10">
                                  <div className="col-md-2 offset-5">
                                    <button
                                        type="submit"
                                        className="btn btn-sm btn-primary btn-default btn-squared"
                                      >
                                        {this.props.t("button.delete")}
                                      </button>
                                  </div>
                                </div>
                                : ""
                              } */}
                              </Form>
                            )}
                          </Formik>
                            // <Formik
                            //   validationSchema={this.schema}
                            //   validateOnChange={false}
                            //   validateOnBlur={false}
                            //   enableReinitialize="true"
                            //   onSubmit={(values) => {
                            //     this.deleteSaveData(values);
                            //   }}
                            //   initialValues={{
                            //     reservation_ids: this.state.list_table_ids,
                            //     initials: this.state.initial,
                            //     cancel_reason: this.state.cancel_reason,
                            //   }}
                            // >
                            //   {({
                            //     handleSubmit,
                            //     handleChange,
                            //     handleBlur,
                            //     values,
                            //     touched,
                            //     isValid,
                            //     errors,
                            //   }) => (
                            //     <Form noValidate onSubmit={handleSubmit}>
                            //       <div className="row mt-10">
                            //         <div className="col-md-3">
                            //           <div className="d-flex ">
                            //             <button
                            //               type="button"
                            //               className="btn btn-sm btn-primary padding-button-res"
                            //               onClick={(e) => this.onToggle(e)}
                            //             >
                            //               <i
                            //                 className="fa fa-trash mr-0"
                            //                 aria-hidden="true"
                            //               ></i>
                            //             </button>
                            //             {this.state.restaurant ? (
                            //               <button
                            //                 type="button"
                            //                 onClick={(e) =>
                            //                   this.onExachangeTable(e)
                            //                 }
                            //                 className="btn btn-sm btn-primary ml-4 padding-button-res"
                            //               >
                            //                 <i
                            //                   className="fas fa-exchange-alt mr-0"
                            //                   aria-hidden="true"
                            //                 ></i>
                            //               </button>
                            //             ) : (
                            //               ""
                            //             )}

                            //             <button
                            //               className="btn btn-sm btn-primary btn-default ml-4 btn-squared"
                            //               onClick={(e) => this.onGroupTable(e)}
                            //             >
                            //               {" "}
                            //               <img
                            //                 src={`${window.location.origin}/img/table-white.png`}
                            //                 alt="circle-arrow"
                            //                 height={"20px"}
                            //                 width={"20px"}
                            //               />
                            //               N
                            //             </button>
                            //           </div>
                            //         </div>
                            //         <div className="col-md-5">
                            //           {this.state.openDeleteModal ? (
                            //             <div>
                            //               <Form.Control
                            //                 type="text"
                            //                 id="exampleFormControlTextarea1"
                            //                 placeholder={this.props.t(
                            //                   "reservation.DeleteForm.cancel_reason"
                            //                 )}
                            //                 defaultValue={values.cancel_reason}
                            //                 name="cancel_reason"
                            //                 isInvalid={!!errors.cancel_reason}
                            //                 onChange={handleChange}
                            //               />
                            //               <Form.Control.Feedback type="invalid">
                            //                 {this.props.t(errors.cancel_reason)}
                            //               </Form.Control.Feedback>
                            //             </div>
                            //           ) : (
                            //             ""
                            //           )}
                            //         </div>
                            //         <div className="col-md-2">
                            //           <Form.Control
                            //             type="text"
                            //             name="initials"
                            //             onChange={handleChange}
                            //             className="form-control"
                            //             defaultValue={values.initials}
                            //             isInvalid={!!errors.initials}
                            //           ></Form.Control>
                            //           <Form.Control.Feedback type="invalid">
                            //             {this.props.t(errors.initials)}
                            //           </Form.Control.Feedback>
                            //         </div>
                            //         <div className="col-md-1">
                            //           <div className="float-right">
                            //             <button
                            //               type="submit"
                            //               className="btn btn-sm btn-primary btn-default ml-4 btn-squared"
                            //             >
                            //               {this.props.t("button.save")}
                            //             </button>
                            //           </div>
                            //         </div>
                            //       </div>
                            //     </Form>
                            //   )}
                            // </Formik>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className={showArrow}
                          style={{
                            background: "rgb(244 245 247)",
                            cursor: "pointer",
                            zIndex: 999,
                            position: "absolute",
                            padding: "6px 15px 6px 0px",
                            borderRadius: "100%",
                            top: top,
                            right: "-52px",
                            fontSize: "28px",
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                          }}
                        >
                          <a
                            href="#!"
                            className={"text-dark ml-0 " + showArrow}
                            onClick={(e) => this.TableMap(e)}
                          >
                            <i className="fa fa-angle-left"></i>
                          </a>
                        </div>
                        <div className="area-drawer-wrapper">
                          <div className={"area-drawer " + tableMapShow}>
                            <div className="atbd-drawer map">
                              <div
                                className=""
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  cursor: "pointer",
                                  background: "rgb(255 255 255)",
                                  padding: "6px 0px 6px 18px",
                                  borderRadius: "100%",
                                  top: "47%",
                                  left: "-3%",
                                  right: "0",
                                  width: "50px",
                                  height: "50px",
                                  fontSize: "28px",
                                }}
                              >
                                <a
                                  href="#!"
                                  className="text-dark"
                                  onClick={(e) => this.TableMap(e)}
                                  id="showTableMap"
                                >
                                  <i className="fa fa-angle-right"></i>
                                </a>
                              </div>

                              {this.state.restaurant ? (
                                <div
                                  className="mt-2"
                                  key={2}
                                  ref={(ref) => (this.container = ref)}
                                  style={{
                                    position: "relative",
                                    // border: "solid 2px #000",
                                    display: "inline-block",
                                    border: "2px solid rgb(0, 0, 0)",
                                    overflowX: "auto",
                                  }}
                                >
                                  <img
                                    ref={(ref) => (this.img = ref)}
                                    className="img-fluid"
                                    alt="restaurant"
                                    width="800"
                                    src={this.state.restaurant.table_map}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = ImgSrc;
                                    }}
                                  />
                                  <fieldset className="checkbox-group">
                                    {Object.keys(tableList).length !== 0
                                      ? _generateTablesForReservation(
                                          this.state.isArrayOfTable,
                                          tableList,
                                          this.state.table_ids,
                                          "1",
                                          "",
                                          this.state.isCurrentReservationData,
                                          this.onTableSelectChange,
                                          this.OnNextClick,
                                          this.OnNextPrevClick
                                        )
                                      : ""}
                                  </fieldset>
                                </div>
                              ) : (
                                <Oval
                                  ariaLabel="loading-indicator"
                                  height={100}
                                  width={100}
                                  strokeWidth={5}
                                  strokeWidthSecondary={1}
                                  color="#B54191"
                                  secondaryColor="white"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-12"></div>
                  { this.state.booking_id !== ""  || this.state.group_id !== "" ?
                  <div
                                className=""
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  cursor: "pointer",
                                  background: "rgb(243 243 246)",
                                  padding: "0px 0px 0px 0px",
                                  borderRadius: "100%",
                                  bottom: "-3%",
                                  left: "23%",
                                  right: "0",
                                  width: "50px",
                                  height: "50px",
                                  textAlign: "center",
                                  fontSize: "28px",
                                }}
                              >
                                 { this.state.booking_id !== "" ?
                                <a
                                  href="#!"
                                  className="text-dark"
                                  onClick={(e) =>
                                    this.onRepeatingButton("")
                                  }
                                  id="showTableMapD"
                                >
                                  <i className="fa fa-angle-up"></i>
                                </a>
                                : this.state.group_id !== "" ?
                                <a
                                  href="#!"
                                  className="text-dark"
                                  onClick={(e) =>
                                    this.onGroupButton("")
                                  }
                                  id="showTableMapD"
                                >
                                  <i className="fa fa-angle-up"></i>
                                </a>
                                :""
                                }
                              </div>
                               : ''
                                }
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id={`myModal`}
          className="modal fade show"
          tabIndex={-1}
          role="dialog"
          aria-labelledby={`myModalLabel`}
          aria-modal="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ backgroundColor: "#B54191" }}
              >
                <h4 className="modal-title text-white" id={`myModalLabel`}>
                  Instructions
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Search field:</strong> possible search results appear
                  by entering at least 3 letters. After selecting an existing
                  guest, its data can be updated.
                  <br />
                  <strong>Search field is not used:</strong> in this case a new
                  guest is created
                </p>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          // show={this.state.openDeleteModal}
          onHide={() => this.setState({ openDeleteModal: false })}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Delete Reservation
            </Modal.Title>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={(e) => this.onToggle(e)}
            >
              ×
            </button>
          </Modal.Header>
          <Modal.Body>
            <Formik
              validationSchema={this.schema}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize="true"
              onSubmit={(values) => {
                this.deleteData(values);
              }}
              initialValues={{
                table_id: this.state.list_table_ids,
                initial: this.state.initial,
                cancel_reason: this.state.cancel_reason,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="form-group form-element-textarea mb-20">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="il-gray fs-14 fw-500 align-center"
                    >
                      {this.props.t("reservation.DeleteForm.cancel_reason")}
                    </label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      isInvalid={!!errors.cancel_reason}
                      placeholder={this.props.t(
                        "reservation.DeleteForm.cancel_reason"
                      )}
                      defaultValue={values.cancel_reason}
                      name="cancel_reason"
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.cancel_reason)}
                    </Form.Control.Feedback>
                  </div>

                  <div className="form-group form-element-textarea mb-20">
                    <label
                      htmlFor="initial"
                      className="il-gray fs-14 fw-500 align-center"
                    >
                      {this.props.t("reservation.DeleteForm.initial")}
                    </label>
                    <Form.Control
                      type="text"
                      name="initial"
                      placeholder={this.props.t(
                        "reservation.DeleteForm.initial"
                      )}
                      value={values.initial}
                      onChange={handleChange}
                      isInvalid={!!errors.initial}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.initial)}
                    </Form.Control.Feedback>
                  </div>
                  <Button
                    className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                    type="submit"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    {this.props.t("button.delete")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={this.state.openModalNote}
          onHide={(e) => this.openModalNote(e)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="note-model">
            <Modal.Title id="example-modal-sizes-title-lg text-white">
            { this.props.t(
                        "reservation.addNoteHeader"
                      ) }
            </Modal.Title>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={(e) => this.openModalNote(e)}
            >
              ×
            </button>
          </Modal.Header>
          <Modal.Body>

            <button
                  type="button"
                  className="btn btn-sm btn-primary padding-button-res float-right my-2"
                >
                  <i
                    className="fas fa-clipboard mr-0"
                    aria-hidden="true"
                  ></i>
                </button>
            <Formik
              validationSchema={this.schemaNotes}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize="true"
              onSubmit={(values) => {
                // console.log(values);
                this.addNoteData(values);
              }}
              initialValues={{
                restaurant_id: this.state.selectedRestaurantId,
                note_date: this.state.startDate,
                note_text: this.state.note_text,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  
                  <div className="form-group form-element-textarea mb-20">
                   
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      isInvalid={!!errors.note_text}
                      placeholder={this.props.t(
                        "reservation.addNoteHeader"
                      )}
                     
                      defaultValue={values.note_text}
                      name="note_text"
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.note_text)}
                    </Form.Control.Feedback>
                  </div>

                  
                  <Button
                    className="btn btn-primary btn-default btn-squared mr-15 float-right text-capitalize lh-normal px-40 py-15"
                    type="submit"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    {this.props.t("button.save")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
           show={this.state.openModalPdf}
          onHide={(e) => this.pdfUploadForm(e)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header className="note-model">
            <Modal.Title id="example-modal-sizes-title-lg text-white">
            { this.props.t(
                        "reservation.addPdfHeader"
                      ) }
            </Modal.Title>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={(e) => this.pdfUploadForm(e)}
            >
              ×
            </button>
          </Modal.Header>
          <Modal.Body>
              <p>
                {this.props.t("reservation.modalpdftext")}
              </p>
            {/* <button
                  type="button"
                  className="btn btn-sm btn-primary padding-button-res float-right my-2"
                >
                  <i
                    className="fas fa-clipboard mr-0"
                    aria-hidden="true"
                  ></i>
                </button> */}
            <Formik
              validationSchema={this.schemaPdf}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize="true"
              onSubmit={(values) => {
                // console.log(values);
                this.addpdfData(values);
              }}
              initialValues={{
                restaurant_id: this.state.selectedRestaurantId,
                note_date: this.state.selectedStartDate,
                attachment_file: this.state.attachment_file,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  
                  <Row className="my-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            { this.props.t("restaurantTable.addForm.fileupload")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="file"
                            name="attachment_file"
                            placeholder={this.props.t(
                              "restaurantTable.addForm.fileupload"
                            )}
                            onChange={(e) => this.handfileUpload(values, e)}
                            isInvalid={!!errors.attachment_file}
                            accept="application/pdf" 
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                            {this.props.t(errors.attachment_file)}
                          </Form.Control.Feedback>
                        </Row>
                        <div className="row">
                          <div className="col-md-4 offset-4">
                            <Button
                              className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              {this.props.t("button.save")}
                            </Button>
                          </div>
                          <div className="col-md-4">
                            <Button
                              onClick={() =>
                                this.setState({ openModalPdf: false })
                              }
                              className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                            >
                              <span>{this.props.t("button.cancel")}</span>
                            </Button>
                          </div>
                        </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state, "aaa");
  return {
    restaurants: state.restaurants,
    reservations: state.reservations,
    configurations: state.configurations,
    restaurantHours: state.restaurantHours,
    restaurantTables: state.restaurantTables,
    //restaurantTables: state.restaurantTables,
    reservationsSettings: state.reservationsSettings,
    reservationsDateSettings: state.reservationsDateSettings,
    retrieveTableReservations: state.retrieveTableReservations,
    currentReservation: state.currentReservation,
    message: state.message,
    notes:state.notes,
    guests: state.guests,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveReservations,
    addWalkInReservation,
    retrieveRestaurants,
    getRestaurant,
    retrieveConfigurations,
    retrieveRestaurantsHours,
    getReservationCalenderdates,
    retrieveRestaurantsTables,
    retrieveReservationRestaurantHours,
    deleteReservation,
    deleteReservationMultiple,
    retrieveTableReservations,
    getReservation,
    makeGroupReservation,
    createNote,
    UpdateNote,
    retriveNotes,
    uploadPdfNotes,
    clearMessage,
    retrieveGuestsQr,
    changeStatusReservation,
    getNextPrevreservation,
  })
)(Reservation);
