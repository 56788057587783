import axios from "axios";
//import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
//const apiEndpoint = configData.apiUrl + "/tutorial/";
import authHeader from "./auth-header";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/";

class QRDataService {
  //Guest
  /* getAll() {
    //return axios.get(apiEndpoint + "all");
    return axios.get("http://localhost:8080/api/tutorials");
  } */
  


  create = (data) => {
    return axios.post(apiEndpoint + "send-code", data);
  };

  verifyCode = (data) => {
    return axios.post(apiEndpoint + "verify-code", data);
  };

  createReservationByQr = (data) => {
    return axios.post(apiEndpoint + "create-reservation-qr", data);
  };

  getRestaurantByTableId = (data) => {
    return axios.post(apiEndpoint + "get-restaurant-by-table-id", data);
  }

 
}

export default new QRDataService();
