import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/";

class UserService {
  getPublicContent() {
    return axios.get(apiEndpoint + "all");
  }

  getUserBoard() {
    return axios.get(apiEndpoint + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(apiEndpoint + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(apiEndpoint + "admin", { headers: authHeader() });
  }
  updatePassword(data) {
    return axios.post(apiEndpoint + "update-password", data, {
      headers: authHeader(),
    });
  }
}

export default new UserService();
