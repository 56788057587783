import { UPDATE_PASSWORD, UPDATE_PASSWORD_FAIL } from "../actions/types";

const initialState = [];

function authUserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PASSWORD:
      return [...state, payload];
    case UPDATE_PASSWORD_FAIL:
      return [...state, payload];
    default:
      return state;
  }
}

export default authUserReducer;
