import {
    GET_RESERVATION_RESTAURANT_HOURS,
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function reservationSettingReducer(reservationsSettings = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
      case GET_RESERVATION_RESTAURANT_HOURS:
        return payload;
  
      default:
        return reservationsSettings;
    }
  }
  
  export default reservationSettingReducer;
  
  