import React from "react";

const Select = ({
  name,
  label,
  options,
  isOptionValue = true,
  error,
  ...rest
}) => {
  return rest.eType === "V" ? (
    <div
      className={
        "form-group " + (rest.required === true ? " ant-form-item-label" : "")
      }
    >
      <label
        htmlFor={name}
        className={
          "color-dark fs-14 fw-600 align-center " +
          (rest.required === true ? " ant-form-item-required" : "")
        }
      >
        {label}
        {/* <span className="text-red">*</span> */}
      </label>
      <select name={name} id={name} {...rest} className="form-control">
        {isOptionValue ? <option value="" /> : ""}
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  ) : (
    <div>
      <div className="form-group row">
        <div
          className={
            "col-sm-4 d-flex aling-items-center" +
            (rest.required === true ? " ant-form-item-label" : "")
          }
        >
          <label
            htmlFor={name}
            className={
              "col-form-label color-dark fs-14 fw-600 align-center " +
              (rest.required === true ? " ant-form-item-required" : "")
            }
          >
            {label}
          </label>
        </div>
        <div className="col-sm-8">
          <select name={name} id={name} {...rest} className="form-control">
            
            {options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-8 pt-2 offset-md-4">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Select;
