import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
// import { _pre } from "../../utils/vlogs";
import {
  retrieveConfigurations,
} from "../../actions/configuration";
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from "react-i18next";

class ConfigurationList extends Component {
  constructor(props) {
    super(props);

 
    this.state = {
      lang: localStorage.getItem("lan"),
      loading: false,
    };
  }
  componentDidMount() {
    
    this.fetchConfiguration(this.state.lang);
   
  }

  componentDidUpdate(prevProps, prevState) {
   
    if(prevProps.i18n.language !== this.state.lang){
     
      this.setState((state) => {
        // Important: read `state` instead of `this.state` when updating.
        return {lang: prevProps.i18n.language}
      });
      this.fetchConfiguration(prevProps.i18n.language);
    }else{
    
      return true;
    }

  }

  fetchConfiguration = async (lang) => {
    this.setState({ loading: true });
   
    await this.props.retrieveConfigurations(lang);
    this.setState({ 
      //lang: lang,
      loading: false });
  };



  render() {

    const { configurations } = this.props;

   
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.t('pagetitle.configuration')}
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25" />
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body bg-opacity-gray">
                  <div className="row">
                    <div className="col-xxl-12 col-md-12">
                      <div className="ap-po-details ap-po-details--2 p-10 pl-25 radius-xl bg-white d-flex justify-content-between mb-25">
                        <div>
                          <div className="overview-content overview-content3">
                            <div className="d-flex">
                              {/* <div className="revenue-chart-box__Icon mr-20 order-bg-opacity-primary1 text-info">
                               
                                <span
                                  data-feather="info"
                                  className="nav-icon svg"
                                />
                              </div> */}
                              <div>
                                <h2> {this.props.t('configuration.title')}</h2>
                                <p className="mb-3 mt-1">
                                {this.props.t('configuration.subtitle')}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-12">
                      <div className="row row-eq-height">
                      {configurations.list ?
                             configurations.list.map((r, index) => {
                            return(
                            <div className="col-xxl-3 col-lg-4 col-md-6 mb-25"  key={r.id}>
                              <figure className="feature-cards3">
                                <img
                                  className="svg"
                                  src={`img/svg/${r.slug}.svg`}
                                  width={"60px"}
                                  alt={r.name}
                                />
                                <figcaption>
                                  <h2>
                                  {r.name}&nbsp;
                                  { r.note !== '' ?
                                    <i
                                      id="info_QR-Code_Icon"
                                      data-toggle="modal"
                                      data-target={`#myModal_${r.id}`}
                                      className="fa fa-info-circle"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                     : ''
                                    }
                                  </h2>
                                  <p> { ReactHtmlParser(r.description)}</p>
                                </figcaption>
                              </figure>
                            </div>
                            )
                          })
                          : ''
                        }
                        </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            {configurations.list ?
              configurations.list.map((r, index) => {
            
              return(
                <div key={r.id}>
                { r.note !== '' ?
                  <div
                    id={`myModal_${r.id}`}
                    className="modal fade show"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby={`myModalLabel_${r.id}`}
                    aria-modal="true"
                    key={r.id}
                  >
                    <div className="modal-dialog" >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title" id={`myModalLabel_${r.id}`}>
                          {r.name}
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            ×
                          </button>
                        </div>
                        <div className="modal-body">
                            { ReactHtmlParser(r.note)}
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''
                  }
                </div>
              )
              })
              : ''
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    configurations: state.configurations,
    message: state.message,
  };
};


export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveConfigurations,
    //deleteAllTutorials,
  })
)(ConfigurationList);
// export default withTranslation()(ConfigurationList);
