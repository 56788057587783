import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import ReactHtmlParser from 'react-html-parser';
import {
  retrieveRestaurantsTables,
  deleteRestaurantTables,
  createRestaurantTable,
  updateRestaurantTable,
  getRestaurantTable,
  InsertRestaurantTableMap,
  getRestaurantTablePdfLink,
} from "../../actions/restaurant-tables";
import Message from "../common/message";
import { Row,  Form, Button,  Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import {
  retrieveRestaurants,
  getRestaurant,
} from "../../actions/restaurant";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component"; 
import {  _generateTables, _paginationComponentOptions, _arrayOfAlphabet } from "../../utils/vlogs";
import { confirmAlert } from "react-confirm-alert";
import { withTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const ImgSrc = "../../default_table_map.jpg";


class RestaurantTables extends Component {

  constructor(props) {
    super(props);
    this.state = {
      restaurant: {},
      restaurantTables: {},
      currentIndex: -1,
      loading: false,
      page: 1, //qsParams.page ? qsParams.page : 1,
      perPage: 29,
      showModal: false,
      showModalImage: false,
      showText: "",
      closeModal: false,
      mode: "",
      data: {
        id: "",
        restaurant_id: "",
        table_number: "",
        min_space: "",
        max_space: "",
        standard_space: "",
        table_shape: "",
        table_size: "",
        x_pos: "",
        y_pos: "",
        attribute_group: "",
        status: "",
        table_map: null,
        
      },
    };
    // this.onChangeSearchKeyword = this.onChangeSearchKeyword.bind(this);
    this.openModal = this.openModal.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.onDeleteRecord = this.onDeleteRecord.bind(this);
    this.statusToValueChange = this.statusToValueChange.bind(this);
    this.showPdfQrcode = this.showPdfQrcode.bind(this);
    
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchRestaurantTable(
       '','', this.props.match.params.id
      );
      this.getRecord(this.props.match.params.id);
      this.getPins();
     // await this.fetchRestaurant(this.state.page, this.state.perPage);
    }
    //
  }
  
  schema =  yup.object().shape({
  table_number: yup.number().typeError('restaurantTable.addForm.validation.tableNum').required('restaurantTable.addForm.validation.tableNumber'),
  min_space: yup.number().typeError('restaurantTable.addForm.validation.minNum').required('restaurantTable.addForm.validation.min'),
  max_space: yup.number().when('min_space', (min_space) => {
    if (min_space) {
        return yup.number()
            .min(min_space, 'restaurantTable.addForm.validation.max_val_greterThenMin')
    } }).typeError('restaurantTable.addForm.validation.maxNum').required('restaurantTable.addForm.validation.max'),
  
  standard_space: yup.number().when(['min_space','max_space'], (min_space, max_space) => {
    if (min_space && max_space) {
        return yup.number()
            .min(min_space, 'restaurantTable.addForm.validation.default_val_greterThenMin').max(max_space, 'restaurantTable.addForm.validation.default_val_lessThenMax').required('restaurantTable.addForm.validation.default')
    } }).typeError('restaurantTable.addForm.validation.defaultNum').required('restaurantTable.addForm.validation.default'),
  table_shape: yup.number().required('restaurantTable.addForm.validation.tableshape'),
  table_size: yup.number().required('restaurantTable.addForm.validation.tablesize'),
  x_pos: yup.number().typeError('restaurantTable.addForm.validation.xposNum').required('restaurantTable.addForm.validation.xpos'),
  y_pos: yup.number().typeError('restaurantTable.addForm.validation.yposNum').required('restaurantTable.addForm.validation.ypos'),
  attribute_group: yup.number().required('restaurantTable.addForm.validation.attribute'),
  status: yup.number().required('restaurantTable.addForm.validation.status'),
  });

  schema1 =  yup.object().shape({
    table_map: yup.string().required('restaurantTable.addForm.validation.table_map'),
  });


  openModal(event) {

    const x = event.nativeEvent.offsetX;
    const y = event.nativeEvent.offsetY;
 
  
    this.setState({

      showModal: true,
      showText: this.props.t('restaurantTable.addForm.addtable'),
      inherit_id_show: false,
      data: {
        type: "",
        restaurant_id: this.props.match.params.id,
        table_number: "",
        min_space: "",
        max_space: "",
        standard_space: "",
        table_shape: 0,
        table_size: 0,
        x_pos: x,
        y_pos: y,
        attribute_group: 0,
        status: 1,
       
      },
    });
  }

  openImageModal(){
    this.setState({showModalImage: true});
  }

  editModal(id, type) {
 
    this.getRestaurantTableRecord(id);
  }

  showPdfQrcode(id){

    this.props.getRestaurantTablePdfLink(id)
    .then((data) => {
      if (data.status) {
        if(data.data.pdfURL !== '')
        {
         window.open(data.data.pdfURL, '_blank');
        }else{
          toast.error("pdf url not found");
        }
      
      } else {
        toast.error(data.message);
      }
      // this.setState({
      //   loading: false,
      // });
    })
    .catch((e) => {
      // console.log(e);
      this.setState({
        loading: false,
      });
    });
      
  }


  handfileUpload( values, e){
      values.table_map = e.target.files[0];
  }

  statusToValueChange(values){
    if(values.status === 3){
      values.inherit_table_id_show = true
    }else{
      values.inherit_table_id_show = false
    }
  }

  getRestaurantTableRecord(id) {
    // this.setState({
    //   loading: true,
    // });
    //alert("getRestaurantHourRecord");
    this.props
      .getRestaurantTable(id)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;

          if(currentRecord.status === "3"){
            this.setState({
              inherit_id_show: true,
            });
          }
         
          this.setState({
            showModal: true,
            showText: this.props.t('restaurantTable.addForm.changetable'),
            data: {
              id: currentRecord.id,
              restaurant_id: this.props.match.params.id,
              table_number: currentRecord.table_number,
              min_space: currentRecord.min_space,
              max_space: currentRecord.max_space,
              standard_space: currentRecord.standard_space,
              table_shape: currentRecord.table_shape,
              table_size: currentRecord.table_size,
              x_pos: currentRecord.x_pos,
              y_pos: currentRecord.y_pos,
              attribute_group: currentRecord.attribute_group,
              status: currentRecord.status,
              inherit_table_id: currentRecord.inherit_table_id,
              
            },
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/restaurants");
        }
        // this.setState({
        //   loading: false,
        // });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  doImageSubmit(values) {
    // console.log(values);

    this.setState({
      loading: true,
    });

    let formData = new FormData();
    formData.append("table_map", values.table_map); 
    formData.append("restaurant_id", values.restaurant_id);   //#TODO Insert Record
      this.props
        .InsertRestaurantTableMap(formData)
        .then((data) => {
          // console.log(data.status, data.message);

          if (data.status) {
            toast.success(data.message);
            this.setState({
              showModalImage: false,
              loading: false,
            });
           
            this.getRecord(this.props.match.params.id);
            this.fetchRestaurantTable(
              
              this.props.match.params.id
            );
            // this.props.history.push("/restaurants/hours/"+this.props.match.params.id);
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            loading: false,
          });
        });
    
  }

  async doSubmit(values) {
    // console.log(values);

    this.setState({
      loading: true,
    });

    if (values.id !== "" && values.id !== undefined) {
      //Update
      this.props
        .updateRestaurantTable(values.id, values)
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            this.setState({
              showModal: false,
            });
            this.fetchRestaurantTable(
             '',
              '',
              this.props.match.params.id
            );
            this.getPins();
            // this.props.history.push("/restaurants/hours/"+this.props.match.params.id);
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      /* this.fetchRestaurantTable(
        this.state.page,
        this.state.perPage,
        this.props.match.params.id
      ); */

     // return true;
     await this.props
        .createRestaurantTable(values)
        .then((data) => {
          // console.log(data.status, data.message,'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');

          if (data.status) {
            toast.success(data.message);
            
             this.fetchRestaurantTable(
              '',
              '',
              this.props.match.params.id
            );
            // this.fetchRestaurant(this.state.page, this.state.perPage);
             this.setState({
              showModal: false,
            });
            // this.props.history.push("/restaurants/hours/"+this.props.match.params.id);
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            loading: false,
          });
        });
    }
  }

  getRecord(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getRestaurant(id)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          this.setState({
            restaurant: currentRecord,
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  onChangeSearchKeyword(e) {
    const searchKeyword = e.target.value;

    this.setState({
      searchKeyword: searchKeyword,
    });
  }

  refreshData() {
    this.setState({
      currentRes: null,
      currentIndex: -1,
    });
    //this.setState({ loading: false });
    //this.setState({ perPage: 10, page: 1 });
  }

  setActiveTutorial(user, index) {
    this.setState({
      currentRes: user,
      currentIndex: index,
    });
  }

  handlePageChange = async (page) => {
    this.setState({ page: page });

    await this.fetchRestaurantTable(page, this.state.perPage, this.props.match.params.id);
  };

  handlePerRowsChange = async (perPage) => {
    //alert(" HAbd PER PAGE " + perPage);
    this.setState({ perPage: perPage });
    // _pre(" newPerPage ", perPage);

    await this.fetchRestaurantTable(this.state.page, perPage, this.props.match.params.id);
  };

  fetchRestaurantTable = async (page, perPage, id) => {
    this.setState({ loading: true });
    //_pre(" FETCH GUEST ", this.state);
    //_pre(" FETCH GUEST2 ", this.props);
    //alert(this.state.page + " => " + this.state.perPage);
    //alert(page + " => " + perPage);
    await this.props.retrieveRestaurantsTables(page, perPage, id);
    this.setState({ loading: false });
  };

  fetchRestaurant = async (page, perPage) => {
    // this.setState({ loading: true });
    //_pre(" FETCH GUEST ", this.state);
    //_pre(" FETCH GUEST2 ", this.props);
    //alert(this.state.page + " => " + this.state.perPage);
    //alert(page + " => " + perPage);
    await this.props.retrieveRestaurants(page, perPage);
    // this.setState({ loading: false });
  };

  onUpdateBtnClick() {
    alert("click");
  }

  onDeleteRecord = async (id) => {
    confirmAlert({
      title: this.props.t("messages.delalertmsgrestable"),
      message: this.props.t("messages.doyouwantcon"),
      buttons: [
        {
          label: this.props.t("messages.yes"),
          onClick: () => this.deleteConfirm(id),
        },
        {
          label: this.props.t("messages.no"),
          onClick: () => "",
        },
      ],
    });
  };


  getPins() {
   
    const restaurantTables = this.props.restaurantTables.list;
    // console.log(restaurantTables);
    if(restaurantTables){

      const pins = this.props.restaurantTables.list.map(pin => {

        let clsTableConditionActive = pin.status === 1 ?
            'table_configuration'
            : pin.status === 2 ?
            'table_configuration_inactive'
            : 'table_configuration_inherit'

        let ClsConditionWise;
        ClsConditionWise = pin.table_shape === 0 &&  pin.table_size === 0 ?
              'small_horizontal'
              : pin.table_shape === 1 &&  pin.table_size === 0 ? 
              'small_vertical'
              : pin.table_shape === 2 &&  pin.table_size === 0 ? 
              'circle_small'
              :pin.table_shape === 0 &&  pin.table_size === 1 ? 
              'medium_hori'
              :pin.table_shape === 1 &&  pin.table_size === 1 ? 
              'medium'
              :pin.table_shape === 2 &&  pin.table_size === 1 ? 
              'circle_medium'
              :pin.table_shape === 0 &&  pin.table_size === 2 ? 
              'big_hori'
              :pin.table_shape === 1 &&  pin.table_size === 2 ? 
              'big'
              :pin.table_shape === 2 &&  pin.table_size === 2 ? 
              'circle_big'
              :''

          let ClsSpanConditionWise;
          ClsSpanConditionWise = pin.table_size === 0 && pin.table_shape !== 2 ?
                ' span_small '
                : pin.table_size === 1 && pin.table_shape !== 2 ? 
                'medium_span '
                :pin.table_size === 2 && pin.table_shape !== 2 ? 
                'big_span'
                :pin.table_shape === 2 &&  pin.table_size === 0 ? 
                'circle_small_span'
                :pin.table_shape === 2 &&  pin.table_size === 1 ? 
                'circle_medium_span'
                :pin.table_shape === 2 &&  pin.table_size === 2 ? 
                'circle_big_span'
                :''
        return (
        
          <div className={`${clsTableConditionActive} tisch ${ClsConditionWise}`} 
          style={{
            position: "absolute",
            left: `${pin.x_pos}px`,
            top: `${pin.y_pos}px`
          }}>
            {pin.table_number} <br/> 
            <span class={`${ClsSpanConditionWise}`}>{pin.min_space}-{pin.max_space} </span>
          </div>
        
        );
      });
     
      return pins;
    }
  
  }

  deleteConfirm(id) {
    // return false;
    this.setState({
      loading: true,
    });
    this.props
      .deleteRestaurantTables(id)
      .then((data) => {
        this.setState({
          loading: false,
        });
        toast.success(this.props.t("messages.delsucmessage"));
        this.fetchRestaurantTable(
          this.state.page,
          this.state.perPage,
          this.props.match.params.id
        );
        this.fetchRestaurant(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }


  render() {
    

    const ArrayOfAlphabet = _arrayOfAlphabet();
    const paginationComponentOptions = _paginationComponentOptions(this.props.t("datatable.rowperpage"), this.props.t("datatable.of"));

    const CustomLoader = () => (
      <div>
        <h2 className="text-center">{this.props.t("datatable.loading")}</h2>
      </div>
    );
    const {  loading } = this.state;
    const { restaurantTables, message } = this.props;
    
    // console.log(this.props, 'ssssss');
    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
          //background: "#cb4b16",
        },
      },
    };
     
    return (
    <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.t("pagetitle.restaurants_table")}
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                {/* <div className="card-header color-dark fw-500">Rate List</div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="action-btn  form-inline-action d-flex justify-content-between ">
                        
                        <div className="d-inline-flex">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={() =>
                              this.openImageModal()
                            }
                          >
                            <i className="fa fa-plus"></i>{" "}
                            {this.props.t("pagetitle.uploadtableplan")}{" "}
                            
                          </button>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Link
                        to="/restaurants"
                        className="btn btn-sm btn-primary btn-add h-35 for-button-padding float-right"
                      >
                        {this.props.t("button.back")}
                      </Link>
                    </div>
                  </div>
                  { this.state.restaurant.table_map ? 
                      <>
                      <div className="mt-2" id="resturantTable"
                          key={2}
                          ref={ref => (this.container = ref)}
                          style={{
                            display: "inline-block",
                            position: "relative",
                            border: "solid 2px #000",
                            overflowX: "auto"
                          }}
                        >
                      <img ref={ref => (this.img = ref)} width="800"  alt={this.state.restaurant} onClick={this.openModal} src={this.state.restaurant.table_map}  onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src=ImgSrc;
                          }} />
        
                      { this.props.restaurantTables !== 0 ?
                       _generateTables(this.props.restaurantTables, '1', '')
                        : ''
                       }
                      </div>
                      </>
                      : <Oval
                      ariaLabel="loading-indicator"
                      height={100}
                      width={100}
                      strokeWidth={5}
                      strokeWidthSecondary={1}
                      color="#B54191"
                      secondaryColor="white"
                    />
                  }
                    
                  {/* <img ref={ref => (this.img = ref)} width="800" src={this.state.restaurant.table_map} /> */}
                  {/* <CanvasDraw
                    style={{
                      position: "absolute",
                      top: "0px",
                      background: "none"
                    }}
                    brushColor="#FF5722"
                    canvasWidth="1000"
                    canvasHeight="1000"
                    loadTimeOffset="2"
                    brushSize="2"
                    disabled="false"
                 
                  
                  /> */}
                  <div className="row">
                    {/* <div className="col-12 ">
                      <div className="">
                        <Message message={message} />
                      </div>
                    </div> */}

                    <div className="col-md-12 p-10">
                      { restaurantTables.total > 0 ? 
                      <DataTable
                        // title="Users"
                        customStyles={customStyles}
                        columns={[
                          {
                            name: this.props.t("restaurantTable.table.tablenumber"),
                            selector: (row) =>
                              row.table_number,
                            sortable: false,
                            width: "15%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.min_max"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                                {row.min_space} / {row.max_space}
                              </div>
                            ),
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.default"
                            ),
                            selector: (row) =>
                            row.standard_space,
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.tableshape"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                                { row.table_shape === 0 ?
                                  this.props.t(
                                    "restaurantTable.table_shape.rect_hori"
                                  )
                                  : row.table_shape === 1 ?
                                  this.props.t(
                                    "restaurantTable.table_shape.rect_vert"
                                  )
                                  : row.table_shape === 2 ?
                                  this.props.t(
                                    "restaurantTable.table_shape.round_table"
                                  )
                                  : ''
                          }
                              </div>
                            ),
                            sortable: false,
                            width: "20%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.tablesize"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                               { row.table_size === 0 ?
                                  this.props.t(
                                    "restaurantTable.table_size.small"
                                  )
                                  : row.table_size === 1 ?
                                  this.props.t(
                                    "restaurantTable.table_size.middle"
                                  )
                                  : row.table_size === 2 ?
                                  this.props.t(
                                    "restaurantTable.table_size.big"
                                  )
                                  : ''
                          }
                              </div>
                            ),
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.attribute"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                               { row.attribute_group === 0 ?
                                    '-'
                                  : <>{String.fromCharCode(64 + row.attribute_group)}</>
                                  
                                  
                          }
                              </div>
                            ),
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t(
                              "restaurantTable.table.status"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                               { row.status === 1 ?
                                  this.props.t(
                                    "restaurantTable.status.active"
                                  )
                                  : row.status === 2 ?
                                  this.props.t(
                                    "restaurantTable.status.inactive"
                                  )
                                  : row.status === 3 ?
                                  this.props.t(
                                    "restaurantTable.status.sameas"
                                  )
                                  : ''
                          }
                            </div>
                            ),
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t("restaurantTable.table.action"),
                  
                            cell: (row) => (
                              <ul
                                key={row.id}
                                className="orderDatatable_actions table_actions mb-0 d-flex flex-wrap"
                              >
                                <li>
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      this.showPdfQrcode(row.id)
                                    }
                                    className="edit color-primary1 color-extra-light"
                                  >
                                   <i className="fa fa-qrcode"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      this.editModal(row.id, row.type)
                                    }
                                    className="edit color-primary1 color-extra-light"
                                  >
                                    <i className="fa fa-pencil-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#!"
                                    className="remove color-danger1 color-extra-light"
                                    //onClick={this.onDeleteRecord(row)}
                                    //onClick={() => onDeleteRecord(row.id)}
                                    onClick={() => this.onDeleteRecord(row.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                            width: "15%",
                            key: "action",
                          },
                        ]}
                        
                        data={restaurantTables.list}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        // selectableRows
                        paginationPerPage={restaurantTables.total}
                       
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        highlightOnHover
                        dense
                      />
                      : ''
  }
                    </div>
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
              <Modal
                size="lg"
                show={this.state.showModalImage}
                onHide={() => this.setState({ showModalImage: false })}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {this.props.t("restaurantTable.addForm.fileupload")}
                  </Modal.Title>
                  <button
                    type="button"
                    onClick={() => this.setState({ showModalImage: false })}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                { Object.keys(message).length !== 0 && message.message ?
                   <div className="col-12 ">
                      <div className="">
                        <Message message={message} />
                      </div>
                    </div>
                    : ''
                }
                  { ReactHtmlParser(this.props.t("restaurantTable.addForm.fileuploadmsg"))}
                  <Formik
                    validationSchema={this.schema1}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize="true"
                    onSubmit={(values) => {
                      this.doImageSubmit(values);
                    }}
                    initialValues={{
                      restaurant_id: this.props.match.params.id,
                      table_map: this.state.data.table_map,

                      // closed_for: this.state.data.closed_for,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        
                        <Row className="my-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            { this.props.t("restaurantTable.addForm.fileupload")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="file"
                            name="table_map"
                            placeholder={this.props.t(
                              "restaurantTable.addForm.fileupload"
                            )}
                            onChange={(e) => this.handfileUpload(values, e)}
                            isInvalid={!!errors.table_map}
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                            {this.props.t(errors.table_map)}
                          </Form.Control.Feedback>
                        </Row>
                        <div className="row">
                          <div className="col-md-4 offset-4">
                            <Button
                              className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              {this.props.t("button.save")}
                            </Button>
                          </div>
                          <div className="col-md-4">
                            <Button
                              onClick={() =>
                                this.setState({ showModalImage: false })
                              }
                              className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                            >
                              <span>{this.props.t("button.cancel")}</span>
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
                </Modal>
              <Modal
                size="lg"
                show={this.state.showModal}
                onHide={() => this.setState({ showModalImage: false })}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {this.state.showText}
                  </Modal.Title>
                  <button
                    type="button"
                    onClick={() => this.setState({ showModal: false })}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                { Object.keys(message).length !== 0  && message.message ?
                  <div className="form-group mb-20">
                    <Message message={message} />
                  </div>
                  : ''
                }
                  <Formik
                    validationSchema={this.schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize="true"
                    onSubmit={(values) => {
                      this.doSubmit(values);
                    }}
                    initialValues={{
                      id: this.state.data.id,
                      restaurant_id: this.props.match.params.id,
                      table_number: this.state.data.table_number,
                      min_space: this.state.data.min_space,
                      max_space: this.state.data.max_space,
                      standard_space: this.state.data.standard_space,
                      table_shape: this.state.data.table_shape,
                      table_size: this.state.data.table_size,
                      x_pos: this.state.data.x_pos,
                      y_pos: this.state.data.y_pos,
                      attribute_group: this.state.data.attribute_group,
                      status: this.state.data.status,
                      inherit_table_id_show:this.state.inherit_id_show,
                      inherit_table_id: this.state.data.inherit_table_id,

                      // closed_for: this.state.data.closed_for,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t("restaurantTable.addForm.labletablenumber")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="text"
                            name="table_number"
                            placeholder={this.props.t(
                              "restaurantTable.addForm.labletablenumber"
                            )}
                            value={values.table_number}
                            onChange={handleChange}
                            isInvalid={!!errors.table_number}
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                            {this.props.t(errors.table_number)}
                          </Form.Control.Feedback>
                        </Row>
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t("restaurantTable.addForm.lablemin")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="text"
                            name="min_space"
                            placeholder={this.props.t(
                              "restaurantTable.addForm.lablemin"
                            )}
                            value={values.min_space}
                            onChange={handleChange}
                            isInvalid={!!errors.min_space}
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                               {this.props.t(errors.min_space)}
                          </Form.Control.Feedback>
                        </Row>
                       
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t("restaurantTable.addForm.lablemax")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="text"
                            name="max_space"
                            placeholder={this.props.t(
                              "restaurantTable.addForm.lablemax"
                            )}
                            min={values.min_space}
                            value={values.max_space}
                            onChange={handleChange}
                            isInvalid={!!errors.max_space}
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                             {this.props.t(errors.max_space)}
                          </Form.Control.Feedback>
                        </Row>
                
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.labledefault"
                            )}
                          </Form.Label>
                          <div className="col-md-8 px-0">
                            <Form.Control
                              className=""
                              type="text"
                              name="standard_space"
                              placeholder={this.props.t(
                                "restaurantTable.addForm.labledefault"
                              )}
                              value={values.standard_space}
            
                              onChange={handleChange}
                              isInvalid={!!errors.standard_space}
                            />
                            <Form.Control.Feedback
                              className="ml-3"
                              type="invalid"
                            >
                                {this.props.t(errors.standard_space)} 
                            </Form.Control.Feedback>
                          </div>
                          
                        </Row>
                       
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.labletableshape"
                            )}
                          </Form.Label>
                          <Form.Select
                            name="table_shape"
                            className="form-control col-md-8"
                            value={values.table_shape}
                            onChange={handleChange}
                            isInvalid={!!errors.table_shape}
                          >
                            <option value="0">
                              {this.props.t("restaurantTable.table_shape.rect_hori")}
                            </option>
                            <option value="1">
                              {this.props.t(
                                "restaurantTable.table_shape.rect_vert"
                              )}
                            </option>
                            <option value="2">
                              {this.props.t(
                                "restaurantTable.table_shape.round_table"
                              )}
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                              {this.props.t(errors.table_shape)}
                          </Form.Control.Feedback>
                        </Row>

                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.labletabelsize"
                            )}
                          </Form.Label>
                          <Form.Select
                            name="table_size"
                            className="form-control col-md-8"
                            value={values.table_size}
                            onChange={handleChange}
                            isInvalid={!!errors.table_size}
                          >
                            <option value="0">
                              {this.props.t("restaurantTable.table_size.small")}
                            </option>
                            <option value="1">
                              {this.props.t(
                                "restaurantTable.table_size.middle"
                              )}
                            </option>
                            <option value="2">
                              {this.props.t(
                                "restaurantTable.table_size.big"
                              )}
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                           {this.props.t(errors.table_size)}
                          </Form.Control.Feedback>
                        </Row>

                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.lableXpos"
                            )}
                          </Form.Label>
                          <div className="col-md-8 px-0">
                            <Form.Control
                              className=""
                              type="text"
                              name="x_pos"
                              placeholder={this.props.t(
                                "restaurantTable.addForm.lableXpos"
                              )}
                              value={values.x_pos}
            
                              onChange={handleChange}
                              isInvalid={!!errors.x_pos}
                            />
                            <Form.Control.Feedback
                              className="ml-3"
                              type="invalid"
                            >
                             {this.props.t(errors.x_pos)}
                            </Form.Control.Feedback>
                          </div>
                          
                        </Row>

                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.lableYpos"
                            )}
                          </Form.Label>
                          <div className="col-md-8 px-0">
                            <Form.Control
                              className=""
                              type="text"
                              name="y_pos"
                              placeholder={this.props.t(
                                "restaurantTable.addForm.lableYpos"
                              )}
                              value={values.y_pos}
            
                              onChange={handleChange}
                              isInvalid={!!errors.y_pos}
                            />
                            <Form.Control.Feedback
                              className="ml-3"
                              type="invalid"
                            >
                                {this.props.t(errors.y_pos)}
                            </Form.Control.Feedback>
                          </div>
                          
                        </Row>
                       
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.lableattribute"
                            )}
                          </Form.Label>
                          <Form.Select
                          name="attribute_group"
                          className="form-control col-md-8 "
                          value={values.attribute_group}
                          onChange={handleChange}
                          isInvalid={!!errors.attribute_group}
                        >
                          <option value="0">
                            -
                          </option>
                          {ArrayOfAlphabet.map((r, index) => {
                            index++;
                            return (
                              <option key={index} value={index}>
                                {this.props.t(
                                  "restaurantHour.tableoption.gruppe_" + r
                                )}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback
                              className="ml-3"
                              type="invalid"
                            >
                                {this.props.t(errors.attribute_group)}
                            </Form.Control.Feedback>
                        </Row>

                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.lableStatus"
                            )}
                          </Form.Label>
                          <Form.Select
                            name="status"
                            className="form-control col-md-8"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={this.statusToValueChange(values)}
                            isInvalid={!!errors.status}
                          >
                            <option value="1">
                              {this.props.t("restaurantTable.status.active")}
                            </option>
                            <option value="2">
                              {this.props.t(
                                "restaurantTable.status.inactive"
                              )}
                            </option>
                            <option value="3">
                              {this.props.t(
                                "restaurantTable.status.sameas"
                              )}
                            </option>
                          </Form.Select>
                        </Row>
                        { values.inherit_table_id_show  ?
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantTable.addForm.table_no"
                            )}
                          </Form.Label>
                            <Form.Select
                              name="inherit_table_id"
                              className="form-control col-md-8"
                              value={values.inherit_table_id}
                              onChange={handleChange}
                              isInvalid={!!errors.inherit_table_id}
                            >
                              {restaurantTables.list.map((r, index) => {
                                
                                return (
                                    r.inherit_table_id === 0  && r.table_number !== values.table_number ?
                                    
                                    <option key={index} value={r.id}>
                                      {r.table_number}
                                    </option>
                                    
                                    : ''
                                  
                                );
                            })}
                            </Form.Select>
                        </Row>
                        : ''
                          }
                       
                        <div className="row">
                          <div className="col-md-4 offset-4 p-0">
                            <Button
                              className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              {this.props.t("button.save")}
                            </Button>
                          </div>
                          <div className="col-md-4 p-0">
                            <Button
                              onClick={() =>
                                this.setState({ showModal: false })
                              }
                              className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                            >
                              <span>{this.props.t("button.cancel")}</span>
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // _pre("ALL DATE", state);
  // const { message } = state.message;
  return {
    restaurants: state.restaurants,
    restaurantTables: state.restaurantTables,
    message: state.message,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveRestaurants,
    retrieveRestaurantsTables,
    deleteRestaurantTables,
    createRestaurantTable,
    updateRestaurantTable,
    getRestaurantTable,
    getRestaurant,
    InsertRestaurantTableMap,
    getRestaurantTablePdfLink,
    //deleteAllTutorials,
  })
)(RestaurantTables);
