import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "./common/message";
import { Row, Form, FormGroup } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { login } from "../actions/auth";

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  schema = yup.object().shape({
    email: yup
      .string()
      .email("user.addForm.validation.validemail")
      .required("user.addForm.validation.email"),
    password: yup.string().required("user.addForm.validation.password"),
  });

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  async handleLogin(values) {
    // e.preventDefault();

    this.setState({
      loading: true,
    });

    // this.form.validateAll();

    const { dispatch, history } = this.props;

    // if (this.checkBtn.context._errors.length === 0) {
    dispatch(login(values.email, values.password))
      .then(() => {
        history.push("/reservation/map");
        // window.location = "/profile";
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
    // } else {
    //   this.setState({
    //     loading: false,
    //   });
    // }
  }

  render() {
    const { isLoggedIn, message } = this.props;

    if (isLoggedIn) {
      return <Redirect to="/reservation/map" />;
    }

    return (
      <div className="signUP-admin1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8">
              <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                {/* <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                  <p className="mb-0">
                    <img
                      src="img/logos/logo-header.png"
                      alt="404"
                      className="svg"
                    />
                  </p>
                </div> */}
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                    <div className="edit-profile mt-md-25 mt-0">
                      <div className="card border-0">
                        {/* <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                          <div className="edit-profile__title">
                            <h6>
                            {this.props.t('pagetitle.login')}{" "}
                              <span className="color-primary"> {this.props.t('pagetitle.login1')}</span>
                            </h6>
                          </div>
                        </div> */}
                        <div className="card-body">
                          <div className="form-group mb-20">
                            <Message message={message} />
                            {/* {message && (
                              <div className="form-group">
                                <div
                                  className="alert alert-danger"
                                  role="alert"
                                  dangerouslySetInnerHTML={{ __html: message }}
                                ></div>
                              </div>
                            )} */}
                          </div>
                          <Formik
                            validationSchema={this.schema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize="true"
                            onSubmit={(values) => {
                              this.handleLogin(values);
                            }}
                            initialValues={{
                              email: this.state.email,
                              password: this.state.password,
                            }}
                          >
                            {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                            }) => (
                              <Form noValidate onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                  <FormGroup className="col-md-12 form-group">
                                    <Form.Label className="color-dark fs-14 fw-500 align-center ant-form-item-required">
                                      {this.props.t("login.labelemail")}
                                    </Form.Label>
                                    <Form.Control
                                      className=""
                                      type="text"
                                      name="email"
                                      placeholder={this.props.t(
                                        "login.labelemail"
                                      )}
                                      value={values.email}
                                      onChange={handleChange}
                                      isInvalid={!!errors.email}
                                    />

                                    <Form.Control.Feedback
                                      className=""
                                      type="invalid"
                                    >
                                      {this.props.t(errors.email)}
                                    </Form.Control.Feedback>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup className="col-md-12 form-group">
                                    <Form.Label className="color-dark fs-14 fw-500 align-center ant-form-item-required">
                                      {this.props.t("login.labelpwd")}
                                    </Form.Label>
                                    <Form.Control
                                      type="password"
                                      name="password"
                                      className="form-control"
                                      onChange={handleChange}
                                      placeholder={this.props.t(
                                        "login.labelpwd"
                                      )}
                                      value={values.password}
                                      isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback
                                      className=""
                                      type="invalid"
                                    >
                                      {this.props.t(errors.password)}
                                    </Form.Control.Feedback>
                                  </FormGroup>
                                </Row>
                                <Row>
                                
                                  <div className="col-6 d-flex align-items-center" />
                                    <div className="col-6 text-right">
                                      <Link className="btn-link" to="/forgot-password">{this.props.t(
                                        "login.forgotPwd"
                                      )}</Link>
                                    </div>
                               

                                </Row>
                                <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                  <button
                                    className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn "
                                    disabled={this.state.loading}
                                  >
                                    {this.state.loading && (
                                      <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>
                                      {this.props.t("login.btnSignin")}
                                    </span>
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                       
                      </div>
                      <p className="social-connector text-center mb-sm-25 mb-15  mt-sm-30 mt-20">
                          <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(Login);
