import React from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';

//import CommonHelper from "./../utils/CommonHelper";
import Joi from "joi-browser";
import Form from "../common/form";
import { updatePassword } from "../../actions/auth-user";
import { _pre } from "../../utils/vlogs";
import { withTranslation  } from 'react-i18next';

//import { ToastContainer, toast } from "react-toastify";
//import Message from "../common/message";

class WalkIn extends Form {
  constructor(props) {
    super(props);
    /*  this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this); */

    /* this.state = {
      id: null,
      title: "",
      description: "",
      published: false,

      submitted: false,
    }; */

    this.state = {
      loading: false,
      data: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      errors: {},
    };
  }

  schema = {
    // _id: "",
    currentPassword: Joi.string()
      .required()
      .label("Current Password"),
    newPassword: Joi.string()
      .required()
      .min(8)
      .max(20)
      .label("New Password"),
    confirmNewPassword: Joi.string()
      //.equal(Joi.ref("newPassword"))
      .required()
      .min(8)
      .max(20)
      .label("Confirm New Password"),
  };

  componentDidMount() {
    /*  const genres = getGenres();
    this.setState({ genres });

    const movieId = this.props.match.params.id;
    if (movieId === "new") return;

    const movie = getMovie(movieId);
    if (!movie) return this.props.history.replace("/not-found");

    this.setState({ data: this.mapToViewModel(movie) }); */
  }

  doSubmit = (e) => {
    //saveMovie(this.state.data);
    //e.preventDefault();

    this.setState({
      loading: true,
    });

    //this.form.validateAll();

    //, history
    const { dispatch } = this.props;

    dispatch(updatePassword(this.state.data))
      .then((response) => {
        _pre(" LAST ", response);
        //history.push("/movies");
        // window.location = "/profile";
        this.setState({
          data: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          },
        });
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });

    //this.props.history.push("/movies");
  };

  render() {
    //const { message } = this.props;
    //const notify = () => toast.error("Wow so easy!");
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                      {this.props.t('pagetitle.walkin')}
                        {/* <button onClick={notify}>Notify!</button> */}
                      </h4>

                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>

                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 p-10 text-center color-yellow">
                      <h3 className="color-warning">
                      {this.props.t('comingsoon.walkin')}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(WalkIn);

