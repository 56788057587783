import {
  CURRENT_RESERVATION,
  // DELETE_ALL_RATES,
} from "../actions/types";

const initialState = [];

function currentReservationReducer(current_reservation = initialState, action) {
  const { type, payload } = action;

  switch (type) {
  

    case CURRENT_RESERVATION:
      return payload;
    // rates.map((rate) => {
    //   if (rate.id === payload.id) {
    //     return {
    //       ...rate,
    //       ...payload,
    //     };
    //   } else {
    //     return rate;
    //   }
    // });

  

    default:
      return current_reservation;
  }
}

export default currentReservationReducer;
