import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";
import CommonHelper from "../utils/CommonHelper";
// import { _pre } from "../utils/vlogs";
export const register = (username, email, password) => (dispatch) => {
  return AuthService.register(username, email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      // _pre("AuthService CYX ", error.response);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (email, password) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: "",
  });
  return AuthService.login(email, password).then(
    (data) => {
      // console.log("in action auth.js -> login then ", data);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data.data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // _pre(" auth.js -> error ", error);
      const resMessage = {
        message: message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: LOGIN_FAIL,
      });
      // console.log(message);
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage,
      });

      return Promise.reject();
    }
  );
};


export const forgotpassword = (formData) => async (dispatch) => {
  try {
    const res = await AuthService.forgotpassword(formData);

    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    });

    /* const resMessage = {
      message: res.data.message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    }); */
    return Promise.resolve(res.data);
  } catch (error) {

    // console.log('in error of create restaurant');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  // console.log(resMessage, 'restaurant');

  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    return Promise.reject(error);
  }
};


export const resetpassword = (formData) => async (dispatch) => {
  try {
    const res = await AuthService.resetpassword(formData);

    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });

    /* const resMessage = {
      message: res.data.message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    }); */
    return Promise.resolve(res.data);
  } catch (error) {

    // console.log('in error of create restaurant');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  // console.log(resMessage, 'restaurant');

  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    return Promise.reject(error);
  }
};



export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
