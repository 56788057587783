import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/reservation";

class ReservationDateSettingDataService {

      getRecordDate(id, start_date) {
        return axios.get(apiEndpoint + `/restaurant-dates?restaurant_id=${id}&start_date=${start_date}`, { headers: authHeader() });
      }


      getRecordDateExternal(data) {
        return axios.post(apiEndpoint + `-external/restaurant-dates`, data,);
      }
    
}

export default new ReservationDateSettingDataService();

