import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import {
  retrieveRestaurantsHours,
  deleteRestaurantHours,
  createRestaurantHour,
  updateRestaurantHour,
  getRestaurantHour,
  //findGuestByKeyword,
  //deleteAllTutorials,
} from "../../actions/restaurant-hours";
import { Row,  Form, Button,  Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import {
  retrieveRestaurants,
  getRestaurant,
  //findGuestByKeyword,
  //deleteAllTutorials,
} from "../../actions/restaurant";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component"; //{ createTheme }
import Message from "../common/message";
import { _getformatDate, _pre } from "../../utils/vlogs";
//import { Button } from "../../components/common/buttons/buttons";
//import FeatherIcon from "feather-icons-react";
// import qs from "querystringify";
import { confirmAlert } from "react-confirm-alert";
import { withTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class RestaurantHours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResturantName:"",
      restaurant: {},
      currentIndex: -1,
      loading: false,
      page: 1, //qsParams.page ? qsParams.page : 1,
      perPage: 10,
      showModal: false,
      showText: "",
      closeModal: false,
      mode: "",
      data: {
        type: "",
        restaurant_id: "",
        shift_name: "",
        attribute_group: "",
        stay_duration: "",
        time_from: "",
        time_to: "",
        week_days: [],
        hp_block: "",
        color_name: "",
        date_from: "",
        date_to: "",
        closed_for: "",
      },
    };
    this.onChangeSearchKeyword = this.onChangeSearchKeyword.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.onDeleteRecord = this.onDeleteRecord.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.dateToValueChange = this.dateToValueChange.bind(this);
    this.timeToValueChange = this.timeToValueChange.bind(this);

  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      this.fetchRestaurantHour(
        this.state.page,
        this.state.perPage,
        this.props.match.params.id
      );
      this.getRecord(this.props.match.params.id);
      await this.fetchRestaurant();
    }
    //
  }

  async handleChange(e) {
    // var id = e.nativeEvent.target.selectedIndex;
    this.props.history.push("/restaurants/hours/" + e.target.value);
     
    this.fetchRestaurantHour(
      this.state.page,
      this.state.perPage,
      e.target.value
    );
    this.getRecord(e.target.value);
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString();
  }

  schemaCondition(type) {

    if (type === 0) {
      this.schema = yup.object().shape({
        shift_name: yup
          .string()
          .required(this.props.t("restaurantHour.validation.shift_name")),
        attribute_group: yup
          .string()
          .required(this.props.t("restaurantHour.validation.table")),
        stay_duration: yup
          .number()
          .required(this.props.t("restaurantHour.validation.stay_duration")),
        time_from: yup
          .string()
          .required(this.props.t("restaurantHour.validation.timefrom")),
        time_to: yup
          .string()
          .required(this.props.t("restaurantHour.validation.timeto")),
        week_days: yup.array().test({
          name: "week_days",
          exclusive: true,
          message: this.props.t("restaurantHour.validation.daysofweek"),
          test: (value) => value.length > 0,
        }),
      });
    } else if (type === 1) {
      this.schema = yup.object().shape({
        shift_name: yup
          .string()
          .required(this.props.t("restaurantHour.validation.shift_name")),
        attribute_group: yup
          .number()
          .required(this.props.t("restaurantHour.validation.table")),
        stay_duration: yup
          .number()
          .required(this.props.t("restaurantHour.validation.stay_duration")),
        time_from: yup
          .string()
          .required(this.props.t("restaurantHour.validation.timefrom")),
        time_to: yup
          .string()
          .required(this.props.t("restaurantHour.validation.timeto")),
        // week_days: yup.string().required(),
        date_from: yup
          .date()
          .required(this.props.t("restaurantHour.validation.datefrom")),

        date_to: yup
          .string()
          .required(this.props.t("restaurantHour.validation.dateto")),
        color_name: yup
          .string()
          .required(this.props.t("restaurantHour.validation.shift_name")),
      });
    } else if (type === 2) {
      this.schema = yup.object().shape({
        shift_name: yup
          .string()
          .required(this.props.t("restaurantHour.validation.shift_name")),
        attribute_group: yup
          .string()
          .required(this.props.t("restaurantHour.validation.table")),
        date_from: yup
          .string()
          .required(this.props.t("restaurantHour.validation.datefrom")),
        date_to: yup
          .string()
          .required(this.props.t("restaurantHour.validation.dateto")),
      });
    }

  }

  openModal(type, msg) {
    this.schemaCondition(type);

    this.setState({
      showModal: true,
      showText: msg,
      mode: type,
      data: {
        type: "",
        restaurant_id: this.props.match.params.id,
        shift_name: "",
        attribute_group: "0",
        stay_duration: "",
        time_from: "",
        time_to: "",
        week_days: [],
        hp_block: "0",
        color_name: "0",
        date_from: "",
        date_to: "",
        closed_for: "0",
      },
    });
  }

  editModal(id, type) {
    //alert("in");
    this.schemaCondition(type);

    this.getRestaurantHourRecord(id);
  }

  getRestaurantHourRecord(id) {
  
    this.props
      .getRestaurantHour(id)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          let title;
          
          title =
            currentRecord.type === 0 && currentRecord.id !== undefined
              ? this.props.t("restaurantHour.addForm.updateopeningtime")
              : currentRecord.type === 1 && currentRecord.id !== undefined
              ? this.props.t("restaurantHour.addForm.updatespecialtime")
              : currentRecord.type === 2 && currentRecord.id !== undefined
              ? this.props.t("restaurantHour.addForm.updatespecialtime")
              : "";
       
          this.setState({
            showModal: true,
            showText: title,
            mode: currentRecord.type,
            data: {
              id: currentRecord.id,
              type: currentRecord.type,
              restaurant_id: this.props.match.params.id,
              shift_name: currentRecord.shift_name,
              attribute_group: currentRecord.attribute_group,
              stay_duration: currentRecord.stay_duration,
              time_from: currentRecord.time_from,
              time_to: currentRecord.time_to,
              week_days: currentRecord.week_days,
              hp_block: currentRecord.hp_block,
              color_name: currentRecord.color_name,
              date_from: currentRecord.date_from,
              date_to: currentRecord.date_to,
              closed_for: currentRecord.closed_for,
            },
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/restaurants");
        }
        // this.setState({
        //   loading: false,
        // });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  doSubmit(values) {
    // console.log(values);

    this.setState({
      loading: true,
    });

    if (values.id !== "" && values.id !== undefined) {
      //Update
      this.props
        .updateRestaurantHour(values.id, values)
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            this.fetchRestaurantHour(
              this.state.page,
              this.state.perPage,
              this.props.match.params.id
            );
            this.setState({
              showModal: false,
            });
            // this.props.history.push("/restaurants/hours/"+this.props.match.params.id);
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      this.props
        .createRestaurantHour(values)
        .then((data) => {
          // console.log(data.status, data.message);

          if (data.status) {
            toast.success(data.message);
            this.setState({
              showModal: false,
            });
            this.fetchRestaurantHour(
              this.state.page,
              this.state.perPage,
              this.props.match.params.id
            );
            this.fetchRestaurant(this.state.page, this.state.perPage);
            // this.props.history.push("/restaurants/hours/"+this.props.match.params.id);
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          // console.log(e);
          this.setState({
            loading: false,
          });
        });
    }
  }

  getRecord(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getRestaurant(id)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          this.setState({
            restaurant: currentRecord,
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  onChangeSearchKeyword(e) {
    const searchKeyword = e.target.value;

    this.setState({
      searchKeyword: searchKeyword,
    });
  }

  refreshData() {
    this.setState({
      currentRes: null,
      currentIndex: -1,
    });
    //this.setState({ loading: false });
    //this.setState({ perPage: 10, page: 1 });
  }

  setActiveTutorial(user, index) {
    this.setState({
      currentRes: user,
      currentIndex: index,
    });
  }

  handlePageChange = async (page) => {
    this.setState({ page: page });

    await this.fetchUser(page, this.state.perPage);
  };

  handlePerRowsChange = async (perPage) => {
    //alert(" HAbd PER PAGE " + perPage);
    this.setState({ perPage: perPage });
    //_pre(" newPerPage ", perPage);

    await this.fetchUser(this.state.page, perPage);
  };

  fetchRestaurantHour = async (page, perPage, id) => {
    this.setState({ loading: true });
    //_pre(" FETCH GUEST ", this.state);
    //_pre(" FETCH GUEST2 ", this.props);
    //alert(this.state.page + " => " + this.state.perPage);
    //alert(page + " => " + perPage);
    await this.props.retrieveRestaurantsHours(page, perPage, id);
    this.setState({ loading: false });
  };

  fetchRestaurant = async (page, perPage) => {
    // this.setState({ loading: true });
    //_pre(" FETCH GUEST ", this.state);
    //_pre(" FETCH GUEST2 ", this.props);
    //alert(this.state.page + " => " + this.state.perPage);
    //alert(page + " => " + perPage);
    await this.props.retrieveRestaurants(page, perPage);
    // this.setState({ loading: false });
  };

  onUpdateBtnClick() {
    alert("click");
  }

  onDeleteRecord = async (id) => {
    confirmAlert({
      title: this.props.t("messages.delalertmsgreshour"),
      message: this.props.t("messages.doyouwantcon"),
      buttons: [
        {
          label: this.props.t("messages.yes"),
          onClick: () => this.deleteConfirm(id),
        },
        {
          label: this.props.t("messages.no"),
          onClick: () => "",
        },
      ],
    });
  };

  dateToValueChange(values){

    if(values.date_from > values.date_to)
    {
      values.date_to = ""
    }

    // console.log(values);
  }

  timeToValueChange(values){

    if(values.time_from > values.time_to)
    {
      values.time_to = ""
    }

    // console.log(values);
  }

  deleteConfirm(id) {
    // return false;
    this.setState({
      loading: true,
    });
    this.props
      .deleteRestaurantHours(id)
      .then((data) => {
        this.setState({
          loading: false,
        });
        toast.success(this.props.t("messages.delsucmessage"));
        this.fetchRestaurantHour(
          this.state.page,
          this.state.perPage,
          this.props.match.params.id
        );
        this.fetchRestaurant(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  getformatDate(date, format) {
    return _getformatDate(date, format);
  }

  render() {
    console.log(this.state, "Ssssssss");
    const ArrayOfAlphabet = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    const paginationComponentOptions = {
      rowsPerPageText: this.props.t("datatable.rowperpage"),
      rangeSeparatorText: this.props.t("datatable.of"),
      selectAllRowsItem: true,
    };

    const CustomLoader = () => (
      <div>
        <h2 className="text-center">{this.props.t("datatable.loading")}</h2>
      </div>
    );
    const { loading } = this.state;
    const { restaurants, restaurantHours, message } = this.props;

    
    const ArrayOfDays = [
      this.props.t("restaurantHour.dayCheckbox.mon"),
      this.props.t("restaurantHour.dayCheckbox.tue"),
      this.props.t("restaurantHour.dayCheckbox.wed"),
      this.props.t("restaurantHour.dayCheckbox.thu"),
      this.props.t("restaurantHour.dayCheckbox.fri"),
      this.props.t("restaurantHour.dayCheckbox.sat"),
      this.props.t("restaurantHour.dayCheckbox.sun"),
    ];
    // console.log("ggggggggggggggggg", message);
    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
          //background: "#cb4b16",
        },
      },
    };
    return (
    <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.t("pagetitle.restaurants_hour")}
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                {/* <div className="card-header color-dark fw-500">Rate List</div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="action-btn  form-inline-action d-flex justify-content-between mb-3">
                        <Form.Select
                          name=""
                          defaultValue={this.props.match.params.id}
                          onChange={this.handleChange}
                          className="form-control col-md-4"
                        >
                          {restaurants.list
                            ? restaurants.list.map((r, index) => {
                                return (
                                  <option key={index} value={r.id} selected={ this.props.match.params.id === r.id ? 'selected' : "" }>
                                    {r.restaurant_name}
                                  </option>
                                );
                              })
                            : ""}
                        </Form.Select>
                        <div className="d-inline-flex">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() =>
                              this.openModal(
                                0,
                                this.props.t(
                                  "restaurantHour.addForm.addopeningtime"
                                )
                              )
                            }
                          >
                            <i className="fa fa-plus"></i>{" "}
                            {this.props.t("pagetitle.open")}{" "}
                            <i
                              className="fa fa-check-square ml-2"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() =>
                              this.openModal(
                                1,
                                this.props.t(
                                  "restaurantHour.addForm.addspecialtime"
                                )
                              )
                            }
                          >
                            <i className="fa fa-plus"></i>{" "}
                            {this.props.t("pagetitle.special")}{" "}
                            <i
                              className="fa fa-calendar-check ml-2"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() =>
                              this.openModal(
                                2,
                                this.props.t(
                                  "restaurantHour.addForm.addclosetime"
                                )
                              )
                            }
                          >
                            <i className="fa fa-plus"></i>{" "}
                            {this.props.t("pagetitle.closed")}{" "}
                            <i
                              className="fa fa-times ml-2"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Link
                        to="/restaurants"
                        className="btn btn-sm btn-primary ml-2 float-right"
                      >
                        {this.props.t("button.back")}
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-12 ">
                      <div className="">
                        <Message message={message} />
                      </div>
                    </div> */}

                    <div className="col-md-12 p-10">
                      <DataTable
                        // title="Users"
                        customStyles={customStyles}
                        columns={[
                          {
                            name: this.props.t("restaurantHour.table.type"),
                            cell: (row) =>
                              row.type === 0 ? (
                                <i
                                  key={row.id}
                                  className="fa fa-check-square text-success"
                                  aria-hidden="true"
                                  title="Open"
                                ></i>
                              ) : row.type === 1 && row.color_name === 1 ? (
                                <i
                                  key={row.id}
                                  className="fa fa-calendar-check text-warning"
                                  aria-hidden="true"
                                  title="Sonderöffnungszeit"
                                ></i>
                              ) : row.type === 1 && row.color_name === 0 ? (
                                <i
                                  key={row.id}
                                  className="fa fa-calendar-check text-default"
                                  aria-hidden="true"
                                  title="Sonderöffnungszeit"
                                ></i>
                              ) : (
                                <i
                                  key={row.id}
                                  className="fa fa-times text-danger"
                                  aria-hidden="true"
                                  title="Geschlossen"
                                ></i>
                              ),
                            sortable: false,
                            width: "10%",
                          },
                          {
                            name: this.props.t(
                              "restaurantHour.table.shift_name"
                            ),
                            selector: (row) => row.shift_name,
                            sortable: false,
                            width: "15%",
                          },
                          {
                            name: this.props.t(
                              "restaurantHour.table.stay_duration"
                            ),
                            cell: (row) => (
                              <div key={row.id}>
                                {row.type === 2 ? "-" : row.stay_duration}
                              </div>
                            ),
                            sortable: false,
                            width: "15%",
                          },
                          {
                            name: this.props.t(
                              "restaurantHour.table.days_time"
                            ),
                            cell: (row) => (
                              <div key={row.id} className="text-left">
                                {row.week_days.length > 0 ? (
                                  <>
                                    <div className="d-flex">
                                      {ArrayOfDays.map((r, index) =>
                                        row.week_days.includes(`${index++}`) ? (
                                          <div key={index} className="ml-2">
                                            {r},
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </div>
                                    <br></br>
                                  </>
                                ) : (
                                  ""
                                )}

                                {(row.type === 2 &&
                                  row.date_from === row.date_to) ||
                                (row.type === 1 &&
                                  row.date_from === row.date_to) ? (
                                  <div className="mb-3">
                                    <span className="ml-2">
                                      {this.getformatDate(
                                        row.date_from,
                                        "dddd MMMM Do YYYY"
                                      )}
                                    </span>
                                    {/* <span className="ml-2">{this.getParsedDate(row.date_to)}</span> */}
                                    <br></br>
                                  </div>
                                ) : row.type === 1 || row.type === 2 ? (
                                  <div className="mb-3">
                                    <span className="ml-2">
                                      {this.getformatDate(
                                        row.date_from,
                                        "DD.MM.YYYY"
                                      )}
                                    </span>{" "}
                                    -
                                    <span className="ml-2">
                                      {this.getformatDate(
                                        row.date_to,
                                        "DD.MM.YYYY"
                                      )}
                                    </span>
                                    <br></br>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {row.type === 0 || row.type === 1 ? (
                                  <>
                                    <span className="ml-2">
                                      {row.time_from.substring(0, 5)}
                                    </span>{" "}
                                    -
                                    <span className="ml-2">
                                      {row.time_to.substring(0, 5)}
                                    </span>
                                  </>
                                ) : (
                                  <span className="ml-2">-</span>
                                )}
                              </div>
                            ),
                            sortable: false,
                            width: "25%",
                          },
                          {
                            name: this.props.t(
                              "restaurantHour.table.closed_for"
                            ),
                            cell: (row) => (
                              <div key={row.id}>
                                {row.type === 2 ? row.closed_for_type : ""}
                              </div>
                            ),
                            sortable: false,
                            width: "15%",
                          },
                          {
                            name: this.props.t("restaurantHour.table.action"),
                            //selector: (row) => row.dob,
                            cell: (row) => (
                              <ul
                                key={row.id}
                                className="orderDatatable_actions table_actions mb-0 d-flex flex-wrap"
                              >
                                {/* <li>
                                  <a
                                    href="#!"
                                    className="view color-info1 feather feather-eye color-extra-light"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                    href="#!"
                                    onClick={() =>
                                      this.editModal(row.id, row.type)
                                    }
                                    className="edit color-primary1 color-extra-light"
                                  >
                                    <i className="fa fa-pencil-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#!"
                                    className="remove color-danger1 color-extra-light"
                                    //onClick={this.onDeleteRecord(row)}
                                    //onClick={() => onDeleteRecord(row.id)}
                                    onClick={() => this.onDeleteRecord(row.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                            width: "20%",
                            key: "action",
                          },
                        ]}
                        data={restaurantHours.list}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        paginationTotalRows={restaurantHours.total}
                        //selectableRows
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        highlightOnHover
                        dense
                      />
                    </div>
                    <div className="col-md-12"></div>
                  </div>
                </div>
              </div>
              <Modal
                size="lg"
                show={this.state.showModal}
                onHide={() => this.setState({ showModal: false })}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {this.state.showText}
                  </Modal.Title>
                  <button
                    type="button"
                    onClick={() => this.setState({ showModal: false })}
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  { Object.keys(message).length !== 0 && message.message ?
                  <div className="form-group mb-20">
                    <Message message={message} />
                  </div>
                  : ''
                  }
                  <Formik
                    validationSchema={this.schema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize="true"
                    onSubmit={(values) => {
                      this.doSubmit(values);
                    }}
                    initialValues={{
                      id: this.state.data.id,
                      type: this.state.mode,
                      restaurant_id: this.state.restaurant.id,
                      shift_name: this.state.data.shift_name,
                      attribute_group: this.state.data.attribute_group,
                      stay_duration: this.state.data.stay_duration,
                      time_from: this.state.data.time_from,
                      time_to: this.state.data.time_to,
                      week_days: this.state.data.week_days,
                      hp_block: this.state.data.hp_block,
                      color_name: this.state.data.color_name,
                      date_from: this.state.data.date_from,
                      date_to: this.state.data.date_to,
                      closed_for: this.state.data.closed_for,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          {console.log(errors)}
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t(
                              "restaurantHour.addForm.lablerestaurant"
                            )}
                          </Form.Label>
                          <Form.Label className="col-md-8 color-dark fs-14 align-center ant-form-item-required">
                            {this.state.restaurant.restaurant_name}
                          </Form.Label>
                        </Row>
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t("restaurantHour.addForm.labletables")}
                          </Form.Label>
                          <Form.Select
                            name="attribute_group"
                            className="form-control col-md-8 "
                            value={values.attribute_group}
                            onChange={handleChange}
                            isInvalid={!!errors.attribute_group}
                          >
                            <option value="0">
                              {this.props.t("restaurantHour.addForm.all_table")}
                            </option>
                            {ArrayOfAlphabet.map((r, index) => {
                              index++;
                              return (
                                <option key={index} value={index}>
                                  {this.props.t(
                                    "restaurantHour.tableoption.gruppe_" + r
                                  )}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                            {errors.attribute_group}
                          </Form.Control.Feedback>
                        </Row>
                        <Row className="mb-3">
                          <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                            {this.props.t("restaurantHour.addForm.shift_name")}
                          </Form.Label>
                          <Form.Control
                            className="col-md-8"
                            type="text"
                            name="shift_name"
                            placeholder={this.props.t(
                              "restaurantHour.addForm.shift_name"
                            )}
                            value={values.shift_name}
                            onChange={handleChange}
                            isInvalid={!!errors.shift_name}
                          />
                          <Form.Control.Feedback
                            className="col-md-8 offset-4"
                            type="invalid"
                          >
                            {errors.shift_name}
                          </Form.Control.Feedback>
                        </Row>
                        {this.state.mode === 0 || this.state.mode === 1 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t("restaurantHour.addForm.labletime")}
                            </Form.Label>
                            <Form.Control
                              className="col-md-8"
                              type="text"
                              name="stay_duration"
                              placeholder={this.props.t(
                                "restaurantHour.addForm.labletime"
                              )}
                              value={values.stay_duration}
                              onChange={handleChange}
                              isInvalid={!!errors.stay_duration}
                            />
                            <Form.Control.Feedback
                              className="col-md-8 offset-4"
                              type="invalid"
                            >
                              {errors.stay_duration}
                            </Form.Control.Feedback>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 0 || this.state.mode === 1 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t(
                                "restaurantHour.addForm.labletimeft"
                              )}
                            </Form.Label>
                            <div className="col-md-3 px-0">
                              <Form.Control
                                className=""
                                type="time"
                                name="time_from"
                                placeholder={this.props.t(
                                  "restaurantHour.addForm.labletimeft"
                                )}
                                value={values.time_from}
                                onBlur={this.timeToValueChange(values)}
                                onChange={handleChange}
                                isInvalid={!!errors.time_from}
                              />
                              <Form.Control.Feedback
                                className="ml-3"
                                type="invalid"
                              >
                                {errors.time_from}
                              </Form.Control.Feedback>
                            </div>
                            <div className="col-md-3 px-0">
                              <Form.Control
                                className="ml-5"
                                type="time"
                                name="time_to"
                                placeholder={this.props.t(
                                  "restaurantHour.addForm.labletimeft"
                                )}
                                value={values.time_to}
                                onChange={handleChange}
                                min={values.time_from}
                                isInvalid={!!errors.time_to}
                              />
                              <Form.Control.Feedback
                                className="ml-5 cm-3"
                                type="invalid"
                              >
                                {errors.time_to}
                              </Form.Control.Feedback>
                            </div>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 1 || this.state.mode === 2 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t("restaurantHour.addForm.lableDate")}
                            </Form.Label>
                            <div className="col-md-3 px-0">
                              <Form.Control
                                className="datepicker1"
                                type="date"
                                name="date_from"
                                placeholder={this.props.t(
                                  "restaurantHour.addForm.labletimeft"
                                )}
                                value={values.date_from}
                                onChange={handleChange}
                                onBlur={this.dateToValueChange(values)}
                                isInvalid={!!errors.date_from}
                                min={new Date().toISOString().split("T")[0]}
                              />
                              <Form.Control.Feedback
                                className="ml-3"
                                type="invalid"
                              >
                                {errors.date_from}
                              </Form.Control.Feedback>
                            </div>
                            <div className="col-md-3 px-0">
                              <Form.Control
                                className="ml-5 datepicker"
                                type="date"
                                name="date_to"
                                placeholder={this.props.t(
                                  "restaurantHour.addForm.labletimeft"
                                )}
                                value={values.date_to}
                                onChange={handleChange}
                                isInvalid={!!errors.date_to}
                                min={values.date_from}
                              />
                              <Form.Control.Feedback
                                className="cm-3"
                                type="invalid"
                              >
                                {errors.date_to}
                              </Form.Control.Feedback>
                            </div>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 0 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t("restaurantHour.addForm.lablemday")}
                            </Form.Label>

                            {ArrayOfDays.map((r, index) => {
                              return values.week_days.includes(`${index}`) ? (
                                <Form.Group
                                  key={index}
                                  className="my-3 ml-3"
                                  controlId={`forBasicCheckbox${index}`}
                                >
                                  <Form.Check
                                    size="lg"
                                    type="checkbox"
                                    name="week_days"
                                    checked="checked"
                                    value={index}
                                    label={r}
                                    onChange={handleChange}
                                    isInvalid={!!errors.week_days}
                                  />
                                </Form.Group>
                              ) : (
                                <Form.Group
                                  key={index}
                                  className="my-3 ml-3"
                                  controlId={`forBasicCheckbox${index}`}
                                >
                                  <Form.Check
                                    size="lg"
                                    type="checkbox"
                                    name="week_days"
                                    value={index}
                                    label={r}
                                    onChange={handleChange}
                                    isInvalid={!!errors.week_days}
                                  />
                                </Form.Group>
                              );
                            })}
                            {errors.week_days !== "" ? (
                              <Form.Control.Feedback
                                className="col-md-6 offset-4 d-block"
                                type="invalid"
                              >
                                {errors.week_days}
                              </Form.Control.Feedback>
                            ) : (
                              ""
                            )}
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 0 || this.state.mode === 1 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t(
                                "restaurantHour.addForm.labletableblock"
                              )}
                            </Form.Label>
                            <Form.Select
                              name="hp_block"
                              className="form-control col-md-8"
                              value={values.hp_block}
                              onChange={handleChange}
                              isInvalid={!!errors.hp_block}
                            >
                              <option value="0">
                                {this.props.t("restaurantHour.addForm.no")}
                              </option>
                              <option value="1">
                                {this.props.t(
                                  "restaurantHour.addForm.the_whole_shift"
                                )}
                              </option>
                            </Form.Select>
                            <Form.Control.Feedback
                              className="col-md-8 offset-4"
                              type="invalid"
                            >
                              {errors.hp_block}
                            </Form.Control.Feedback>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 1 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t(
                                "restaurantHour.addForm.lableHighlight_in_calender"
                              )}
                            </Form.Label>
                            <Form.Select
                              name="color_name"
                              className="form-control col-md-8"
                              value={values.color_name}
                              onChange={handleChange}
                              isInvalid={!!errors.color_name}
                            >
                              <option value="0">
                                {this.props.t("restaurantHour.addForm.no")}
                              </option>
                              <option value="1">
                                {this.props.t(
                                  "restaurantHour.highlighOption.orange"
                                )}
                              </option>
                            </Form.Select>
                          </Row>
                        ) : (
                          ""
                        )}
                        {this.state.mode === 2 ? (
                          <Row className="mb-3">
                            <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                              {this.props.t(
                                "restaurantHour.addForm.lableclose"
                              )}
                            </Form.Label>
                            <Form.Select
                              name="closed_for"
                              className="form-control col-md-8"
                              value={values.closed_for}
                              onChange={handleChange}
                              isInvalid={!!errors.closed_for}
                            >
                              <option value="0">
                                {this.props.t(
                                  "restaurantHour.closeOption.intAndOn"
                                )}
                              </option>
                              <option value="1">
                                {this.props.t(
                                  "restaurantHour.closeOption.just_intern"
                                )}
                              </option>
                              <option value="2">
                                {this.props.t(
                                  "restaurantHour.closeOption.only_online"
                                )}
                              </option>
                            </Form.Select>
                          </Row>
                        ) : (
                          ""
                        )}

                        <div className="row">
                          <div className="col-md-4 offset-4 p-0">
                            <Button
                              className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                              type="submit"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              {this.props.t("button.save")}
                            </Button>
                          </div>
                          <div className="col-md-4 p-0">
                            <Button
                              onClick={() =>
                                this.setState({ showModal: false })
                              }
                              className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                            >
                              <span>{this.props.t("button.cancel")}</span>
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  _pre("ALL DATE", state);
  // const { message } = state.message;
  return {
    restaurants: state.restaurants,
    restaurantHours: state.restaurantHours,
    message: state.message,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveRestaurants,
    createRestaurantHour,
    updateRestaurantHour,
    getRestaurantHour,
    retrieveRestaurantsHours,
    deleteRestaurantHours,
    getRestaurant,
    //deleteAllTutorials,
  })
)(RestaurantHours);
