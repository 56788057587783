import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Row,  Form, Button } from "react-bootstrap";
import { _pre } from "../../utils/vlogs";
import Message from "../common/message";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import {
  createRestaurant,
  getRestaurant,
  updateRestaurant,
} from "../../actions/restaurant";
// import { Empty } from "antd";

class AddRestaurant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      roles: [],
      data: {
        restaurant_name: "",
        restaurant_email: "",
        link_menu: "",
        link_cancellation: "",
        signature_mail: "",
        hint_text: "",
        user_name: "",
        email: "",
        phone: "",
        color_code: 0,
        hp_block: 0,
        is_visible_all: 0,
        default_adults: 0,
        interval_time: 15,
        table_limit: 0,
        reminder: 0,
        free_period: "",
        blocking_time: "",
      },
      errors: {},
    };

    this.doSubmit = this.doSubmit.bind(this);
  }

  schema =  yup.object().shape({
    restaurant_name: yup.string().required(this.props.t('restaurant.addForm.validation.designation')),
    email: yup.string().email(this.props.t('restaurant.addForm.validation.email')),
    });


  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.getRecord(this.props.match.params.id);
    }
  }

  getRecord(id) {
    this.setState({
      loading: true,
    });
    this.props
      .getRestaurant(id)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          console.log(currentRecord);

          this.setState({
            data: {
              restaurant_name: currentRecord.restaurant_name,
              restaurant_email: currentRecord.restaurant_email,
              link_menu: currentRecord.link_menu,
              link_cancellation: currentRecord.link_cancellation,
              signature_mail: currentRecord.signature_mail,
              hint_text: currentRecord.hint_text,
              email: currentRecord.email,
              phone: currentRecord.phone,
              color_code: currentRecord.color_code,
              hp_block: currentRecord.hp_block,
              is_visible_all: currentRecord.is_visible_all,
              default_adults: currentRecord.default_adults,
              interval_time: currentRecord.interval_time,
              table_limit: currentRecord.table_limit,
              reminder: currentRecord.reminder,
              free_period: currentRecord.free_period,
              blocking_time: currentRecord.blocking_time,
            },
          });
        } else {
          toast.error(data.message);
          this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  doSubmit(values) {


    console.log(values);
    this.setState({
      loading: true,
    });

    if (this.props.match.params.id > 0) {
      //Update
      this.props
        .updateRestaurant(this.props.match.params.id, values)
        .then((data) => {
          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/restaurants");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    } else {
      //#TODO Insert Record
      this.props
        .createRestaurant(values)
        .then((data) => {
          console.log(data.status, data.message);

          if (data.status) {
            toast.success(data.message);
            this.props.history.push("/restaurants");
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false, 
          });
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            loading: false,
          });
        });
    }
  }

  render() {
    // console.log(this.state.roles, 'hhhhhhhhhhhhhhhhhhhhhh');
    // let optionRoles = [{ id: "0", name: "select Roles" }];
    // if (this.state.roles != undefined) {
    //   optionRoles = this.state.roles;
    // }
    const { message } = this.props;
    // const options = [
    //   { id: "0", name: "Mr." },
    //   { id: "1", name: "Mrs." },
    // ];

    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.match.params.id
                          ? this.props.t("restaurant.updateRestaurant")
                          : this.props.t("restaurant.AddRestaurant")}
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12-cus p-10 horizontal-form">
                      <div className="form-group mb-20">
                        <Message message={message} />
                      </div>
                      <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          this.doSubmit(values);
                        }}
                        initialValues={{
                          restaurant_name: this.state.data.restaurant_name,
                          restaurant_email: this.state.data.restaurant_email,
                          link_menu: this.state.data.link_menu,
                          link_cancellation: this.state.data.link_cancellation,
                          signature_mail: this.state.data.signature_mail,
                          hint_text: this.state.data.hint_text,
                          email: this.state.data.email,
                          phone: this.state.data.phone,
                          color_code: this.state.data.color_code,
                          hp_block: this.state.data.hp_block,
                          is_visible_all: this.state.data.is_visible_all,
                          default_adults: this.state.data.default_adults,
                          interval_time: this.state.data.interval_time,
                          table_limit: this.state.data.table_limit,
                          reminder: this.state.data.reminder,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ant-form-item-label ">
                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.labledesignation"
                                )}
                              </Form.Label>
                            </div>
                            <div className="col-md-9">

                              <Form.Control
                                
                                type="text"
                                name="restaurant_name"
                                placeholder={this.props.t(
                                  "restaurant.addForm.labledesignation"
                                )}
                                value={values.restaurant_name}
                                onChange={handleChange}
                                isInvalid={!!errors.restaurant_name}
                              />
                              <Form.Control.Feedback
                                
                                type="invalid"
                              >
                              { this.props.t('restaurant.addForm.validation.designation')}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">
                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.lableDesignationEmail"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                                
                                type="text"
                                name="restaurant_email"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lableDesignationEmail"
                                )}
                                value={values.restaurant_email}
                                onChange={handleChange}
                                isInvalid={!!errors.restaurant_email}
                              />
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.restaurant_email}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lablemenulink")}
                              </Form.Label>
                                  </div>
                                  <div className="col-md-9">
                              <Form.Control
                               
                                type="text"
                                name="link_menu"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lablemenulink"
                                )}
                                value={values.link_menu}
                                onChange={handleChange}
                                isInvalid={!!errors.link_menu}
                              />
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.link_menu}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            {/* <Row className="mb-3">
                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableCancellink")}
                              </Form.Label>
                              <Form.Control
                                className="col-md-8"
                                type="text"
                                name="link_cancellation"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lableCancellink"
                                )}
                                value={values.link_cancellation}
                                onChange={handleChange}
                                isInvalid={!!errors.link_cancellation}
                              />
                              <Form.Control.Feedback
                                className="col-md-8 offset-4"
                                type="invalid"
                              >
                                {errors.link_cancellation}
                              </Form.Control.Feedback>
                            </Row> */}
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableHintText")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                               
                                name="hint_text"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lableHintText"
                                )}
                                value={values.hint_text}
                                onChange={handleChange}
                                isInvalid={!!errors.hint_text}
                                as="textarea"
                                rows={3}
                              />
                              <Form.Control.Feedback
                                
                                type="invalid"
                              >
                                {errors.hint_text}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableSignatureText")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">

                              <Form.Control
                                
                                name="signature_mail"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lableSignatureText"
                                )}
                                value={values.signature_mail}
                                onChange={handleChange}
                                isInvalid={!!errors.signature_mail}
                                as="textarea"
                                rows={2}
                              />
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.signature_mail}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableEmail")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                              
                                type="email"
                                name="email"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lableEmail"
                                )}
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback
                                
                                type="invalid"
                              >
                              { this.props.t('restaurant.addForm.validation.designation')}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lablephone")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Control
                                
                                type="text"
                                name="phone"
                                placeholder={this.props.t(
                                  "restaurant.addForm.lablephone"
                                )}
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                              />
                              <Form.Control.Feedback
                              
                                type="invalid"
                              >
                                {errors.phone}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lablecolor")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="color_code"
                                className="form-control"
                                onChange={handleChange}
                                value={values.color_code}
                                isInvalid={!!errors.color_code}
                              >
                                <option value="0">
                                  {this.props.t("restaurant.option_color_code.none")}
                                </option>
                                <option value="1">
                                  {this.props.t("restaurant.option_color_code.green")}
                                </option>
                                <option value="2">
                                  {this.props.t("restaurant.option_color_code.violet")}
                                </option>
                                <option value="3">
                                  {this.props.t("restaurant.option_color_code.blue")}
                                </option>
                                <option value="4">
                                  {this.props.t("restaurant.option_color_code.orange")}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.color_code}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableTableBlocking")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="hp_block"
                                className="form-control"
                                value={values.hp_block}
                                onChange={handleChange}
                                isInvalid={!!errors.hp_block}
                              >
                                <option value="0">
                                  {this.props.t(
                                    "restaurant.option_table_blocking.none"
                                  )}
                                </option>
                                <option value="1">
                                  {this.props.t(
                                    "restaurant.option_table_blocking.theWholeShift"
                                  )}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback
                                
                                type="invalid"
                              >
                                {errors.hp_block}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.lableRestaurantList"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="is_visible_all"
                                className="form-control"
                                value={values.is_visible_all}
                                onChange={handleChange}
                                isInvalid={!!errors.is_visible_all}
                              >
                                <option value="0">
                                  {this.props.t("restaurant.option_restaurantList.not_visible")}
                                </option>
                                <option value="1">
                                  {this.props.t("restaurant.option_restaurantList.visible")}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.is_visible_all}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t("restaurant.addForm.lableStandardNumberOfAdult")}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="default_adults"
                                className="form-control"
                                value={values.default_adults}
                                onChange={handleChange}
                                isInvalid={!!errors.default_adults}
                              >
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </Form.Select>
                              <Form.Control.Feedback
                                className="col-md-8 offset-4"
                                type="invalid"
                              >
                                {errors.default_adults}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center ">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.lableinterval"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="interval_time"
                                className="form-control"
                                value={values.interval_time}
                                onChange={handleChange}
                                isInvalid={!!errors.interval_time}
                              >
                                <option value="15">
                                  {this.props.t(
                                    "restaurant.option_interval_time.15min"
                                  )}
                                </option>
                                <option value="30">
                                  {this.props.t(
                                    "restaurant.option_interval_time.30min"
                                  )}
                                </option>
                              </Form.Select>
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.interval_time}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.lablelimitInterval"
                                )}
                              </Form.Label>
                              </div>
                              <div className="col-md-9">
                              <Form.Select
                                name="table_limit"
                                className="form-control"
                                value={values.table_limit}
                                onChange={handleChange}
                                isInvalid={!!errors.table_limit}
                              >
                                <option value="0">{this.props.t('restaurant.option_table_limit.none')}</option>
                                <option value="3">{this.props.t('restaurant.option_table_limit.3_tab')}</option>
                                <option value="4">{this.props.t('restaurant.option_table_limit.4_tab')}</option>
                                <option value="5">{this.props.t('restaurant.option_table_limit.5_tab')}</option>
                                <option value="6">{this.props.t('restaurant.option_table_limit.6_tab')}</option>
                                <option value="7">{this.props.t('restaurant.option_table_limit.7_tab')}</option>
                                <option value="8">{this.props.t('restaurant.option_table_limit.8_tab')}</option>
                                <option value="9">{this.props.t('restaurant.option_table_limit.9_tab')}</option>
                                <option value="10">{this.props.t('restaurant.option_table_limit.10_tab')}</option>
                              </Form.Select>
                              <Form.Control.Feedback
                               
                                type="invalid"
                              >
                                {errors.table_limit}
                              </Form.Control.Feedback>
                              </div>
                            </Row>
                            <Row className="mb-3">
                            <div className="col-md-3 d-flex align-items-center">

                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "restaurant.addForm.lablereminder"
                                )}
                              </Form.Label>
                                  </div>
                                  <div className="col-md-9">
                              <Form.Select
                                name="reminder"
                                className="form-control"
                                value={values.reminder}
                                onChange={handleChange}
                                isInvalid={!!errors.reminder}
                                >
                                <option value="0">{this.props.t('restaurant.option_reminder.none')}</option>
                                <option value="1">{this.props.t('restaurant.option_reminder.theDayBeforeAm')}</option>
                              </Form.Select>
                              <Form.Control.Feedback

                                type="invalid"
                              >
                                {errors.reminder}
                              </Form.Control.Feedback>

                              <p>
                                {this.props.t(
                                  // "restaurant.addForm.lableremidertext"
                                )}
                              </p>
                              </div>
                            </Row>

                            <div className="mb-3 row">
                              <div className="col-md-3 d-flex align-items-center"></div>
                              <div className="col-md-9 d-flex justify-content-between">
                              <Button
                                  className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                                  type="submit"
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  {this.props.t("button.save")}
                                </Button>
                                <Link
                                  to="/restaurants"
                                  className="btn btn-light btn-default btn-squared text-capitalize float-right lh-normal px-40 py-15 "
                                >
                                  <span>{this.props.t("button.cancel")}</span>
                                </Link>
                              </div>
                            </div>

                          
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  _pre("ALL DATE RES", state);
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    createRestaurant,
    getRestaurant,
    updateRestaurant,

    //deleteAllTutorials,
  })
)(AddRestaurant);
