import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
// import { Redirect } from 'react-router';

import "./App.css";

import Login from "./components/login.component";
import ForgotPassword from "./components/forgotPassword.component";
import ResetPassword from "./components/resetPassword.component";



// import Profile from "./components/profile.component";
import ChangePassword from "./components/change-password.component";

import Dashboard from "./components/dashboard.component";

import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";

import { history } from "./helpers/history";
import EventBus from "./common/EventBus";
import ProtectedRoute from "./components/common/protectedRoute";

// import { _pre } from "./utils/vlogs";
import AppHeader from "./components/layout/AppHeader";
import AppSideBar from "./components/layout/AppSideBar";
import AppFooter from "./components/layout/AppFooter";

import Reservation from "./components/reservation/Reservation";
import ReservationCopy from "./components/reservation/ReservationCopy";



import WalkIn from "./components/walkin/WalkIn";
import Logs from "./components/log/Logs";

import GuestUserList from "./components/guest-user/guest-list.component";
import GuestUserAdd from "./components/guest-user/add-guest.component";

import UserList from "./components/users/user-list.component";
import UserAdd from "./components/users/add-user.component";

import RestaurantList from "./components/restaurants/restaurant-list.component";
import RestaurantAdd from "./components/restaurants/add-restaurant.component";

import RestaurantHours from "./components/restaurants/restaurant-hours-list.component";
import RestaurantTables from "./components/restaurants-table/restaurant-table-list.component";

import RateList from "./components/rate/rate-list.component";
import RateAdd from "./components/rate/add-rate.component";

import QrBookingStep1 from "./components/qr-booking/qr-booking-step1.component";
import QrBookingStep2 from "./components/qr-booking/qr-booking-step2.component";
import QrBookingStep3 from "./components/qr-booking/qr-booking-step3.component";
import QrBookingStep4 from "./components/qr-booking/qr-booking-step4.component";
import QrBookingStep5 from "./components/qr-booking/qr-booking-step5.component";

import ConfigurationList from "./components/configuration/configuration-list.component";
import page_404 from "./components/page_404.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      toggled: false,
    };

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        currentUser: user,
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }
  componentWillUnmount() {
    EventBus.remove("logout");
  }
  logOut() {
    this.props.dispatch(logout());
    this.setState({
      currentUser: undefined,
    });
    history.push("/login");
  }
  toggleMenu() {
    //console.log(this.state.toggled);
    let isToggled = this.state.toggled;
    // this.setState({ toggled: !isToggled });
    this.setState((state) => {
      // Important: read `state` instead of `this.state` when updating.
      return { toggled: !isToggled };
    });
  }
  render() {
    // _pre("1. APP  Render ", this.props);
    const { user: currentUser } = this.props;

    // console.log(currentUser);

    return (
      <Router history={history}>
        {currentUser ? (
          <>
            <Suspense fallback="loading">
              <AppHeader
                currentUser={currentUser}
                onClick={() => this.logOut()}
                toggleMenu={() => this.toggleMenu()}
                toggled={this.state.toggled}
              />
            </Suspense>
            <AppSideBar
              currentUser={currentUser}
              toggled={this.state.toggled}
            />
          </>
        ) : (
          <>
            <Suspense fallback="loading">
              <AppHeader
                currentUser={currentUser}
                onClick={() => this.logOut()}
                toggleMenu={() => this.toggleMenu()}
                toggled={this.state.toggled}
              />
            </Suspense>
          </>
        )}

        <Switch>
          <Route exact path={["/", "/home", "/login"]} component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route
            exact
            path="/reservation_external/:lan/:clientId/:restaurantId?"
            component={ReservationCopy}
          />
          <Route
            exact
            path="/qr-booking/step1/:id"
            component={QrBookingStep1}
          />
          <Route
            exact
            path="/qr-booking/step2/:id"
            component={QrBookingStep2}
          />
          <Route
            exact
            path="/qr-booking/step3/:id"
            component={QrBookingStep3}
          />
          <Route
            exact
            path="/qr-booking/step4/:id"
            component={QrBookingStep4}
          />
          <Route
            exact
            path="/qr-booking/step5/:id"
            component={QrBookingStep5}
          />

          <ProtectedRoute
            exact
            path="/change-password"
            component={ChangePassword}
          />

          <ProtectedRoute
            exact
            path="/reservation/map"
            component={Reservation}
          />
          <ProtectedRoute
            exact
            path="/reservation/list"
            component={Reservation}
          />

          <ProtectedRoute
            exact
            path="/reservation/calender"
            component={Reservation}
          />

          <ProtectedRoute exact path="/walk-in" component={WalkIn} />
          <Route exact path="/dashboard" component={Dashboard} />

          <ProtectedRoute exact path="/guests/add" component={GuestUserAdd} />
          <ProtectedRoute
            exact
            path="/guests/update/:id"
            component={GuestUserAdd}
          />
          <ProtectedRoute exact path="/guests" component={GuestUserList} />

          <ProtectedRoute exact path="/rates/add" component={RateAdd} />
          <ProtectedRoute exact path="/rates/update/:id" component={RateAdd} />
          <ProtectedRoute exact path="/rates" component={RateList} />

          <ProtectedRoute exact path="/logs" component={Logs} />
          <ProtectedRoute
            exact
            path="/configuration"
            component={ConfigurationList}
          />

          <ProtectedRoute exact path="/users/add" component={UserAdd} />
          <ProtectedRoute exact path="/users/update/:id" component={UserAdd} />
          <ProtectedRoute exact path="/users" component={UserList} />
          <ProtectedRoute
            exact
            path="/restaurants/hours/:id"
            component={RestaurantHours}
          />
          <ProtectedRoute
            exact
            path="/restaurants/tables/:id"
            component={RestaurantTables}
          />
          <ProtectedRoute
            exact
            path="/restaurants/add"
            component={RestaurantAdd}
          />
          <ProtectedRoute
            exact
            path="/restaurants/update/:id"
            component={RestaurantAdd}
          />
          <ProtectedRoute
            exact
            path="/restaurants"
            component={RestaurantList}
          />

          <ProtectedRoute path="*" component={page_404} />
        </Switch>

        {window.location.pathname !== "/login" && currentUser ? (
          <>
            <AppFooter currentUser={currentUser}  toggled={this.state.toggled}/>
          </>
        ) : (
          ""
        )}
      </Router>
    );
  }
}

function mapStateToProps(state) {
  // console.log(state);
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
