import React from "react";

const Textarea = ({ name, label, error, ...rest }) => {
  return rest.eType === "V" ? (
    <div
      className={
        "form-group " + (rest.required === true ? " ant-form-item-label" : "")
      }
    >
      <label
        htmlFor={name}
        className={
          "color-dark fs-14 fw-600 align-center " +
          (rest.required === true ? " ant-form-item-required" : "")
        }
      >
        {label}
        {/* <span className="text-red">*</span> */}
      </label>

      <textarea
        {...rest}
        name={name}
        id={name}
        className="form-control ih-medium ip-gray radius-xs b-light px-15"
        placeholder={"Enter " + label}
        required={rest.required}
      ></textarea>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  ) : (
    <div>
      <div className="form-group row">
        <div
          className={
            "col-sm-4 d-flex aling-items-center" +
            (rest.required === true ? " ant-form-item-label" : "")
          }
        >
          <label
            htmlFor={name}
            className={
              "col-form-label color-dark fs-14 fw-600 align-center " +
              (rest.required === true ? " ant-form-item-required" : "")
            }
          >
            {label}
            {/* <span className="text-red">*</span> */}
          </label>
        </div>
        <div className="col-sm-8">
          <textarea
            {...rest}
            name={name}
            id={name}
            className="form-control ih-medium ip-gray h-auto radius-xs b-light px-15"
            placeholder={"Enter " + label}
            required={rest.required}
          ></textarea>
        </div>
        <div className="col-8 pt-2 offset-md-4">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default Textarea;
