import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { toast } from "react-toastify";
import {
  retrieveRates,
  findRateByKeyword,
  deleteRate,
  //deleteAllTutorials,
} from "../../actions/rate.action";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component"; //{ createTheme }
// import { _pre } from "../../utils/vlogs";
import Message from "../common/message";
// import { Button } from "../../components/common/buttons/buttons";
// import FeatherIcon from "feather-icons-react";
// import qs from "querystringify";
import { confirmAlert } from "react-confirm-alert";
import { withTranslation } from "react-i18next";

class RateList extends Component {
  constructor(props) {
    super(props);
    // const qsParams = qs.parse(props.location.search);
    this.state = {
      currentRate: null,
      currentIndex: -1,
      searchKeyword: "",
      loading: false,
      page: 1, //qsParams.page ? qsParams.page : 1,
      perPage: 10,
      showModal: false,
    };

    this.onChangeSearchKeyword = this.onChangeSearchKeyword.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.findByTitle = this.findByTitle.bind(this);
    this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
    this.onDeleteRecord = this.onDeleteRecord.bind(this);
  }
  componentDidMount() {
    // _pre(" ********** componentDidMount IN  ***********", "RATE-list");
    //this.props.retrieveGuests();
    /* if (qsParams.page) {
      this.fetchGuest(qsParams.page, this.state.perPage);
    } else {
      this.fetchGuest(this.state.page, this.state.perPage);
    } */
    //this.fetchGuest(3, this.state.perPage);
    //this.handlePageChange(3);
    this.fetchRate(this.state.page, this.state.perPage);
    // console.log(" ********** componentDidMount OUT  ***********");
  }

  onChangeSearchKeyword(e) {
    const searchKeyword = e.target.value;

    this.setState({
      searchKeyword: searchKeyword,
    });
  }

  refreshData() {
    this.setState({
      currentRate: null,
      currentIndex: -1,
    });
    //this.setState({ loading: false });
    //this.setState({ perPage: 10, page: 1 });
  }

  setActiveTutorial(rate, index) {
    this.setState({
      currentRate: rate,
      currentIndex: index,
    });
  }

  handlePageChange = async (page) => {
    this.setState({ page: page });
    //console.log(this.state.page);
    //_pre(" PAGE ", page);
    //alert(" handlePageChange PER PAGE " + page);
    // this.fetchGuest();
    //this.fetchUsers(page);
    await this.fetchRate(page, this.state.perPage);
  };

  handlePerRowsChange = async (perPage) => {
    //alert(" HAbd PER PAGE " + perPage);
    this.setState({ perPage: perPage });
    //_pre(" newPerPage ", perPage);

    await this.fetchRate(this.state.page, perPage);
  };
  fetchRate = async (page, perPage) => {
    this.setState({ loading: true });
    //_pre(" FETCH GUEST ", this.state);
    //_pre(" FETCH GUEST2 ", this.props);
    //alert(this.state.page + " => " + this.state.perPage);
    //alert(page + " => " + perPage);
    await this.props.retrieveRates(page, perPage);
    this.setState({ loading: false });
  };
  removeAllTutorials() {
    /* this.props
      .deleteAllTutorials()
      .then((response) => {
        console.log(response);
        this.refreshData();
      })
      .catch((e) => {
        console.log(e);
      }); */
  }

  findByTitle() {
    this.refreshData();

    this.props.findRateByKeyword(this.state.searchKeyword);
  }
  onUpdateBtnClick() {
    alert("click");
  }
  onDeleteRecord = async (id) => {
    confirmAlert({
      title: this.props.t("messages.delalertmsgrate"),
      message: this.props.t("messages.doyouwantcon"),
      buttons: [
        {
          label: this.props.t("messages.yes"),
          onClick: () => this.deleteConfirm(id),
        },
        {
          label: this.props.t("messages.no"),
          onClick: () => "",
        },
      ],
    });
  };

  deleteConfirm(id) {
    this.setState({
      loading: true,
    });
    this.props
      .deleteRate(id)
      .then((data) => {
        this.setState({
          loading: false,
        });
        toast.success("Record deleted successfully");
        //this.props.history.push("/guests");
        this.fetchRate(this.state.page, this.state.perPage);
        /* if (data.status) {
          toast.success(data.message);
          //this.props.history.push("/guests");
          this.fetchGuest(this.state.page, this.state.perPage);
        } else {
          toast.error(data.message); 
        }*/
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    // console.log(this.props);
    const paginationComponentOptions = {
      rowsPerPageText: this.props.t("datatable.rowperpage"),
      rangeSeparatorText: this.props.t("datatable.of"),
      selectAllRowsItem: true,
    };

    const CustomLoader = () => (
      <div><h2 className="text-center">{this.props.t("datatable.loading")}</h2></div>
    );
    const { loading } = this.state;
    const { rates, message } = this.props;
    // console.log( rates, 'ggggggggggggggggggggggggggggggggggggggggg');
    //message.type = "success";
    //message.message = "this is testing";
    /* createTheme(
      "solarized",
      {
        text: {
          primary: "#268bd2",
          secondary: "#2aa198",
        },
        background: {
          default: "#000",
        },
        context: {
          background: "#cb4b16",
          text: "#FFFFFF",
        },
        divider: {
          default: "#073642",
        },
        action: {
          button: "rgba(0,0,0,.54)",
          hover: "rgba(0,0,0,.08)",
          disabled: "rgba(0,0,0,.12)",
        },
      },
      "dark"
    ); */
    const customStyles = {
      rows: {
        style: {
          minHeight: "72px", // override the row height
          //background: "#cb4b16",
        },
      },
      headCells: {
        style: {
          /* paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
          background: "#000",
          borderLeft: "1px solid #f1f2f6",
          borderRadius: "10px 0px 0px 10px",
          color: "#5a5f7d",
          background: "#f8f9fb",
          borderTop: "1px solid #f1f2f6",
          borderBottom: "1px solid #f1f2f6",
          whiteSpace: "nowrap", */
        },
      },
      cells: {
        style: {
          //paddingLeft: "8px", // override the cell padding for data cells
          //paddingRight: "8px",
        },
      },
    };
    return (
      <div className="contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-breadcrumb">
                <div className="breadcrumb-main add-contact justify-content-sm-between ">
                  <div className=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                    <div className="d-flex align-items-center add-contact__title justify-content-center mr-sm-25">
                      <h4 className="text-capitalize fw-500 breadcrumb-title">
                        {this.props.t("pagetitle.rate")}
                      </h4>
                      <span className="sub-title ml-sm-25 pl-sm-25"></span>
                    </div>
                  </div>
                  <div className="breadcrumb-main__wrapper"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-30">
              <div className="card">
                {/* <div className="card-header color-dark fw-500">Rate List</div> */}
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="action-btn  form-inline-action d-flex justify-content-between align-items-center">
                        <div className="checkbox-theme-default custom-checkbox "></div>
                        <Link
                          to="/rates/add"
                          className="btn btn-sm btn-primary btn-add h-35 for-button-padding"
                        >
                          {" "}
                          <i className="fa fa-plus"></i>
                        </Link>
                        {/* <a
                          href="/guests/add"
                          className="btn btn-sm btn-primary btn-add col-md-1 col-12"
                        >
                          <i className="fa fa-plus"></i> Add New
                        </a> */}
                        {/* <Button
                          className="btn-add_new"
                          size="default"
                          key="1"
                          type="primary"
                        >
                          <FeatherIcon icon="plus" size={14} /> Add New Customer
                        </Button> */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 ">
                      <div className="">
                        <Message message={message} />
                      </div>
                    </div>

                    <div className="col-md-12 p-10">
                      <DataTable
                        // title="Users"
                        customStyles={customStyles}
                        columns={[
                          {
                            name: this.props.t("rate.table.name"),
                            selector: (row) => row.rate_name,
                            sortable: false,
                            width: "40%",
                          },
                          {
                            name: this.props.t("rate.table.Abbreviation"),
                            selector: (row) => row.abbreviation,
                            //sortable: true,
                            width: "40%",
                          },
                          
                          {
                            name: this.props.t("rate.table.action"),
                            //selector: (row) => row.dob,
                            cell: (row) => (
                              <ul className="orderDatatable_actions table_actions mb-0 d-flex flex-wrap">
                                {/* <li>
                                  <a
                                    href="#!"
                                    className="view color-info1 feather feather-eye color-extra-light"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </a>
                                </li> */}
                                <li>
                                  <Link
                                    to={"/rates/update/" + row.id}
                                    className="edit color-primary1 color-extra-light"
                                  >
                                    <i className="fa fa-pencil-alt"></i>
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    href="#!"
                                    className="remove color-danger1 color-extra-light"
                                    //onClick={this.onDeleteRecord(row)}
                                    //onClick={() => onDeleteRecord(row.id)}
                                    onClick={() => this.onDeleteRecord(row.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </a>
                                </li>
                              </ul>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                            width: "20%",
                            key: "action",
                          },
                        ]}
                        data={rates.list}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationServer
                        paginationTotalRows={rates.total}
                        //selectableRows
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                      />
                    </div>
                    <div className="col-md-12"></div>

                    {/* <div className="col-md-12 p-10">
                      <DataTable
                        // title="Users"
                        columns={[
                          {
                            name: "Name",
                            selector: (row) => row.first_name + row.last_name,
                            sortable: true,
                          },
                          {
                            name: "Phone",
                            selector: (row) =>
                              Math.floor(Math.random(7, 9) * 1111111111),
                            sortable: true,
                          },
                          {
                            name: "Email",
                            selector: (row) => row.email,
                            sortable: true,
                          },
                          {
                            name: "Photo",
                            //selector: (row) => row.dob,
                            cell: (row) => (
                              <div>
                                <img
                                  height="30px"
                                  width="30px"
                                  alt={row.name}
                                  src={`https://i.pravatar.cc/150?img=${row.id}`}
                                />
                                {row.name}{" "}
                              </div>
                            ),
                          },
                        ]}
                        data={tList}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        selectableRows
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.rates,
    message: state.message,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveRates,
    findRateByKeyword,
    deleteRate,
    //deleteAllTutorials,
  })
)(RateList);
