import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { _pre } from "../../utils/vlogs";
import i18n from "../../i18n/i18n";
import CommonHelper from "../../utils/CommonHelper";
import { withTranslation } from "react-i18next";

//import $ from "jquery";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    //_pre(" props ", props);

    if (localStorage.getItem("lan")) {
      this.state = {
        selectedLang: localStorage.getItem("lan"),
      };
    } else {
      this.state = {
        selectedLang: "de",
      };
    }

    this.sideBarToggle = this.sideBarToggle.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);

    // this.toggleMenu = this.toggleMenu.bind(this);

    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    this.sideBarToggle();
  }
  sideBarToggle() {
    const script = document.createElement("script");
  script.src = "/assets/theme_assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    //$(".contents").hide();

    //$(".sidebar-toggle").click(function() {
    /* if ($(".header-top").hasClass("add-padding")) {
      $(".header-top").removeClass("add-padding1");
    } else {
      $(".header-top").addClass("add-padding1");
    } */
    //    });
  }
  /* toggleMenu() {
    let isToggled = this.state.toggled;
    this.setState({ toggled: !isToggled });
    this.props.toggleMenu();
  } */
  changeLanguage(value) {
    // console.log(value);
    this.setState({
      selectedLang: value,
    });

    localStorage.setItem("lan", value);

    i18n.changeLanguage(value);
  }

  render() {
    // console.log(window.location.origin);
    //_pre(" Header ", this.props);
    const { currentUser, toggled } = this.props;
    let buttonClass = toggled ? "add-padding1" : "";
    let toggleClass = toggled ? "margin-left-add": "margin-left-add1";
    //_pre(" buttonClass ", buttonClass);
    //_pre(" t ", this.props.toggled);
    return (
      <>
        {currentUser && !window.location.pathname.includes('/qr-booking')  ? (
          <>
            <header className={"header-top " + buttonClass}>
              <nav className="navbar navbar-light">
                <div className={"navbar-left " + toggleClass}>
                  <a
                    href="#!"
                    className="sidebar-toggle fffs"
                    onClick={this.props.toggleMenu}
                  >
                    <img
                      className="svg"
                      src={`${window.location.origin}/img/svg/bars.svg`}
                      alt="img"
                    />
                  </a>
                </div>

                <div className="navbar-right">
                  <ul className="navbar-right__menu">
                    <li className="nav-search d-none">
                      <a href="#!" className="search-toggle">
                        <i className="la la-search"></i>

                        <i className="la la-times"></i>
                      </a>

                      <form action="/" className="search-form-topMenu">
                        <span
                          className="search-icon"
                          data-feather="search"
                        ></span>

                        <input
                          className="form-control mr-sm-2 box-shadow-none"
                          type="search"
                          placeholder="Search... "
                          aria-label="Search"
                        />
                      </form>
                    </li>

                    {/* <li className="nav-settings">
                      <div className="dropdown-custom">
                        <a href="#!" className="nav-item-toggle">
                          <span data-feather="settings"></span>
                        </a>

                        <div className="dropdown-wrapper dropdown-wrapper--large">
                          <ul className="list-settings">
                            <li className="d-flex">
                              <div className="mr-3">
                                <img src="img/rated.svg" alt="" />
                              </div>

                              <div className="flex-grow-1">
                                <h6>
                                  <a
                                    href="rate.php?page=rate"
                                    className=" <?= ($page =='rate') ? 'active' : '' ?>"
                                  >
                                    Rate
                                  </a>
                                </h6>
                              </div>
                            </li>

                            <li className="d-flex">
                              <div className="mr-3">
                                <img src="img/configuration.svg" alt="" />
                              </div>

                              <div className="flex-grow-1">
                                <h6>
                                  <a
                                    className="<?= ($page =='configuration') ? 'active' : '' ?>"
                                    href="configuration.php?page=configuration"
                                  >
                                    Konfiguration
                                  </a>
                                </h6>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li> */}

                    <li className="nav-support">
                      <div className="dropdown-custom">
                        <a href="#!" className="nav-item-toggle">
                          <span data-feather="help-circle"></span>
                        </a>

                        <div className="dropdown-wrapper">
                          <div className="list-group">
                            <span>Documentation</span>

                            <ul>
                              <li>
                                <a href="#!">How to customize admin</a>
                              </li>

                              <li>
                                <a href="#!">How to use</a>
                              </li>

                              <li>
                                <a href="#!">
                                  The relation of vertical spacing
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="list-group">
                            <span>Payments</span>

                            <ul>
                              <li>
                                <a href="#!">How to customize admin</a>
                              </li>

                              <li>
                                <a href="#!">How to use</a>
                              </li>
                            </ul>
                          </div>

                          <div className="list-group">
                            <span>Content Planner</span>

                            <ul>
                              <li>
                                <a href="#!">How to customize admin</a>
                              </li>

                              <li>
                                <a href="#!">How to use</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="nav-flag-select">
                      <div className="dropdown-custom">
                        <a href="#!" className="nav-item-toggle">
                          <h6
                            className="rounded-circle"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.selectedLang}
                          </h6>
                        </a>

                        <div className="dropdown-wrapper dropdown-wrapper--small">
                          <a
                            href="#!"
                            value="en"
                            onClick={() => this.changeLanguage("en")}
                          >
                            <b>EN</b>
                          </a>

                          <a
                            href="#!"
                            value="de"
                            onClick={() => this.changeLanguage("de")}
                          >
                            <b>DE</b>
                          </a>
                        </div>
                      </div>
                    </li>

                    <li className="nav-author">
                      <div className="dropdown-custom">
                        <a href="#!" className="nav-item-toggle">
                          <div className="ant-avatar ant-avatar-lg ant-avatar-circle avtar-round-header">
                            <span className="ant-avatar-string avtar-round-hstring">
                              {CommonHelper._nameInitials(currentUser.fullName)}
                            </span>
                          </div>
                          {/*  <img
                            src="img/author-nav.jpg"
                            alt=""
                            className="rounded-circle"
                          /> */}
                        </a>

                        <div className="dropdown-wrapper">
                          <div className="nav-author__info">
                            <div className="author-img">
                              <div className="ant-avatar ant-avatar-lg ant-avatar-circle avtar-round-header rounded-circle">
                                <span className="ant-avatar-string avtar-round-hstring">
                                  {CommonHelper._nameInitials(
                                    currentUser.fullName
                                  )}
                                </span>
                              </div>
                            </div>

                            <div>
                              <h6>{currentUser.fullName}</h6>

                              <span>3W TECHNOLOGIES OWNER</span>
                            </div>
                          </div>

                          <div className="nav-author__options">
                            <ul>
                              <li>
                                <Link to={"/profile"}>
                                  <span data-feather="user"></span>
                                  {this.props.t("pagetitle.profile")}
                                </Link>
                              </li>
                              <li>
                                <Link to={"/change-password"}>
                                  <span data-feather="user"></span>{" "}
                                  {this.props.t("pagetitle.changepassword")}
                                </Link>
                              </li>

                              {/* <li>
                                <a href="#!">
                                  <span data-feather="settings"></span> Settings
                                </a>
                              </li> */}
                            </ul>

                            <a
                              href="/login"
                              className="nav-author__signout "
                              onClick={this.props.onClick}
                            >
                              <span data-feather="log-out"></span>{" "}
                              {this.props.t("pagetitle.signout")}
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
          </>
        ) :!window.location.pathname.includes('/reservation_external') ? (
          <>
            <header className={"header-top " + buttonClass}>
              <nav className="navbar navbar-light">
                <div className="navbar-left">
                  <img
                    className="svg"
                    src={`${window.location.origin}/img/logos/logo_loginpage.png`}
                    alt="JT Logo"
                  />
                </div>

                <div className="navbar-right">
                  <ul className="navbar-right__menu">
                    <li className="nav-flag-select">
                      <div className="dropdown-custom">
                        <a href="#!" className="nav-item-toggle">
                          <h6
                            className="rounded-circle"
                            style={{ textTransform: "uppercase" }}
                          >
                            {this.state.selectedLang}
                          </h6>
                        </a>

                        <div className="dropdown-wrapper dropdown-wrapper--small">
                          <a
                            href="#!"
                            value="en"
                            onClick={() => this.changeLanguage("en")}
                          >
                            <b>EN</b>
                          </a>

                          <a
                            href="#!"
                            value="de"
                            onClick={() => this.changeLanguage("de")}
                          >
                            <b>DE</b>
                          </a>
                        </div>
                      </div>
                    </li>
                    {/* <li className="nav-support">&nbsp;</li> */}
                  </ul>
                </div>
              </nav>
            </header>
          </>
        )
      :(
        <>
        </>
      )}
      </>
    );
  }
}

//  export default connect(mapStateToProps, mapDispatchToProps)(Eventdetail)
export default withTranslation()(AppHeader);
//export default Header;
