import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Textarea from "./textarea";
import Select from "./select";

class Form extends Component {
  constructor(props) {
    super(props);
    /*  this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveTutorial = this.saveTutorial.bind(this);
    this.newTutorial = this.newTutorial.bind(this); */

    this.state = {
      data: {},
      errors: {},
    };

    this.validate = this.validate.bind(this);
    // this.validateProperty = this.valivalidatePropertydate.bind();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    console.log(error, "error for validatessssssssssssssssssssssssssssssss");
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  renderButton(label, loading) {
    return (
      <button
        disabled={this.validate()}
        className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15 "
      >
        {loading && <span className="spinner-border spinner-border-sm"></span>}
        <span> {label}</span>
      </button>
    );
  }

  renderSelect(
    name,
    label,
    options,
    isOptionValue = true,
    required = false,
    eType = "H"
  ) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        isOptionValue={isOptionValue}
        required={required}
        eType={eType}
      />
    );
  }

  renderInput(name, label, type = "text", required = false, eType = "H") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        eType={eType}
        
        autoComplete="new-password"
      />
    );
  }

  renderTextarea(name, label, required = false, eType = "H") {
    const { data, errors } = this.state;

    return (
      <Textarea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        eType={eType}
        rows="4"
        cols="50"
      />
    );
  }
}

export default Form;
