import {
    RETRIEVE_RESERVATIONS_CALENDER,
  } from "../actions/types";
  
  const initialState = [];
  
  function reservationCalenderReducer(reservationsCalender = initialState, action) {
    const { type, payload } = action;
    // console.log(type, payload);
    switch (type) {
  
      case RETRIEVE_RESERVATIONS_CALENDER:
        return payload;
  
      default:
        return reservationsCalender;
    }
  }
  
  export default reservationCalenderReducer;
  
  