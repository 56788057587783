import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/configurations";

class ConfigurationDataService {
  getAll(lang = "") {
    return axios.get(apiEndpoint + `?lang=${lang}`, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getAllByResturantClientId(data) {
    return axios.post(apiEndpoint + `-external`, data );
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }
}

export default new ConfigurationDataService();