import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
//import CommonHelper from "./../utils/CommonHelper";
// import Joi from "joi-browser";
// import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "../common/message";
import { Row,  Form} from "react-bootstrap";
//import { updatePassword } from "../../actions/auth-user";
// import { _pre } from "../../utils/vlogs";
//import { ToastContainer, toast } from "react-toastify";
import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { createCode, getRestaurantByTableId } from "../../actions/qr-booking";
import Cookie from 'universal-cookie';

// const phoneRegExp = "/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";

class QrBookingStep1 extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      phone: "",
      loading: false,
      restaurant:{},
    };
  }



   async componentDidMount() {
    if (this.props.match.params.id) {
      const cookies = new Cookie();
      this.getRecord(parseInt(this.props.match.params.id.substring(4)));
      cookies.set('is_verified', true, { path: '/' })
      cookies.set('step', 1, { path: '/' })
      
    }
    //
  }

  getRecord(id){
    this.setState({
      loading: true,
    });
    let values = {
      table_id: id,
    };
    this.props
      .getRestaurantByTableId(values)
      .then((data) => {
        console.log(data.data)
        if (data.status) {
          // const { detail: currentRecord } = data;
          this.setState({
            restaurant: data.data,
          });
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }
 
  schema =  yup.object().shape({
    
    phone: yup.string().required('qrcodeBooking.addformstep1.validation.phone'),
  
    });

    async doSubmit(values){
      // console.log(values);
      // console.log( parseInt(this.props.match.params.id.substring(4)));
      // return false;
      values.reservation_id = parseInt(this.props.match.params.id.substring(4));
      //resetForm({ values: "" });
      //console.log(values);
      //this.props.switch();
      //this.props.reservationList();
      //this.props.switch();
      //return true;
      await this.props
        .createCode(values)
        .then((data) => {
          // console.log(data, "sssssssssssssss");
          // //console.log(data.status, data.message);
          if (data.status) {
            toast.success(data.message);
            localStorage.setItem("phone", values.phone);
            const cookies = new Cookie();
            cookies.set('is_verified', true, { path: '/' })
            cookies.set('step', 2, { path: '/' })
            this.props.history.push("/qr-booking/step2/tid="+parseInt(this.props.match.params.id.substring(4)));
          } else {
            toast.error(data.message);
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          //console.log(e);
          this.setState({
            loading: false,
          });
          toast.error(e.response.data.message);
          // console.log(e.response.data,"ddddddddddddddddddddd");
        });
      

    }
    
 

  render() {
    const { message } = this.props;

    const { restaurant } = this.state;
    console.log(this.state);
    if(restaurant){
    return (
      <div className="signUP-admin1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 card my-3">
              <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                    <div className="mt-0">
                      <div className="card border-0">
                        <div className="card-header border-0 pb-md-15 pb-10 pt-md-20 pt-10 ">
                          <div className="edit-profile__title">
                            <h6>
                            {this.props.t('qrcodeBooking.step1title')}
                            </h6>
                            <p className="font-weight-bold">  {this.props.t('qrcodeBooking.table')} {restaurant.table_number} / {restaurant.restaurant_name}</p>
                          </div>
                        </div>
                        <div className="card-body">
                         
                        <div className="form-group mb-20">
                        <Message message={message} />
                        
                       </div>
                        <Formik
                        validationSchema={ this.schema }
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                          this.doSubmit(values);
                        }}
                        initialValues={{
                          phone: this.state.phone,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          
                        }) => (
                          
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-4">
                            
                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "qrcodeBooking.addformstep1.lablephone"
                                )}
                              </Form.Label>
                              <PhoneInput
                                className="col-md-8"
                                country={'ch'}
                                defaultErrorMessage="It doesn't works, why?"
                                isInvalid={!!errors.phone}
                                inputProps={{
                                  name: 'phone',
                                  required: true,
                                  autoFocus: true,
                                  className: "form-control",
                                 
                                  onChange:handleChange
                                }}
                                value={values.phone}
                                enableSearch={true}
                                copyNumbersOnly={false}
                                placeholder='078 123 45 67'
                              
                              />                              
                              <Form.Control.Feedback
                                className="col-md-8 offset-4 d-block"
                                type="invalid"
                              >
                           
                                { this.props.t(errors.phone)}
                              </Form.Control.Feedback>
                            </Row>
                                                    
                            <div className="row mt-4">
                              <div className="col-md-4 offset-4">
                                <button
                                  className="btn btn-primary btn-sm btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                                  disabled={this.state.loading}
                                  type="submit"
                                >
                                  {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  )}
                                  <span>{this.props.t('qrcodeBooking.addformstep1.buttonContinue')}</span>
                                </button>
                              </div>
                          </div>
                          </Form>
                        )}
                      </Formik>

                        
                        </div>
                      </div>
                      {/* <p className="social-connector text-right mb-sm-25 mb-15  mt-sm-30 mt-20">
                          <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                        </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
      }else{
        return(
          <>
          </>
        )
      }

  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);
export default compose(withTranslation() ,connect(mapStateToProps, {
  createCode,
  getRestaurantByTableId,
  //deleteAllTutorials,
}))(QrBookingStep1);

