import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { DatePicker } from "antd";
import Message from "../common/message";
import moment from "moment";
// import { confirmAlert } from "react-confirm-alert";
//import CommonHelper from "./../utils/CommonHelper";
import { toast } from "react-toastify";
// import Joi from "joi-browser";
import { compose } from "redux";
import { Form} from "react-bootstrap";
import { retrieveReservationCalender } from "../../actions/reservation-calender";
// import { _pre } from "../../utils/vlogs";
import { withTranslation } from "react-i18next";

class ReservationCalender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedRestaurantId: props.selectedRestaurantId,
      startDate: moment(),
      slot_time: [],
      table_list: [],
      length_of_stay: 0,
      slot_occupy: 0,
      errors: {},
      selectedStartDate: moment().format("YYYY-MM-DD"),
    };

    this.handleRestaurantChange = this.handleRestaurantChange.bind(this);
    this.handleCalendarDateChange = this.handleCalendarDateChange.bind(this);
    this.addReservation = this.addReservation.bind(this);
  }

  async componentDidMount() {
    console.log(this.props, "didmout");
    if (this.props.selectedRestaurantId !== 0) {
      await this.fetchReservationCalender();
    }
  }

  fetchReservationCalender = async (page = 1, perPage = 10) => {
    let data = {
      page: page,
      perPage: perPage,
      start_date: this.state.startDate.format("YYYY-MM-DD"),
      restaurant_id: this.state.selectedRestaurantId,
    };
    await this.props.retrieveReservationCalender(data);

    console.log(this.props, "rese");
    this.setState({
      slot_time: this.props.reservationsCalender.slot_time,
      table_list: this.props.reservationsCalender.table_list,
      length_of_stay: this.props.reservationsCalender.length_of_stay,
      slot_occupy: this.props.reservationsCalender.slot_occupied,
    });
    //alert("V1");
    //await this.tableReservationList(data);
    //await this.props.retrieveTableReservations(data);
  };

  async handleRestaurantChange(e) {
    //alert(e.target.value);
    await this.setState({
      restaurant_id: e.target.value,
      selectedRestaurantId: e.target.value,
    });
    await this.fetchReservationCalender();
  }

  async handleCalendarDateChange1(e) {
    await this.setState({
      startDate: e.target.value,
    });
    await this.fetchReservationCalender();
  }
  async handleCalendarDateChange(date, dateString) {
    await this.setState({
      startDate: date,
      selectedStartDate: date.format("YYYY-MM-DD"),
    });
    await this.fetchReservationCalender();
  }

  async addReservation(e, time) {
    if (this.props.user.role_slug === "admin") {
      toast.success(this.props.t("messages.underDevlopMsg"));
    } else {
    }
  }

  render() {
    // console.log(this.props, 'reservation calender');
    const { restaurants, message } = this.props;
    let indexStr = 0;
    // const { message } = this.props;
    //const notify = () => toast.error("Wow so easy!");
    //alert("in ");
    return (
      <>
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <div className="action-btn  form-inline-action d-flex ">
              <Form.Select
                name="resturantList"
                /* defaultValue={this.state.selectedRestaurantId} */
                /* value={this.state.selectedRestaurantId} */
                value={this.state.selectedRestaurantId}
                ref="resturantList"
                onChange={this.handleRestaurantChange}
                className="form-control col-md-4"
              >
                {restaurants.list
                  ? restaurants.list.map((r, index) => {
                      return (
                        <option
                          key={index}
                          value={r.id}
                          selected={
                            this.state.selectedRestaurantId === r.id
                              ? "selected"
                              : ""
                          }
                        >
                          {r.restaurant_name}
                        </option>
                      );
                    })
                  : ""}
              </Form.Select>

              <DatePicker
                type="text"
                format={"YYYY/MM/DD"}
                value={this.state.startDate}
                //disabledDate={this.disabledDate}
                size="large"
                className="ml-3"
                onChange={this.handleCalendarDateChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <div className="">
              <Message message={message} />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card mt-4">
            <div id="main-div">
              {/* <div className="container-fluid"> */}
              <table
                className="table-booking content-table"
                style={{ display: "none2" }}
              >
                <colgroup></colgroup>
                <thead>
                  <tr>
                    <th>
                      <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>No.</font>
                      </font>
                    </th>
                    {this.state.slot_time.length > 0
                      ? this.state.slot_time.map((s, index) => {
                          return (
                            <th colSpan={4}>
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  {s}
                                </font>
                              </font>
                            </th>
                          );
                        })
                      : ""}
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_list.length > 0
                    ? this.state.table_list.map((t, index) => {
                        return (
                          <tr>
                            <th>
                              <font style={{ verticalAlign: "inherit" }}>
                                <font style={{ verticalAlign: "inherit" }}>
                                  {t.table_number}
                                </font>
                              </font>
                            </th>
                            {t.slot.length > 0
                              ? t.slot.map((st, index) => {
                                  if (
                                    Object.keys(st.reservation_data).length !==
                                    0
                                  ) {
                                    indexStr = index + 7;
                                  } else if (indexStr + 1 === index) {
                                    indexStr = 0;
                                  }
                                  // }else{
                                  //   indexStr = 0
                                  // }

                                  /* {
                                    console.log(indexStr);
                                  } */
                                  let classTd =
                                    st.slot_status === 1
                                      ? "available"
                                      : st.slot_status === 0
                                      ? "off"
                                      : "block";
                                  
                                  return Object.keys(st.reservation_data)
                                    .length !== 0 ? (
                                    <td
                                      colSpan="8"
                                      className="mrg-25"
                                      id={index}
                                      onClick={(e) =>
                                        this.addReservation(e, st.time)
                                      }
                                    >
                                      <div
                                        class={
                                          st.reservation_data.color_str_class
                                        }
                                      >
                                        <h3>
                                          {st.reservation_data.first_name}
                                          &nbsp;
                                          {st.reservation_data.last_name}
                                          <br />
                                          {st.reservation_data.adults + "E"}
                                          {st.reservation_data.ehp === 1 ? (
                                            <sub>HP</sub>
                                          ) : (
                                            ""
                                          )}
                                          {st.reservation_data.children_1 +
                                            st.reservation_data.children_2 +
                                            st.reservation_data.children_3 +
                                            st.reservation_data.children_4 !==
                                          0
                                            ? parseInt(
                                                st.reservation_data.children_1
                                              ) +
                                              parseInt(
                                                st.reservation_data.children_2
                                              ) +
                                              parseInt(
                                                st.reservation_data.children_3
                                              ) +
                                              parseInt(
                                                st.reservation_data.children_4
                                              ) +
                                              "K"
                                            : ""}
                                        </h3>
                                      </div>
                                    </td>
                                  ) : indexStr < index ? (
                                    <td
                                      colSpan="1"
                                      className={classTd}
                                      id={index}
                                      onClick={(e) =>
                                        this.addReservation(e, st.time)
                                      }
                                    ></td>
                                  ) : (
                                    ""
                                  );
                                })
                              : ""}
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              {/* </div>
            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
    reservationsCalender: state.reservationsCalender,
  };
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveReservationCalender,
  })
)(ReservationCalender);
