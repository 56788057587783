import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class AppSideBar extends Component {
  // constructor(props) {
  //   // console.log(props, 'Sideber Js zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
  //   super(props);
  // }

  render() {
    const currentUser = this.props.currentUser;
    const toggled = this.props.toggled 
    let sidebarClass = toggled ? "" : "collapsed";
   
    // console.log(window.location.pathname, 'sidebar js');
    if(!window.location.pathname.includes('/qr-booking') )
    {
    return (
      <aside className={"sidebar " + sidebarClass}>
        <div className="sidebar__menu-group">
          <ul className="sidebar_nav">
            <li className="menu-collapse-logo m-top-30 m-bottom-85 ml-3">
              <span>
                <a className="navbar-brand logo-size mb-1" href="#!">
                  {/* <img
                    className="svg dark"
                    src={`${window.location.origin}/img/logos/logo_header_mobile.png`}
                    width="100%"
                    alt="svg"
                  />
                 */}
                </a>
                
              </span>
            </li>
            {/* <div className="m-top-30 m-bottom-50 ant-avatar ant-avatar-lg ant-avatar-circle avtar-round-header rounded-circle ml-3">
              <span className="ant-avatar-string avtar-round-hstring">
                Jt
              </span>
            </div> */}
            <li className="menu-title m-top-30 m-bottom-65">
              <span>
                <a className="navbar-brand logo-size" href="#!">
                  <img
                    className="svg dark"
                    src={`${window.location.origin}/img/logos/logo_header_new.png`}
                    width="100%"
                    alt="svg"
                  />
                
                </a>
                
              </span>
            </li>

            <li>
              <NavLink to={"/reservation/map"} activeclassname="active">
                {/* <span data-feather="calendar" className="nav-icon"></span> */}
                <i className="fas fa-calendar nav-icon"></i>
                <span className="menu-text text-capitalize">
                  {this.props.t("sidebar.reservation")}
                </span>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to={"/walk-in"} activeclassname=" active">
                <i className="fas fa-walking nav-icon"></i>
                <span className="menu-text text-capitalize">
                  {this.props.t("sidebar.walkin")}
                </span>
              </NavLink>
            </li> */}
            { currentUser.role_slug === 'admin' || currentUser.role_slug === 'chef' ?
              <li>
                <NavLink to={"/guests"} activeclassname=" active">
                  <i className="fas fa-users nav-icon"></i>
                  {/* <span data-feather="users" className="nav-icon"></span> */}
                  <span className="menu-text text-capitalize">
                    {this.props.t("sidebar.guest")}
                  </span>
                </NavLink>
                {/* 
                <NavLink to={"/rates"} activeclassname=" active">
                  <i className="fas fa-users nav-icon"></i>
                
                  <span className="menu-text text-capitalize">
                    {this.props.t("sidebar.rates")}
                  </span>
                </NavLink> */}
                {/* <a
                  href="/guest"
                  className=" <?= ($page =='guest') ? 'active' : '' ?>"
                >
                  <span data-feather="users" className="nav-icon"></span>
                  <span className="menu-text">Gäste</span>
                </a> */}
              </li>
              : ''
            }
            { currentUser.role_slug === 'admin' ?
            <>
            <li>
              <NavLink to={"/restaurants"} activeclassname=" active">
                <i className="fas fa-hotel nav-icon"></i>
                <span className="menu-text text-capitalize">
                  {this.props.t("sidebar.restaurants")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/users"} activeclassname=" active">
                <i className="fas fa-user-friends nav-icon"></i>
                <span className="menu-text text-capitalize">
                  {this.props.t("sidebar.user")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/logs"} activeclassname=" active">
                <i className="fas fa-database nav-icon"></i>
                {/* <span data-feather="database" className="nav-icon"></span> */}
                <span className="menu-text text-capitalize">
                  {this.props.t("sidebar.logs")}
                </span>
              </NavLink>
            </li>
            <li>
              <a
                href="#pageSubmenu"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              
              >
                <i className="fa fa-cog nav-icon"></i>
                <span className="menu-text text-capitalize">
                {this.props.t("sidebar.settings")}
                </span>
                <i className="ml-auto fas fa-angle-right"></i>
              </a>
              <ul className="collapse list-unstyled" id="pageSubmenu">
                <li>
                  <NavLink to={"/rates"} activeclassname="active">
                    <i className="fas fa-users nav-icon"></i>
                    {/* <span data-feather="users" className="nav-icon"></span> */}
                    <span className="menu-text text-capitalize">
                      {this.props.t("sidebar.rates")}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/configuration"} activeclassname=" active">
                    <i className="fa fa-cog nav-icon"></i>
                    {/* <span data-feather="users" className="nav-icon"></span> */}
                    <span className="menu-text text-capitalize">
                      {this.props.t("sidebar.configuration")}
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            </>
            : ''
          }
          </ul>
        </div>
      </aside>
    );
    }else{
      return (
        <div></div>
      )
    }
  }
}

export default withTranslation()(AppSideBar);
