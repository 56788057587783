import Moment from "moment";
import React from "react";

export function _pre(msg, data) {
  console.log("==== " + msg + "======");
  console.log(data);
  console.log("----------------------");
}

export function _nameInitials(string) {
  return string
    .split(" ")
    .map((n) => n[0])
    .join(".");
}

export function _getformatDate(date, format) {
  const d = new Date(date);
  return Moment(d).format(format);
}


export function _getArrayOfTableOption() {
  return [
    {
      id: "0",
      value: "onlyCancelled",
    },
    {
      id: "2",
      value: "withTable",
    },
    {
      id: "3",
      value: "onlyWaitingList",
    },
    {
      id: "7",
      value: "withoutTable",
    },
    {
      id: "8",
      value: "withAndWithOutTable",
    },
    {
      id: "9",
      value: "withTodayArrival",
    },
    {
      id: "10",
      value: "onlyQRcode",
    },
    {
      id: "11",
      value: "onlyArrived",
    },
  ];
}

export function _arrayOfAlphabet() {
  return [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
}

export function _paginationComponentOptions(rowsPerPage, rangeSeparator) {
  return {
    rowsPerPageText: rowsPerPage,
    rangeSeparatorText: rangeSeparator,
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
}

export function _generateTables(data, type, reservationData) {
  let restaurantTables = "";
  if (data[0] !== undefined) {
    restaurantTables = data[0].list;
  } else {
    restaurantTables = data.list;
  }

  if (restaurantTables) {
    const table = restaurantTables.map((tables) => {
      let clsTableConditionActive =
        tables.status === 1
          ? "table_configuration"
          : tables.status === 2
          ? "table_configuration_inactive"
          : "table_configuration_inherit";

      let ClsConditionWise;
      ClsConditionWise =
        tables.table_shape === 0 && tables.table_size === 0
          ? "small_horizontal"
          : tables.table_shape === 1 && tables.table_size === 0
          ? "small_vertical"
          : tables.table_shape === 2 && tables.table_size === 0
          ? "small_circle"
          : tables.table_shape === 0 && tables.table_size === 1
          ? "medium_horizontal"
          : tables.table_shape === 1 && tables.table_size === 1
          ? "medium_vertical"
          : tables.table_shape === 2 && tables.table_size === 1
          ? "medium_circle"
          : tables.table_shape === 0 && tables.table_size === 2
          ? "big_horizontal"
          : tables.table_shape === 1 && tables.table_size === 2
          ? "big_vertical"
          : tables.table_shape === 2 && tables.table_size === 2
          ? "big_circle"
          : "";

      let ClsSpanConditionWise;
      ClsSpanConditionWise =
        tables.table_size === 0 && tables.table_shape !== 2
          ? "small_span "
          : tables.table_size === 1 && tables.table_shape !== 2
          ? "medium_span "
          : tables.table_size === 2 && tables.table_shape !== 2
          ? "big_span"
          : tables.table_shape === 2 && tables.table_size === 0
          ? "small_circle_span"
          : tables.table_shape === 2 && tables.table_size === 1
          ? "medium_circle_span"
          : tables.table_shape === 2 && tables.table_size === 2
          ? "big_circle_span"
          : "";
      return (
        <div
          key={tables.id}
          className={`${clsTableConditionActive} tisch ${ClsConditionWise}`}
          style={{
            position: "absolute",
            left: `${tables.x_pos}px`,
            top: `${tables.y_pos}px`,
            zIndex: 1,
          }}
        >
          {tables.table_number} <br />
          <span className={`${ClsSpanConditionWise}`}>
            {tables.min_space}-{tables.max_space}{" "}
          </span>
        </div>
      );
    });

    return table;
  }
}

export function _generateTablesForReservation(
  isArrayOfTable,
  data,
  selected,
  type,
  reservationData,
  isCurrentReservationData,
  onTableSelectChange,
  OnNextClick,
  OnPreviousClick
) {

  // console.log(isArrayOfTable, "sddddddddddddd");
  let restaurantTables = "";

  
  
  if (data[0] !== undefined) {
    restaurantTables = data[0].list;
  } else {
    restaurantTables = data.list;
  }

 
  //console.log(restaurantTables);
  if (Object.keys(restaurantTables).length !== 0) {
    let clsTableConditionActive = "";
    let ClsConditionWise = "";
    let isCurrent = "";
    let hasCurrentReservation = false;
    let hasNextReservation = false;
    let hasPreviousReservation = false;
    // let hasNextReservationCount = 0;
    // let hasPreviousReservationCount = 0;

    const table = restaurantTables.map((tables) => {
      //console.log(tables);
      clsTableConditionActive =
        tables.status === 1
          ? "table_configuration"
          : tables.status === 2
          ? "table_configuration_inactive"
          : "table_configuration_inherit";

      ClsConditionWise =
        tables.table_shape === 0 && tables.table_size === 0
          ? "small_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 0
          ? "small_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 0
          ? "small_circle_reservation"
          : tables.table_shape === 0 && tables.table_size === 1
          ? "medium_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 1
          ? "medium_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 1
          ? "medium_circle_reservation"
          : tables.table_shape === 0 && tables.table_size === 2
          ? "big_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 2
          ? "big_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 2
          ? "big_circle_reservation"
          : "";
          let currentReservationData;
          let totalCountNrRes;
          let totalCountPrvRes;
          if(isArrayOfTable.length > 0 ){
            let currentEReservationData = isArrayOfTable.filter((a) =>
                a.isCurrentTableId.includes(tables.id)
          );
          // console.log(currentEReservationData);
            if(currentEReservationData.length > 0 ){
              currentReservationData =  currentEReservationData.length > 0 ? currentEReservationData[0].isCurrentReservationData : "";
              totalCountNrRes = currentEReservationData.length > 0 ? currentEReservationData[0].isNextCount : undefined;
              totalCountPrvRes = currentEReservationData.length > 0 ? currentEReservationData[0].isPreviousCount : undefined ;
            }else{
              currentReservationData = tables.reservation_current;
            }
        }else{
          currentReservationData = tables.reservation_current;
        }

        


        let totalcountPr = Object.keys(tables.reservation_previous).length > 0  ? tables.reservation_previous.length : 0;
        let totalcountNr = Object.keys(tables.reservation_next).length > 0 ? tables.reservation_next.length : 0;
   
      // console.log(Object.keys(tables.reservation_previous).length, Object.keys(tables.reservation_next).length);
        let LastIdPr = Object.keys(tables.reservation_previous).length > 0 ? tables.reservation_previous[totalcountPr - 1].reservation_id : 0
        let LastIdNr = Object.keys(tables.reservation_next).length > 0 ? tables.reservation_next[totalcountNr - 1].reservation_id : 0

        let LastIdCuPr = Object.keys(currentReservationData).length > 0 ? currentReservationData.reservation_id : 0

      //  console.log(totalCountNrRes, totalCountPrvRes, tables.id, tables.table_number, currentReservationData)
        // console.log(totalCountNrRes, totalCountPrvRes, totalcountNr, totalcountPr, LastIdPr, LastIdNr, LastIdCuPr, totalCountPrvRes,totalCountNrRes );
      // console.log(LastIdPr, LastIdNr, LastIdCuPr, isArrayOfTable, ( isArrayOfTable.length > 0 && ((isArrayOfTable[0].isCurrent - 1) > 0)), LastIdCuPr !== LastIdNr && LastIdNr !== 0 ||  ( isArrayOfTable.length > 0 && ((isArrayOfTable[0].isCurrent) < 0)) );
        // console.log(currentReservationData);
        // let arrayPrevious = arrayOfTablePrevious.filter((b) =>
        //      b.isCurrentTableId.includes(tables.id)
        // );
        // console.log(arrayPrevious,arrayNext, "checkTableId" );

        // console.log(arrayNext.length > 0 && arrayNext[0].isCurrent == 1 && arrayNext[0].isCurrentTableId == tables.id )
        // console.log(arrayPrevious.length > 0 && arrayPrevious[0].isCurrent == 2 && arrayPrevious[0].isCurrentTableId == tables.id )
      
        // console.log(selected.includes(`${tables.id}`), selected, 'hhhhhhhhhhhh');
      // let ClsSpanConditionWise;
      // ClsSpanConditionWise = tables.table_size === 0 && tables.table_shape !== 2 ?
      //       ' span_small '
      //       : tables.table_size === 1 && tables.table_shape !== 2 ?
      //       'medium_span '
      //       :tables.table_size === 2 && tables.table_shape !== 2 ?
      //       'big_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 0 ?
      //       'circle_small_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 1 ?
      //       'circle_medium_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 2 ?
      //       'circle_big_span'
      //       :''
      //ClsConditionWise = ClsConditionWise +" " + !tables.reservation_current ? " red " : " ";

      hasCurrentReservation =
        Object.keys(tables.reservation_current).length > 0;
      hasPreviousReservation =
        Object.keys(tables.reservation_previous).length > 0;
      hasNextReservation = Object.keys(tables.reservation_next).length > 0;


  

      // console.log(currentReservationData, "8888");
      // hasNextReservationCount = Object.keys(tables.reservation_next).length;
      // hasPreviousReservationCount = Object.keys(tables.reservation_previous).length;
      //console.log("hasCurrentreservation", hasCurrentReservation);
      isCurrent =
        hasCurrentReservation
          ? " table-red "
          : " ";
      return clsTableConditionActive !== "table_configuration_inactive" ? (
        <div key={tables.id} className="checkbox ">
          <label className="checkbox-wrapper" title={ Object.keys(currentReservationData).length > 0 ? currentReservationData.last_name + " " + currentReservationData.reservation_time.substring(
                            0,
                            5
                          ) + " " +  currentReservationData.total_person + "P"  : ""} >
            <input
              type="checkbox"
              id={tables.id}
              className="checkbox-input"
              onChange={onTableSelectChange}
              checked={selected.includes(`${tables.id}`) ? true : false}
            />
            <span
              className={`checkbox-tile ${ClsConditionWise} ${clsTableConditionActive} ${isCurrent}`}
              style={{
                position: "absolute",
                left: `${tables.x_pos}px`,
                top: `${tables.y_pos}px`,
              }}
            >
              {
                <div
                  className={"_" + hasCurrentReservation}
                  style={
                    hasCurrentReservation ||
                    hasPreviousReservation ||
                    hasNextReservation
                      ? {
                          position: "relative",
                          zIndex: 9999,
                          width: 60,
                          height: 70,
                          cursor: "pointer",
                          textAlign: "left",
                        }
                      : { display: "none" }
                  }
                >
                
                <a href="#!" data-id={tables.id} onClick={(e) => OnPreviousClick(e, currentReservationData, "previous")} style={{ color: "#fff", textAlign: "left" }}>
                    
                  { totalcountPr !== 0 && totalCountPrvRes === undefined || totalCountPrvRes !== undefined &&  totalCountPrvRes > 0 ?
                      <i className="fas fa-chevron-up" />
                      : <span>&nbsp;</span>
                    }
                   
                  </a>
                  



                  <p style={{ margin: 0, fontWeight: 200 }}>
                    
                    { Object.keys(currentReservationData).length > 0 ?
                     currentReservationData.last_name 
                    : tables.reservation_current.last_name ?
                    tables.reservation_current.last_name
                    : <span>&nbsp;</span>
                      } 

                    
                  </p>
                 
                  <span
                    className="checkbox-icon"
                    style={{
                      padding: "1px 0px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                     { hasCurrentReservation ||  Object.keys(currentReservationData).length > 0 ?
                    <a href="#!">
                      <p
                        style={{
                          margin: 0,
                          background: "#ddd",
                          color: "#000",
                          padding: "0px 2px",
                        }}
                        className="font-big"
                      >
                        {  Object.keys(currentReservationData).length > 0 ?

                          currentReservationData.reservation_time.substring(
                            0,
                            5
                          )
                         : tables.reservation_current.reservation_time ?
                          tables.reservation_current.reservation_time.substring(
                            0,
                            5
                          )
                          :"00:00"}
                      </p>
                    </a>
                    : <span>&nbsp;</span>
                    }
                     { hasCurrentReservation ||  Object.keys(currentReservationData).length > 0 ?
                    <a href="#!">
                      <p
                        style={{
                          margin: 0,
                          background: "#5b5b5b",
                          color: "#000",
                          padding: "0px 2px",
                        }}
                        className="font-big"
                      >
                        {   Object.keys(currentReservationData).length > 0 ?
                        currentReservationData.total_person
                          :tables.reservation_current.total_person ? 
                          tables.reservation_current.total_person
                          : <span>&nbsp;</span>
                          }
                      </p>
                    </a>
                     : <span>&nbsp;</span>}
                    {/* <a href="{#!}">
                      {" "}
                      <img
                        src="../img/table.png"
                        style={{ width: 15, height: 15, padding: "0px 2px" }}
                      />
                    </a>
                    <span>2</span> */}
                  </span>
                 
            
                 
                
                  <a href="#!" data-id={tables.id} onClick={(e) => OnPreviousClick(e, currentReservationData, "next")} style={{ color: "#fff" }}>
                  {  totalcountNr !== 0 && totalCountNrRes === undefined ||  totalCountNrRes !== undefined && totalCountNrRes > 0 ?
                      <i className="fas fa-chevron-down " />
                      : <span>&nbsp;</span>
                  }
                  
                  </a>
                 
                    
                </div>
              }
              {/*  <p
                style={{
                  margin: 0,
                  position: "relative",
                  zIndex: 9999,
                }}
                className="font-big-second"
              >
                {tables.table_number}
              </p> */}
            
              <p
                style={
                  hasCurrentReservation ||
                  hasPreviousReservation ||
                  hasNextReservation
                    ? {
                        // margin: 0,
                        //textAlign: "right",
                        //position: "relative",
                        position: "absolute",
                        // top: "10px",
                       
                        zIndex: 9999,
                        //top: "-70px",
                        //right: "-37px",
                      }
                    : {
                        margin: 0,
                        //textAlign: "right",
                        //position: "relative",
                        position: "absolute",
                        // marginTop: "-40px",
                        // marginRight: "-30px",
                        zIndex: 9999,
                        // top: "-70px",
                        //right: "-37px",
                      }
                }
                className="font-big-second"
              >
                {  Object.keys(currentReservationData).length > 0  && tables.id == currentReservationData.table_id && currentReservationData.length_of_day == 1 ?
                <i className="fas fa-lock mr-1"  style={{ fontSize: "10px" }}/>
                  :" "
                }
                {tables.table_number}
              </p>
              { !hasCurrentReservation && !hasNextReservation  && !hasPreviousReservation ? 
              <span   style={{marginTop: "30px"}} >{tables.min_space} - {tables.max_space}</span>
              : ""
          }
            </span>
          </label>
        </div>
      ) : (
        // <div key={tables.id} className="checkbox">
        //   <label className="checkbox-wrapper">
        //     <input
        //       type="checkbox"
        //       className="checkbox-input"
        //       id={tables.id}
        //       onChange={onTableSelectChange}
        //       checked={selected.includes(`${tables.id}`) ? true : false}
        //     />
        //     <span
        //       className={`checkbox-tile ${ClsConditionWise} ${clsTableConditionActive}`}
        //       style={{
        //         position: "absolute",
        //         left: `${tables.x_pos}px`,
        //         top: `${tables.y_pos}px`,
        //       }}
        //     >
        /* <div className="text-center" style={{textAlign: 'center', position: 'relative', background: 'red', zIndex: '9999 !important', width: 80, height: 50, top: 7, bottom: 0, right: 0, left: 0, fontSize: 11, cursor: 'pointer', borderRadius: '100%'}}>
                <a href="#!" style={{color: '#fff'}}>
                  <i className="fas fa-chevron-up " />
                </a>
                <span className="checkbox-icon" style={{padding: '1px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <a href="#!">
                    <p style={{margin: 0, background: '#ddd', color: '#000', padding: '0px 2px'}} className="font-big">
                      19:00</p>
                  </a>
                  <a href="#!">
                    <p style={{margin: 0, background: '#5b5b5b', color: '#000', padding: '0px 2px'}} className="font-big">
                      10</p>
                  </a>
                  <a href="#!"> <img src="table.png" style={{width: 15, height: 15, padding: '0px 2px'}} /></a>
                  <span>2</span>
                </span>
                <a href="#!" style={{color: '#fff'}}>
                  <i className="fas fa-chevron-down" />
                </a>
              </div> */
        /* <span className="checkbox-icon">
                    <p style={{ margin: "0px"}} className="font-big">Test</p>
                </span> */
        //     <span className="checkbox-label">{tables.table_number}</span>
        //   </span>
        // </label>
        // </div>
        ""
      );
    });

    return table;
  }
}

export function _generateTablesForReservation_backup(
  data,
  selected,
  type,
  reservationData,
  onTableSelectChange
) {
  let restaurantTables = "";
  /* console.log(
    "****************************START********************************************"
  );
  console.log(data);
  console.log(
    "****************************END********************************************"
  ); */
  if (data[0] !== undefined) {
    restaurantTables = data[0].list;
  } else {
    restaurantTables = data.list;
  }
  //console.log(restaurantTables);
  if (restaurantTables) {
    const table = restaurantTables.map((tables) => {
      // console.log(tables.id)
      let clsTableConditionActive =
        tables.status === 1
          ? "table_configuration"
          : tables.status === 2
          ? "table_configuration_inactive"
          : "table_configuration_inherit";

      let ClsConditionWise;
      ClsConditionWise =
        tables.table_shape === 0 && tables.table_size === 0
          ? "small_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 0
          ? "small_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 0
          ? "small_circle_reservation"
          : tables.table_shape === 0 && tables.table_size === 1
          ? "medium_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 1
          ? "medium_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 1
          ? "medium_circle_reservation"
          : tables.table_shape === 0 && tables.table_size === 2
          ? "big_horizontal_reservation"
          : tables.table_shape === 1 && tables.table_size === 2
          ? "big_vertical_reservation"
          : tables.table_shape === 2 && tables.table_size === 2
          ? "big_circle_reservation"
          : "";
      // console.log(selected.includes(`${tables.id}`), selected, 'hhhhhhhhhhhh');
      // let ClsSpanConditionWise;
      // ClsSpanConditionWise = tables.table_size === 0 && tables.table_shape !== 2 ?
      //       ' span_small '
      //       : tables.table_size === 1 && tables.table_shape !== 2 ?
      //       'medium_span '
      //       :tables.table_size === 2 && tables.table_shape !== 2 ?
      //       'big_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 0 ?
      //       'circle_small_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 1 ?
      //       'circle_medium_span'
      //       :tables.table_shape === 2 &&  tables.table_size === 2 ?
      //       'circle_big_span'
      //       :''
      ClsConditionWise =
        ClsConditionWise + " " + !tables.reservation_current ? " red " : " ";
      return clsTableConditionActive !== "table_configuration_inactive" ? (
        <div key={tables.id} className="checkbox ">
          <label className="checkbox-wrapper">
            <input
              type="checkbox"
              id={tables.id}
              className="checkbox-input"
              onChange={onTableSelectChange}
              checked={selected.includes(`${tables.id}`) ? true : false}
            />
            <span
              className={`checkbox-tile ${ClsConditionWise} ${clsTableConditionActive}`}
              style={{
                position: "absolute",
                left: `${tables.x_pos}px`,
                top: `${tables.y_pos}px`,
              }}
            >
              <div
                className
                style={{
                  position: "relative",
                  zIndex: 9999,
                  width: 80,
                  height: 70,
                  top: 10,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  fontSize: 11,
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                <a href="#!" style={{ color: "#fff", textAlign: "left" }}>
                  <i className="fas fa-chevron-up " />
                </a>
                <p style={{ margin: 0, fontWeight: 200 }}>Lopez</p>
                <span
                  className="checkbox-icon"
                  style={{
                    padding: "1px 0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href="#!">
                    <p
                      style={{
                        margin: 0,
                        background: "#ddd",
                        color: "#000",
                        padding: "0px 2px",
                      }}
                      className="font-big"
                    >
                      19:00
                    </p>
                  </a>
                  <a href="#!">
                    <p
                      style={{
                        margin: 0,
                        background: "#5b5b5b",
                        color: "#000",
                        padding: "0px 2px",
                      }}
                      className="font-big"
                    >
                      10
                    </p>
                  </a>
                  <a href="#!">
                    {" "}
                    <img
                      alt="Table Map"
                      src="../img/table.png"
                      style={{ width: 15, height: 15, padding: "0px 2px" }}
                    />
                  </a>
                  <span>2</span>
                </span>
                <a href="#!" style={{ color: "#fff" }}>
                  <i className="fas fa-chevron-down" />
                </a>
              </div>
              <p
                style={{
                  margin: 0,
                  textAlign: "right",
                  position: "relative",
                  zIndex: 9999,
                  top: "-70px",
                  right: "-37px",
                }}
                className="font-big-second"
              >
                {tables.table_number}
              </p>
            </span>
          </label>
        </div>
      ) : (
        // <div key={tables.id} className="checkbox">
        //   <label className="checkbox-wrapper">
        //     <input
        //       type="checkbox"
        //       className="checkbox-input"
        //       id={tables.id}
        //       onChange={onTableSelectChange}
        //       checked={selected.includes(`${tables.id}`) ? true : false}
        //     />
        //     <span
        //       className={`checkbox-tile ${ClsConditionWise} ${clsTableConditionActive}`}
        //       style={{
        //         position: "absolute",
        //         left: `${tables.x_pos}px`,
        //         top: `${tables.y_pos}px`,
        //       }}
        //     >
        /* <div className="text-center" style={{textAlign: 'center', position: 'relative', background: 'red', zIndex: '9999 !important', width: 80, height: 50, top: 7, bottom: 0, right: 0, left: 0, fontSize: 11, cursor: 'pointer', borderRadius: '100%'}}>
                <a href="#!" style={{color: '#fff'}}>
                  <i className="fas fa-chevron-up " />
                </a>
                <span className="checkbox-icon" style={{padding: '1px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <a href="#!">
                    <p style={{margin: 0, background: '#ddd', color: '#000', padding: '0px 2px'}} className="font-big">
                      19:00</p>
                  </a>
                  <a href="#!">
                    <p style={{margin: 0, background: '#5b5b5b', color: '#000', padding: '0px 2px'}} className="font-big">
                      10</p>
                  </a>
                  <a href="#!"> <img src="table.png" style={{width: 15, height: 15, padding: '0px 2px'}} /></a>
                  <span>2</span>
                </span>
                <a href="#!" style={{color: '#fff'}}>
                  <i className="fas fa-chevron-down" />
                </a>
              </div> */
        /* <span className="checkbox-icon">
                    <p style={{ margin: "0px"}} className="font-big">Test</p>
                </span> */
        //     <span className="checkbox-label">{tables.table_number}</span>
        //   </span>
        // </label>
        // </div>
        ""
      );
    });

    return table;
  }
}

export function _getDateBlocked(data) {
  // const table = data.close_days.map(tables => {
  //   if(Moment().format('YYYY-MM-DD') > tables.date_from || Moment().format('YYYY-MM-DD') > tables.date_to)
  //   {
  //     console.log(tables)
  //   }else{
  //     console.log(tables)
  //   }
  // });
}
