import axios from "axios";
//import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
//const apiEndpoint = configData.apiUrl + "/tutorial/";
import authHeader from "./auth-header";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/rate";

class RateService {
  //Guest
  /* getAll() {
    //return axios.get(apiEndpoint + "all");
    return axios.get("http://localhost:8080/api/tutorials");
  } */
  getAll(page, perPage) {
    return axios.get(apiEndpoint + `?page=${page}&per_page=${perPage}`, {
      headers: authHeader(),
    });
  }

  getRecord = (id) => {
    return axios.get(apiEndpoint + `/${id}`, {
      headers: authHeader(),
    });
  };


  create = (data) => {
    return axios.post(apiEndpoint, data, {
      headers: authHeader(),
    });
  };

  update = (id, data) => {
    return axios.put(apiEndpoint + `/${id}`, data, {
      headers: authHeader(),
    });
  };


  remove = (id) => {
    return axios.delete(apiEndpoint + `/${id}`, {
      headers: authHeader(),
    });
  };

  removeAll = () => {
    return axios.delete(apiEndpoint);
  };

  findByTitle = (title) => {
    return axios.get(apiEndpoint + `?page=&per_page=&keyword=${title}`, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint + `?title=${title}`);
  };
}

export default new RateService();
