import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/reservation";

class ReservationSettingService {

    getAll(restaurant_id, date) {
        // reservation?per_page=&page=&keyword=dan&start_date=&end_date=&status=&restaurant_id=1&shift_id=
        return axios.get(apiEndpoint + `/restaurant-hours?restaurant_id=${restaurant_id}&start_date=${date}`, {
          headers: authHeader(),
        });
        //return axios.get(apiEndpoint, { headers: authHeader() } );
      }

      getAllExternal(data) {
        // reservation?per_page=&page=&keyword=dan&start_date=&end_date=&status=&restaurant_id=1&shift_id=
        return axios.post(apiEndpoint + `-external/restaurant-hours`, data);
        //return axios.get(apiEndpoint, { headers: authHeader() } );
      }
      // getRecord(id) {
      //   return axios.get(apiEndpoint + `/restaurant-dates?restaurant_id=${id}`, { headers: authHeader() });
      // }
    
}

export default new ReservationSettingService();

