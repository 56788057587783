import {
  CREATE_GUEST,
  RETRIEVE_GUESTS,
  DELETE_GUEST,
  UPDATE_GUEST,
  //RETRIEVE_TUTORIALS,
  //UPDATE_TUTORIAL,
  //DELETE_TUTORIAL,
  DELETE_ALL_TUTORIALS,
  SET_MESSAGE,
} from "./types";
import CommonHelper from "../utils/CommonHelper";

import GuestDataService from "../services/guest-user.service";
// import { _pre } from "../utils/vlogs";

export const createGuest = (formData) => async (dispatch) => {

  try {
    const res = await GuestDataService.create(formData);
    // console.log('in =create guest');
    dispatch({
      type: CREATE_GUEST,
      payload: res.data,
    });

    /* const resMessage = {
      message: res.data.message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    }); */
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of create guest');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    return Promise.reject();
  }
};

export const retrieveGuests = (page, perPage) => async (dispatch) => {
  try {
    const res = await GuestDataService.getAll(page, perPage);
    // console.log("ACTION TU : retrieveGuests " + page);
    // console.log(res.data.data);
    dispatch({
      type: RETRIEVE_GUESTS,
      payload: res.data.data,
    });
  } catch (err) {
    // console.log(err);
  }
};


export const retrieveGuestsQr = () => async (dispatch) => {
  try {
    const res = await GuestDataService.getAllQR();
    // console.log("ACTION TU : retrieveGuests " + page);
    // console.log(res.data.data);
    dispatch({
      type: RETRIEVE_GUESTS,
      payload: res.data.data,
    });
  } catch (err) {
    // console.log(err);
  }
};

export const getGuest = (id) => async (dispatch) => {
  try {
    const res = await GuestDataService.getRecord(id);

    dispatch({
      type: UPDATE_GUEST,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateGuest = (id, data) => async (dispatch) => {
  try {
    const res = await GuestDataService.update(id, data);

    dispatch({
      type: UPDATE_GUEST,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of update guest');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    return Promise.reject();
  }
};

export const deleteGuest = (id) => async (dispatch) => {
  try {
    const res = await GuestDataService.remove(id);
    dispatch({
      type: DELETE_GUEST,
      //payload: { id },
      payload: res.data,
    });
    // _pre(" test ", res.data);
    return Promise.resolve(res.data);
  } catch (error) {
    // console.log('in error of delete guest');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    // console.log(error);
  }
};

export const deleteAllGuests = () => async (dispatch) => {
  try {
    const res = await GuestDataService.removeAll();

    dispatch({
      type: DELETE_ALL_TUTORIALS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findGuestByKeyword = (page, perPage, title) => async (dispatch) => {
  try {
    const res = await GuestDataService.findByTitle(page, perPage, title);

    dispatch({
      type: RETRIEVE_GUESTS,
      payload: res.data.data,
    });
  } catch (error) {
    // console.log('in error of find Guest');

    const message =
    (error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString();

  // _pre("  action auth-user.js -> updatePassword -> error ", error);
  // dispatch({
  //   type: UPDATE_PASSWORD_FAIL,
  // });
  const resMessage = {
    message: message,
    type: CommonHelper.getMsgType("E"),
  };
  dispatch({
    type: SET_MESSAGE,
    payload: resMessage, //message,
  });
    // console.log(error);
  }
};
