import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
// import { _pre } from "../../utils/vlogs";
// import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import Cookie from 'universal-cookie';


class QrBookingStep5 extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
        code: "",
      password: "",
      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const cookies = new Cookie();
      // this.getRecord(parseInt(this.props.match.params.id.substring(4)));
      let status = cookies.get('is_verified');
      let step = cookies.get('step');

      cookies.remove('is_verified');
      cookies.remove('step');
      if(status){
        this.props.history.push("/qr-booking/step" + step + "/tid=" +parseInt(this.props.match.params.id.substring(4)));
      }
    }
    //
  }

  render() {
    // const { message } = this.props;
   
    return (
      <div className="signUP-admin1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 card my-3">
              <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                    <div className="mt-0">
                      <div className="card border-0">
                        <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                          <div className="edit-profile__title">
                            <h6>
                            {this.props.t('qrcodeBooking.step5title')}{" "}
                             
                            </h6>
                          </div>
                        </div>
                        <div className="card-body">
                            <p>{this.props.t('qrcodeBooking.addformstep5.lablesuccessMsg')}</p>
                            <br></br>  
                            
                        
                        </div>
                        
                        </div>
                      </div>
                    {/* <p className="social-connector text-right mb-sm-25 mb-15  mt-sm-30 mt-20">
                        <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);
export default compose(withTranslation() ,connect(mapStateToProps, {
//   createRate,
//   updateRate,
//   getRate
  //deleteAllTutorials,
}))(QrBookingStep5);

