import {
    CREATE_CODE,
    VERIFY_CODE,
    CREATE_RESERVATION_QR,
    // DELETE_CODE,
    // UPDATE_CODE,
    //RETRIEVE_TUTORIALS,
    // UPDATE_TUTORIAL,
    //DELETE_TUTORIAL,
    // DELETE_ALL_TUTORIALS,
    //SET_MESSAGE,
  } from "./types";
  //import CommonHelper from "../utils/CommonHelper";
  
  import QRDataService from "../services/qr-booking.service";
  // import { _pre } from "../utils/vlogs";
  
  export const createCode = (formData) => async (dispatch) => {
    try {
      const res = await QRDataService.create(formData);
  
      dispatch({
        type: CREATE_CODE,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };


  export const getRestaurantByTableId = (formData) => async (dispatch) => {
    try {
      const res = await QRDataService.getRestaurantByTableId(formData);
  
      // dispatch({
      //   type: CREATE_CODE,
      //   payload: res.data,
      // });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };


  export const verifyCode = (formData) => async (dispatch) => {
    try {
      const res = await QRDataService.verifyCode(formData);
  
      dispatch({
        type: CREATE_CODE,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  export const createReservationByQr = (formData) => async (dispatch) => {
    try {
      const res = await QRDataService.createReservationByQr(formData);
  
      dispatch({
        type: CREATE_RESERVATION_QR,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  
  