import {
    // CREATE_CONFIGURATION,
    RETRIEVE_CONFIGURATION,
    // DELETE_CONFIGURATION,
    // UPDATE_CONFIGURATION,
    
    // SET_MESSAGE,
  } from "./types";
  // import CommonHelper from "../utils/CommonHelper";
  
  import ConfigurationDataService from "../services/configuration.service";
  // import { _pre } from "../utils/vlogs";
  
  
  export const retrieveConfigurations = (lang) => async (dispatch) => {
    try {
      const res = await ConfigurationDataService.getAll(lang);
     
      dispatch({
        type: RETRIEVE_CONFIGURATION,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };


  export const retrieveConfigurationsExternal = (data) => async (dispatch) => {
    try {
      const res = await ConfigurationDataService.getAllByResturantClientId(data);
     
      dispatch({
        type: RETRIEVE_CONFIGURATION,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };
  

  

  