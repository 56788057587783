import {
    CREATE_RATE,
    RETRIEVE_RATES,
    DELETE_RATE,
    UPDATE_RATE,
    //RETRIEVE_TUTORIALS,
    // UPDATE_TUTORIAL,
    //DELETE_TUTORIAL,
    DELETE_ALL_TUTORIALS,
    //SET_MESSAGE,
  } from "./types";
  //import CommonHelper from "../utils/CommonHelper";
  
  import RateDataService from "../services/RateService";
  // import { _pre } from "../utils/vlogs";
  
  export const createRate = (formData) => async (dispatch) => {
    try {
      const res = await RateDataService.create(formData);
  
      dispatch({
        type: CREATE_RATE,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const retrieveRates = (page, perPage) => async (dispatch) => {
    try {
      const res = await RateDataService.getAll(page, perPage);
      // console.log("ACTION TU : retrieveGuests " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_RATES,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };
  
  
export const getRate = (id) => async (dispatch) => {
  try {
    const res = await RateDataService.getRecord(id);

    dispatch({
      type: UPDATE_RATE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

  export const updateRate = (id, data) => async (dispatch) => {
    try {
      const res = await RateDataService.update(id, data);
  
      dispatch({
        type: UPDATE_RATE,
        payload: data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const deleteRate = (id) => async (dispatch) => {
    // alert("ininn DELERE");
    try {
      const res = await RateDataService.remove(id);
      dispatch({
        type: DELETE_RATE,
        //payload: { id },
        payload: res.data,
      });
      // _pre(" test ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // console.log(err);
    }
  };
  
  export const deleteAllRates = () => async (dispatch) => {
    try {
      const res = await RateDataService.removeAll();
  
      dispatch({
        type: DELETE_ALL_TUTORIALS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const findRateByKeyword = (title) => async (dispatch) => {
    try {
      const res = await RateDataService.findByTitle(title);
  
      dispatch({
        type: RETRIEVE_RATES,
        payload: res.data.data,
      });
    } catch (err) {
      // console.log(err);
    }
  };
  