import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class page_404 extends Component {
  
  render() {
    return (
        <div className="contents">
        <div className="container-fluid">
            <div className="row justify-content-center">
            <div className="col-12">
                {/* Start: error page */}
                <div className="min-vh-100 content-center">
                <div className="error-page text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="401.403" height="316.25" viewBox="0 0 401.403 316.25" className="svg replaced-svg">
                    <defs>
                        <linearGradient id="linear-gradient" x1="1.148" y1="-0.041" x2="1.942" y2="-0.041" gradientUnits="objectBoundingBox">
                        <stop offset={0} stopColor="#343a44" />
                        <stop offset="0.301" stopColor="#232c3d" />
                        <stop offset="0.639" stopColor="#172339" />
                        <stop offset={1} stopColor="#142138" />
                        </linearGradient>
                        <linearGradient id="linear-gradient-2" x1={0} y1="0.5" x2={1} y2="0.5" xlinkHref="#linear-gradient" />
                        <linearGradient id="linear-gradient-3" x1="-6.635" y1="-0.966" x2="-5.313" y2="-0.966" xlinkHref="#linear-gradient" />
                    </defs>
                    <g id="Group_1622" data-name="Group 1622" transform="translate(-249.412 -77.426)">
                        <g id="Group_1609" data-name="Group 1609" transform="translate(404.785 206.876)">
                        <path id="Path_1012" data-name="Path 1012" d="M548.348,301.649l35.2,76.08,3.967-.013-30.437-81.009Z" transform="translate(-383.522 -191.279)" fill="#dca86e" />
                        <path id="Path_1013" data-name="Path 1013" d="M516.958,302.951l-35.2,74.454-3.967-.013,30.437-76.131Z" transform="translate(-388.54 -190.955)" fill="#dca86e" />
                        <path id="Path_1014" data-name="Path 1014" d="M433.449,357.718l-17.891,15.656L394.467,358.53s7.179-2.408,19.487.871l12.6-10.586Z" transform="translate(-394.467 -187.572)" fill="url(#linear-gradient)" />
                        <path id="Path_1015" data-name="Path 1015" d="M535.184,276.507s-39.541,20.268-53.51,26.332-61.5,53.656-61.5,53.656,6.084,12.39,9.127,10.213c26.835-19.186,51.557-36.353,67.444-42.343,31.458-11.862,40.225-14.44,48.284-23.779C545.033,300.586,540.306,289.363,535.184,276.507Z" transform="translate(-392.638 -192.716)" fill="#1e32c6" />
                        <g id="Group_1606" data-name="Group 1606" transform="translate(49.996 78.646)">
                            <path id="Path_1016" data-name="Path 1016" d="M472.086,349.872l-5.156,23.207-25.786.515s4.363-6.188,16.224-10.83l3.889-15.987Z" transform="translate(-441.143 -266.363)" fill="url(#linear-gradient-2)" />
                            <path id="Path_1017" data-name="Path 1017" d="M533.375,271.7s-36.78,3.967-50.189,11.188S459.1,356.322,459.1,356.322s10.414,5.653,11.7,2.142a482.142,482.142,0,0,1,21.94-50.5c13.215-2.414,43.824,12.483,65.948-9.308C558.7,298.652,539.563,284.08,533.375,271.7Z" transform="translate(-439.865 -271.703)" fill="#3644cc" />
                        </g>
                        <g id="Group_1607" data-name="Group 1607" transform="translate(187.425)">
                            <path id="Path_1018" data-name="Path 1018" d="M588.9,230.663a49.018,49.018,0,0,0-4.33,9.9l-15.129-1.4s7.811-11.835,7.213-19.013Z" transform="translate(-569.446 -196.724)" fill="#fec4ad" />
                            <path id="Path_1019" data-name="Path 1019" d="M585.47,207.275c-.858-.228-4.092-1.118-4.746-.852-.907.37.148,1.56-.491,2.286-1.034,1.176-3.1,1.2-4.437,1.915a8.824,8.824,0,0,0-3.587,3.3c-1.232,2.165,3.255,7,3.957,10.618,1.47,7.575,11.044,8.7,18.824,6.376,2.781-.829,5.34-3.33,6.855-5.756,1.782-2.851.778-7.618-.715-10.456a13.274,13.274,0,0,0-4.217-4.24c-1.4-.966-1.791-4.989-3.48-5.274C593.432,205.2,587.4,207.788,585.47,207.275Z" transform="translate(-569.265 -197.788)" fill="#fec4ad" />
                            <path id="Path_1020" data-name="Path 1020" d="M606.218,228.1c.017.015-.26-1.27,1.933-3.337,7.258-6.834-.506-20.2-8.414-22.529-4.047-1.191-6.632.064-8.085-3.957.1.288-1.636,1.026-1.807,1.172a3.134,3.134,0,0,0-1.432,2.7c-.134,5.842,5.094,11.281,9.05,14.96,1.779,1.655-2.6.513-4.363.85-1.149.221-4.129,3.368-4.769,3.844,1.658,2.065,3.135.2,5.373,1.2,2.066.922.757,3.552-1.17,3.864a4.047,4.047,0,0,1-4.2-1.486c-1.368.742-1.586,5.172-2.661,6.333,1.482.814,10.131,2.334,12.161,2.31C602.169,233.97,604.826,231.573,606.218,228.1Z" transform="translate(-568.292 -198.28)" fill="url(#linear-gradient-3)" />
                        </g>
                        <path id="Path_1021" data-name="Path 1021" d="M591.334,232.309c3.172,35.38-37.567,69.3-37.567,69.3s-26.6,1-25.322-26.949c0,0,2.069-5.082,18.537-20.005,11-9.971,18.731-24.851,26.473-24.555C594.6,230.911,591.334,232.309,591.334,232.309Z" transform="translate(-384.94 -196.017)" fill="#ee4093" />
                        <path id="Path_1022" data-name="Path 1022" d="M494.91,303.569s24.533,9.151,57.231-3.252S593.757,230,593.757,230l3.741,1.9s-3.642,63.908-46.692,77.8c-32.91,10.617-55.9-2.028-55.9-2.028Z" transform="translate(-387.322 -196.024)" fill="url(#linear-gradient-2)" />
                        <g id="Group_1608" data-name="Group 1608" transform="translate(192.573 36.046)">
                            <path id="Path_1023" data-name="Path 1023" d="M583.481,265.745s3.917,13.574,4.135,20.341-2.674,29.737-2.674,29.737l6.231.789s5.538-27.379,5.668-36.315-5.023-23.41-5.023-23.41Z" transform="translate(-573.596 -230.157)" fill="#f8d2a1" />
                            <path id="Path_1024" data-name="Path 1024" d="M583.747,268.093c4.524,19.768,2.649,46.261,2.649,46.261l6.762.431c7.413-15.507,6.719-49.866,2.8-64.322-2.3-8.493-3.66-17.057-9.407-18.377-3.593-.826-10.982,1.8-11.973,5.355C572.43,245.145,581.528,258.4,583.747,268.093Z" transform="translate(-574.252 -231.932)" fill="#ea268c" />
                            <path id="Path_1025" data-name="Path 1025" d="M582.354,315.488c-1.492,2.843-2.168,4.925-1.29,5.643.607.495,1.582-2.264,2.761-3.249a8.531,8.531,0,0,1-.343,5.1c-.832,2.049-.759,3.8.8,3.951s4.09.048,4.652-1.242,1.843-6.671,2.535-8.462-.226-3.849.037-4.875-4.6-2.742-4.6-2.742c-.267.412-1.764,2.455-1.764,2.455a15.769,15.769,0,0,1-1.7,1.787A6.017,6.017,0,0,0,582.354,315.488Z" transform="translate(-573.795 -226.407)" fill="#f8d2a1" />
                        </g>
                        </g>
                        <g id="Group_1610" data-name="Group 1610" transform="translate(335.291 77.426)">
                        <line id="Line_66" data-name="Line 66" y2="47.128" transform="translate(39.029)" fill="none" stroke="#d4dbe2" strokeMiterlimit={10} strokeWidth="0.25" />
                        <path id="Path_1026" data-name="Path 1026" d="M374.912,138.938a8.266,8.266,0,1,1-7.6-8.878A8.266,8.266,0,0,1,374.912,138.938Z" transform="translate(-327.538 -73.684)" fill="#ffba01" />
                        <path id="Path_1027" data-name="Path 1027" d="M407.645,142.679H329.588l34.162-24.858h9.734Z" transform="translate(-329.588 -74.553)" fill="#d2dceb" />
                        </g>
                        <line id="Line_67" data-name="Line 67" x2="401.403" transform="translate(249.412 393.426)" fill="none" stroke="#a6aeba" strokeMiterlimit={10} strokeWidth="0.5" />
                        <g id="Group_1621" data-name="Group 1621" transform="translate(262.426 198.176)">
                        <g id="Group_1614" data-name="Group 1614" transform="translate(30.517 3.448)">
                            <g id="Group_1611" data-name="Group 1611" transform="translate(11.675 2.531)">
                            <path id="Path_1028" data-name="Path 1028" d="M306.988,220.054a32.9,32.9,0,0,1,4.638-7.623c2.992-3.591,7.452-9.572,7.452-9.572s-2.175,4.257-5.129,9.066A36.772,36.772,0,0,1,306.988,220.054Z" transform="translate(-300.522 -195.233)" fill="#50cc85" />
                            <path id="Path_1029" data-name="Path 1029" d="M309.876,213.552a19.058,19.058,0,0,0,5.3-8.111c1.989-5.509,3.8-9.7,3.8-9.7a77.845,77.845,0,0,0-6.143,10.4C310.989,210.4,309.876,213.552,309.876,213.552Z" transform="translate(-300.316 -195.739)" fill="#50cc85" />
                            <path id="Path_1030" data-name="Path 1030" d="M307.241,219.48a69.466,69.466,0,0,0,2.073-11.329c.341-5.029.3-8.188.3-8.188s-2.185,9.125-2.844,13A12.548,12.548,0,0,0,307.241,219.48Z" transform="translate(-300.552 -195.439)" fill="#50cc85" />
                            <path id="Path_1031" data-name="Path 1031" d="M306.046,223.536a36.617,36.617,0,0,0-1.269-12.293,38.585,38.585,0,0,1-1.923-9.49,39.149,39.149,0,0,0,.164,11.316A74.968,74.968,0,0,0,306.046,223.536Z" transform="translate(-300.841 -195.311)" fill="#50cc85" />
                            <path id="Path_1032" data-name="Path 1032" d="M306.313,221.315s4.609-3.936,6.939-4.862a27.686,27.686,0,0,0,5.574-3.625s-4,5.126-6.24,6.143S306.313,221.315,306.313,221.315Z" transform="translate(-300.57 -194.523)" fill="#50cc85" />
                            <path id="Path_1033" data-name="Path 1033" d="M305.1,226.96a18.594,18.594,0,0,0-1.613-8.564,44.955,44.955,0,0,1-2.536-7.5s-.122,6.817,1.233,9.375S305.1,226.96,305.1,226.96Z" transform="translate(-300.951 -194.661)" fill="#50cc85" />
                            <path id="Path_1034" data-name="Path 1034" d="M305.23,223.672a14.877,14.877,0,0,1,5.2-2.126,12.529,12.529,0,0,0,3.235-.706,6.051,6.051,0,0,1-4.441,2.758C306.077,224,305.23,223.672,305.23,223.672Z" transform="translate(-300.647 -193.954)" fill="#50cc85" />
                            <path id="Path_1035" data-name="Path 1035" d="M303.612,230.351c.572-2.228,1.31-5.672,2.069-7.973a70.414,70.414,0,0,1,5.665-11.624c-2.436,5.4-4.215,8.442-5.44,13.1-.485,1.848-1.324,4.411-1.778,6.086C304.054,230.4,303.687,230.784,303.612,230.351Z" transform="translate(-300.762 -194.671)" fill="#50cc85" />
                            </g>
                            <g id="Group_1612" data-name="Group 1612" transform="translate(6.104)">
                            <path id="Path_1036" data-name="Path 1036" d="M305.122,222.034a34.945,34.945,0,0,0-2.025-9.267c-1.764-4.645-4.178-12.2-4.178-12.2s.713,5.031,2.018,10.887A39.087,39.087,0,0,0,305.122,222.034Z" transform="translate(-295.526 -192.865)" fill="#77e6a6" />
                            <path id="Path_1037" data-name="Path 1037" d="M304.269,214.43a20.264,20.264,0,0,1-2.524-9.986c-.093-6.225-.465-11.068-.465-11.068a82.637,82.637,0,0,1,2.576,12.585C304.239,210.88,304.269,214.43,304.269,214.43Z" transform="translate(-295.358 -193.376)" fill="#77e6a6" />
                            <path id="Path_1038" data-name="Path 1038" d="M304.753,221.161a73.774,73.774,0,0,1,1.836-12.1c1.4-5.174,2.528-8.334,2.528-8.334s-.96,9.927-1.638,14.051A13.343,13.343,0,0,1,304.753,221.161Z" transform="translate(-295.111 -192.853)" fill="#77e6a6" />
                            <path id="Path_1039" data-name="Path 1039" d="M304.794,225.589a38.943,38.943,0,0,1,5.528-11.918,41.017,41.017,0,0,0,5.216-8.873,41.62,41.62,0,0,1-4.08,11.318A79.692,79.692,0,0,1,304.794,225.589Z" transform="translate(-295.108 -192.564)" fill="#77e6a6" />
                            <path id="Path_1040" data-name="Path 1040" d="M305.392,223.543s-3.271-5.551-5.292-7.287a29.434,29.434,0,0,1-4.349-5.571s2.252,6.537,4.147,8.332S305.392,223.543,305.392,223.543Z" transform="translate(-295.751 -192.145)" fill="#77e6a6" />
                            <path id="Path_1041" data-name="Path 1041" d="M304.466,229.348a19.778,19.778,0,0,1,4.584-8.05,47.763,47.763,0,0,0,5.145-6.666s-2.234,6.895-4.482,9S304.466,229.348,304.466,229.348Z" transform="translate(-295.131 -191.864)" fill="#77e6a6" />
                            <path id="Path_1042" data-name="Path 1042" d="M305.394,226.194a15.855,15.855,0,0,0-4.494-3.937,13.331,13.331,0,0,1-3.008-1.827,6.432,6.432,0,0,0,3.51,4.308C304.43,226.226,305.394,226.194,305.394,226.194Z" transform="translate(-295.599 -191.452)" fill="#77e6a6" />
                            <path id="Path_1043" data-name="Path 1043" d="M304.662,233.451c.2-2.437.646-6.154.679-8.73a74.856,74.856,0,0,0-1.674-13.644c.579,6.274,1.318,9.943.936,15.052-.151,2.024-.194,4.891-.317,6.731C304.2,233.347,304.437,233.861,304.662,233.451Z" transform="translate(-295.188 -192.117)" fill="#50cc85" />
                            </g>
                            <g id="Group_1613" data-name="Group 1613" transform="translate(0 5.304)">
                            <path id="Path_1044" data-name="Path 1044" d="M302.241,222.644a32.9,32.9,0,0,0-4.638-7.623c-2.993-3.592-7.452-9.572-7.452-9.572s2.175,4.257,5.129,9.066A36.773,36.773,0,0,0,302.241,222.644Z" transform="translate(-290.045 -197.821)" fill="#50cc85" />
                            <path id="Path_1045" data-name="Path 1045" d="M299.154,216.142a19.067,19.067,0,0,1-5.3-8.111c-1.989-5.509-3.8-9.7-3.8-9.7a77.81,77.81,0,0,1,6.143,10.406C298.042,212.994,299.154,216.142,299.154,216.142Z" transform="translate(-290.052 -198.328)" fill="#50cc85" />
                            <path id="Path_1046" data-name="Path 1046" d="M301.388,222.07a69.334,69.334,0,0,1-2.073-11.329c-.341-5.03-.3-8.188-.3-8.188s2.185,9.125,2.844,13A12.548,12.548,0,0,1,301.388,222.07Z" transform="translate(-289.415 -198.027)" fill="#50cc85" />
                            <path id="Path_1047" data-name="Path 1047" d="M302.62,226.126a36.617,36.617,0,0,1,1.269-12.293,38.648,38.648,0,0,0,1.923-9.49,39.149,39.149,0,0,1-.164,11.316A74.963,74.963,0,0,1,302.62,226.126Z" transform="translate(-289.164 -197.9)" fill="#50cc85" />
                            <path id="Path_1048" data-name="Path 1048" d="M302.944,223.9s-4.609-3.936-6.939-4.863a27.649,27.649,0,0,1-5.574-3.624s4,5.125,6.24,6.142S302.944,223.9,302.944,223.9Z" transform="translate(-290.025 -197.112)" fill="#50cc85" />
                            <path id="Path_1049" data-name="Path 1049" d="M303.6,229.55a18.6,18.6,0,0,1,1.613-8.565,44.961,44.961,0,0,0,2.536-7.5s.122,6.817-1.233,9.375S303.6,229.55,303.6,229.55Z" transform="translate(-289.091 -197.25)" fill="#50cc85" />
                            <path id="Path_1050" data-name="Path 1050" d="M303.756,226.261a14.911,14.911,0,0,0-5.2-2.126,12.512,12.512,0,0,1-3.235-.7,6.045,6.045,0,0,0,4.441,2.757C302.909,226.586,303.756,226.261,303.756,226.261Z" transform="translate(-289.677 -196.542)" fill="#50cc85" />
                            <path id="Path_1051" data-name="Path 1051" d="M305.327,232.94c-.572-2.228-1.309-5.672-2.068-7.972a70.365,70.365,0,0,0-5.665-11.624c2.436,5.4,4.215,8.442,5.44,13.1.485,1.848,1.324,4.411,1.778,6.085C304.885,232.989,305.253,233.374,305.327,232.94Z" transform="translate(-289.516 -197.26)" fill="#50cc85" />
                            </g>
                            <path id="Path_1052" data-name="Path 1052" d="M311.364,247.625h-12.5l-3.862-22.011h20.669Z" transform="translate(-289.7 -191.083)" fill="#d2dceb" />
                        </g>
                        <g id="Group_1616" data-name="Group 1616" transform="translate(0 58.505)">
                            <g id="Group_1615" data-name="Group 1615" transform="translate(20.698 7.506)">
                            <path id="Path_1053" data-name="Path 1053" d="M443.572,251.81l20.609,128.863,3.967-.012L451.843,251.784Z" transform="translate(-269.313 -251.784)" fill="#dca86e" />
                            <path id="Path_1054" data-name="Path 1054" d="M313.071,251.864,286.229,380.855l-5.344.013L301.929,251.89Z" transform="translate(-280.885 -251.779)" fill="#dca86e" />
                            </g>
                            <rect id="Rectangle_556" data-name="Rectangle 556" width="221.751" height="9.924" transform="translate(10.632)" fill="#dca86e" />
                            <rect id="Rectangle_557" data-name="Rectangle 557" width="243.015" height="6.588" fill="#f3ba7a" />
                        </g>
                        <g id="Group_1618" data-name="Group 1618" transform="translate(130.293)">
                            <g id="Group_1617" data-name="Group 1617">
                            <path id="Path_1055" data-name="Path 1055" d="M426.139,245.188h-8.625l-8.625-55.031h6.557Z" transform="translate(-381.375 -190.157)" fill="#a6aeba" />
                            <path id="Path_1056" data-name="Path 1056" d="M425.49,245.188H391.827L383.2,190.157h33.663Z" transform="translate(-383.202 -190.157)" fill="#d2dceb" />
                            <rect id="Rectangle_558" data-name="Rectangle 558" width="36.591" height="4.105" transform="translate(8.173 53.784)" fill="#d2dceb" />
                            <rect id="Rectangle_559" data-name="Rectangle 559" width="46.136" height="4.105" transform="translate(44.764 53.784)" fill="#a6aeba" />
                            <path id="Path_1057" data-name="Path 1057" d="M405.888,218.711c.975,4.212.289,8.762-1.531,10.162s-4.086-.88-5.06-5.093-.289-8.763,1.532-10.162S404.914,214.5,405.888,218.711Z" transform="translate(-382.089 -188.518)" fill="#a6aeba" />
                            </g>
                        </g>
                        <g id="Group_1620" data-name="Group 1620" transform="translate(91.091 42.023)">
                            <path id="Path_1058" data-name="Path 1058" d="M357.24,245.063h-4.775a4,4,0,0,1-3.953-3.387L346.6,229.389h16.5l-1.908,12.287A4,4,0,0,1,357.24,245.063Z" transform="translate(-346.604 -229.389)" fill="#a6aeba" />
                            <g id="Group_1619" data-name="Group 1619" transform="translate(11.226 1.69)">
                            <path id="Path_1059" data-name="Path 1059" d="M360.919,242.333c-.174,0-.348-.006-.527-.02l-.224-.017-.178-.137c-.119-.09-2.9-2.272-2.9-5.393,0-3.2,2.123-5.8,4.732-5.8a4.944,4.944,0,0,1,3.842,1.632,5.019,5.019,0,0,1,.88,4.3h0C365.911,240.378,363.875,242.333,360.919,242.333Zm-.179-1.5c3.012.1,3.963-2.214,4.321-4.2h0a3.611,3.611,0,0,0-.556-3.068,3.447,3.447,0,0,0-2.687-1.1c-1.78,0-3.229,1.927-3.229,4.3A6.007,6.007,0,0,0,360.74,240.828Z" transform="translate(-357.085 -230.967)" fill="#a6aeba" />
                            </g>
                        </g>
                        <path id="Path_1060" data-name="Path 1060" d="M345.291,245.275H366.2l3.335-3.692H341.956Z" transform="translate(-255.843 -186.499)" fill="#d2dceb" />
                        </g>
                    </g>
                    </svg>
                    <div className="error-page__title">404</div>
                    <h5 className="fw-500">Sorry! the page you are looking for doesn't exist.</h5>
                    <div className="content-center mt-30">
                    <a href="index.html" className="btn btn-primary btn-default btn-squared px-30">Return Home</a>
                    </div>
                </div>
                </div>
                {/* End: error page */}
            </div>
            </div>
        </div>
        </div>

    );
  }
}
export default withTranslation()(page_404);
