import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/daily-notes";

class NotesService {
  getAll(date, id) {
    return axios.get(apiEndpoint + `/get-daily-note-by-date?note_date=${date}&restaurant_id=${id}`, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }
  

  create(data) {
    return axios.post(apiEndpoint + "", data, { headers: authHeader() });
  }

  uploadpdf(data){
    return axios.post(apiEndpoint + "/upload-pdf", data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(apiEndpoint + `/${id}`, data, { headers: authHeader() });
  }

  
}

export default new NotesService();
