import {
    CREATE_RESTAURANT_TABLE,
    RETRIEVE_RESTAURANT_TABLE,
    DELETE_RESTAURANT_TABLE,
    // UPDATE_RESTAURANT_TABLE,
    //RETRIEVE_TUTORIALS,
    //UPDATE_TUTORIAL,
    //DELETE_TUTORIAL,
    // DELETE_ALL_TUTORIALS,
    SET_MESSAGE,
  } from "./types";
  import CommonHelper from "../utils/CommonHelper";
  
  import RestaurantTableDataService from "../services/restaurant_table.service";
  // import { _pre } from "../utils/vlogs";
  
  export const createRestaurantTable = (formData) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.create(formData);
  
      dispatch({
        type: CREATE_RESTAURANT_TABLE,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {
      // console.log('in error of create restaurant Tables');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
      // console.log(message, 'Tables aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant hour');
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };
  

  export const InsertRestaurantTableMap = (formData) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.insertTableMap(formData);
  
      dispatch({
        type: CREATE_RESTAURANT_TABLE,
        payload: res.data,
      });
  
      /* const resMessage = {
        message: res.data.message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve(res.data);
    } catch (error) {
      // console.log('in error of create restaurant hour');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
      // console.log(message);
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    // console.log(resMessage, 'restaurant hour');
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  export const retrieveRestaurantsTables = (page, perPage, id) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.getAll(page, perPage, id);
      // console.log("ACTION TU : retrieveRestaurants tables " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_RESTAURANT_TABLE,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log('in error of retrive restaurant table');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      // console.log(error);
    }
  };

  export const retrieveRestaurantsTablesExternal = (page, perPage, id, clientId) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.getAllExternal(page, perPage, id, clientId);
      // console.log("ACTION TU : retrieveRestaurants tables " + page);
      // console.log(res.data.data);
      dispatch({
        type: RETRIEVE_RESTAURANT_TABLE,
        payload: res.data.data,
      });
    } catch (error) {
      // console.log('in error of retrive restaurant table');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      // console.log(error);
    }
  };
  
  export const getRestaurantTable = (id) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.getRecord(id);
  
      // dispatch({
      //   type: UPDATE_RESTAURANT_TABLE,
      //   payload: res.data,
      // }); 
  
      return Promise.resolve(res.data);
    } catch (error) {

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  export const getRestaurantTablePdfLink = (id) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.getPdfLink(id);
  
      // dispatch({
      //   type: UPDATE_RESTAURANT_TABLE,
      //   payload: res.data,
      // }); 
  
      return Promise.resolve(res.data);
    } catch (error) {

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };

  

  export const getRestaurantRole = () => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.getRestaurantRole();
  
      dispatch({
        type: CREATE_RESTAURANT_TABLE,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  
  export const updateRestaurantTable = (id, data) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.update(id, data);
  
      // dispatch({
      //   type: UPDATE_RESTAURANT_TABLE,
      //   payload: res.data,
      // });
  
      return Promise.resolve(res.data);
    } catch (error) {
      // console.log('in error of retrive restaurant table');

      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
  
    // _pre("  action auth-user.js -> updatePassword -> error ", error);
    // dispatch({
    //   type: UPDATE_PASSWORD_FAIL,
    // });
    const resMessage = {
      message: message,
      type: CommonHelper.getMsgType("E"),
    };
    dispatch({
      type: SET_MESSAGE,
      payload: resMessage, //message,
    });
      return Promise.reject(error);
    }
  };
  
  export const deleteRestaurantTables = (id) => async (dispatch) => {
    try {
      const res = await RestaurantTableDataService.delete(id);
      dispatch({
        type: DELETE_RESTAURANT_TABLE,
        //payload: { id },
        payload: res.data,
      });
      // _pre(" test ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      // console.log(err);
    }
  };
  
  

  