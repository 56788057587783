import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import "antd/dist/antd.css";
import { Formik } from "formik";
import moment from "moment";
import { Form, Button } from "react-bootstrap";
import { DatePicker } from "antd";
// import { _pre } from "../../utils/vlogs";
// import Message from "../common/message";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { findGuestByKeyword } from "../../actions/guest-user";
import {
  createReservation,
  updateReservations,
  getReservation,
} from "../../actions/reservation";

class Addreservation extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    // console.log("In ADD RES.aaaaaaaaa ", props);
    this.state = {
      table_id: "",
      loading: false,
      showList: false,
      reservation_date: 0,
      reservation_time: 0,
      restaurant_id: "",
      adult: 0,
      ehp: 0,
      children_1: 0,
      k1hp: 0,
      children_2: 0,
      children_3: 0,
      children_4: 0,
      k2hp: 0,
      repeating_day: 1,
      status: props.table_id.length > 0 ? 1 : 3,
      length_of_stay: 0,
      guest_id: 0,
      room_no: "",
      title: "0",
      first_name: "",
      last_name: "",
      zip_code: "",
      phone: "",
      email: "",
      remarks: "",
      save_guest: 0,
      pms_notes: "",
      local_notes: "",
      confirmationEmail:"",
      errors: {},
    };
    this.typingTimer = null;

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.HandleONchecked = this.HandleONchecked.bind(this);
    this.onChangeRepeatingDays = this.onChangeRepeatingDays.bind(this);

    this.handleChangeGuestInfo = this.handleChangeGuestInfo.bind(this);
    this.handleChangePerson = this.handleChangePerson.bind(this);
    this.formResetAfterSubmit = this.formResetAfterSubmit.bind(this);
  }
  async handleChangePerson(event) {
    var name = event.target.name;
    await this.setState({ [name]: event.target.value });
    //await this.setState({ [name]: value });
    /* if (event.target.name === "adult")
      this.setState({ adult: event.target.value });

    if (event.target.name === "children_1")
      this.setState({ children_1: event.target.value });

    if (event.target.name === "children_2")
      this.setState({ children_2: event.target.value });

    if (event.target.name === "children_3")
      this.setState({ children_3: event.target.value });

    if (event.target.name === "children_4")
      this.setState({ children_4: event.target.value }); */
  }
  async handleChangeGuestInfo(event) {
    var name = event.target.name;
    await this.setState({ [name]: event.target.value });
    /* if (event.target.name === "title")
      this.setState({ title: event.target.value });
    if (event.target.name === "first_name")
      this.setState({ first_name: event.target.value });
    if (event.target.name === "last_name")
      this.setState({ last_name: event.target.value });
    if (event.target.name === "email")
      this.setState({ email: event.target.value });
    if (event.target.name === "phone")
      this.setState({ phone: event.target.value });
    if (event.target.name === "zip_code")
      this.setState({ zip_code: event.target.value });

    if (event.target.name === "room_no")
      this.setState({ room_no: event.target.value });

    if (event.target.name === "remarks")
      this.setState({ remarks: event.target.value });
    if (event.target.name === "guest_id")
      this.setState({ guest_id: event.target.value }); */
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
  // console.log('ccc');
    await this.getRecrod();
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (this.props.table_id !== this.state.table_id)
      // console.log("add reservatiuon compodidupdate")
      // console.log(this.props);

      this.getRecrod();
  }

  handleDateChange(date, dateString) {
    this.setState({
      reservation_date: dateString,
    });
  }

  handleNameChange(e) {
    var searchText = e.target.value;

    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      if (searchText.length >= 3) {
        this.setState({
          showList: true,
        });
        this.props.findGuestByKeyword("", "", searchText);
      }else{
        this.setState({
          showList: false,
        });
      }
    }, 200);
  }

  async AutoFillFrom(e, id) {
    // //console.log(this.props.guests.list);
    let filtered = this.props.guests.list.filter((m) => (m.id === id ? m : ""));
    // console.log(filtered);
    // return false;
    await this.setState({
      showList: false,
      title: 0,
      first_name: filtered[0].first_name,
      last_name: filtered[0].last_name,
      phone: filtered[0].phone,
      email: filtered[0].email,
      remarks: filtered[0].remarks,
      guest_id: filtered[0].id,
    });
  }

  handleTimeChange(e) {
    //console.log(e);
    this.setState({
      reservation_time: e.target.value,
    });
  }

  getRecrod() {
    // console.log(this.props.table_id.length  );
    this.setState({
      table_id: this.props.table_id,
      status:this.props.table_id.length > 0 ? 1 : 3,
    });
  }

  //On change repeating days dropdown
  async onChangeRepeatingDays(e) {
    this.setState({
      repeating_day: e.target.value,
    });
  }

  async HandleONchecked(e) {
    if (e.target.name === "length_of_stay") {
      if (e.target.checked) {
        await this.setState({
          length_of_stay: 1,
        });
      } else {
        await this.setState({
          length_of_stay: 0,
        });
      }
    } else if (e.target.name === "ehp") {
      if (e.target.checked) {
        await this.setState({
          ehp: 1,
        });
      } else {
        await this.setState({
          ehp: 0,
        });
      }
    } else if (e.target.name === "save_guest") {
      if (e.target.checked) {
        await this.setState({
          save_guest: 1,
        });
      } else {
        await this.setState({
          save_guest: 0,
        });
      }
    }
    // //console.log(date, dateString);
  }

  async PlusHandle(e, val) {
    let name = e.target.getAttribute("name");
    let value = val;
    if (val !== 10) {
      value = val + 1;
    }
    await this.setState({ [name]: value });
  }

  async MinusHandle(e, val) {
    const name = e.target.getAttribute("name");
    let value = 0;
    if (val !== 0) {
      if(name === "adult" && val === 1){
        value = val;
      }else{
        value = val - 1;
      }
    }
    console.log(value);
    await this.setState({ [name]: value });
  }

  async doSubmit(values) {
    console.log(values);
    //resetForm({ values: "" });
    //console.log(values);
    //this.props.switch();
    //this.props.reservationList();
    //this.props.switch();
    //return true;
    await this.props
      .createReservation(values)
      .then((data) => {
        console.log(data, "sssssssssssssss");
        // //console.log(data.status, data.message);
        if (data.status) {
          toast.success(data.message);
          this.formResetAfterSubmit();
          this.props.reservationList();
        } else {
          toast.error(data.message);
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        //console.log(e);
        this.setState({
          loading: false,
        });
        toast.error(e.response.data.message);
        // console.log(e.response.data,"ddddddddddddddddddddd");
      });
    // }
  }

  async formResetAfterSubmit() {
    document.getElementById("element-text2").value = "";

    console.log(this.props.table_id.length);  
    await this.props.switch();
    await this.setState({
      showList: false,
      title: this.state.title,
      first_name: " ",
      last_name: "",
      zip_code: "",
      phone: "",
      email: "",
      remarks: "",
      guest_id: 0,
      adult:
        this.props.reservationsDateSettings !== undefined
          ? this.props.reservationsDateSettings.default_adult
          : this.state.adult, //this.props.adult,
      ehp: 0,
      children_1: 0,
      status: this.props.table_id.length > 0 ? 1 : 3,
      k1hp: 0,
      children_2: 0,
      children_3: 0,
      children_4: 0,
      k2hp: 0,
      length_of_stay: 0,
      repeating_day: 1,
      save_guest: 0,
      room_no: "",
      pms_notes: "",
      local_notes: "",
    });

    console.log(this.state);
  }

  render() {
    const { guests } = this.props;
    // const format = "HH:mm";
    const dateFormat = "YYYY-MM-DD";
    let dnoneClass = !this.state.showList ? "d-none" : "d-block";

    let child = this.props.childrencategory.split(",");

    let menuItems = [];
    if (
      this.props.restaurantTime !== undefined &&
      this.props.restaurantTime.days_allow !== undefined
    ) {
      for (var i = 2; i <= this.props.restaurantTime.days_allow; i++) {
        menuItems.push(i);
      }
    }

    // console.log(this.props, "addreservation render");

    // console.log();
    //console.log(menuItems);
    return (
      <>
        {/* <div className="form-group mb-20">
          <Message message={message} />
        </div> */}
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.doSubmit(values);
          }}
          initialValues={{
            restaurant_id: this.props.restaurant_id,
            table_id: this.props.table_id,
            guest_id: this.state.guest_id,
            searchText: this.state.searchText,
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            zip_code: this.state.zip_code,
            phone: this.state.phone,
            email: this.state.email,
            remarks: this.state.remarks,
            reservation_date:
              this.state.reservation_date === 0
                ? this.props.selectedDate
                : this.state.reservation_date, //this.state.reservation_date,
            reservation_time:
              this.state.reservation_time === 0
                ? this.props.selectedTime
                : this.state.reservation_time, //this.state.reservation_time,
            adults:
              this.props.reservationsDateSettings !== undefined &&
              this.state.adult === 0
                ? this.props.reservationsDateSettings.default_adult
                : this.state.adult, //this.props.adult,
            ehp: this.state.ehp,
            status: this.props.table_id.length > 0 && this.state.status === 3 ? 1 : this.state.status,
            children_1: this.state.children_1,
            k1hp: this.state.k1hp,
            children_2: this.state.children_2,
            children_3: this.state.children_3,
            children_4: this.state.children_4,
            k2hp: this.state.k2hp,
            length_of_stay: this.state.length_of_stay,
            repeating_day: this.state.repeating_day,
            save_guest: this.state.save_guest,
            room_no: this.state.room_no,
            note_local: this.state.local_notes,
            note: this.state.pms_notes,
            confirmation_email: this.state.confirmationEmail
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row form-group-calender">
                    <div className="col-sm-3 d-flex aling-items-center">
                      <label
                        htmlFor="datepicker8"
                        className=" col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.labledate")}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      {/* {this.props.table_id.length > 0 ? ( */}
                        <DatePicker
                          type="text"
                          name="reservation_date"
                          defaultValue={moment(
                            this.props.selectedDate,
                            dateFormat
                          )}
                          value={moment(values.reservation_date, dateFormat)}
                          onChange={this.handleDateChange}
                          disabledDate={(current) => {
                            return (
                              moment().add(-1, "days") >= current ||
                              moment().add(1, "month") <= current
                            );
                          }}
                          disabled={true}
                          isinvalid={errors.reservation_date ? 1 : 0}
                          size="large"
                        />
                      {/* ) : (
                        <DatePicker
                          type="text"
                          name="reservation_date"
                          value={moment(values.reservation_date, dateFormat)}
                          defaultValue={moment(
                            this.props.selectedDate,
                            dateFormat
                          )}
                          onChange={this.handleDateChange}
                          disabledDate={(current) => {
                            return (
                              moment().add(-1, "days") >= current ||
                              moment().add(1, "month") <= current
                            );
                          }}
                          isinvalid={errors.reservation_date ? 1 : 0}
                          size="large"
                        />
                      )} */}
                      <Form.Control.Feedback type="invalid">
                        {this.props.t(errors.reservation_date)}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row form-group-calender mb-20">
                    <div className="col-sm-3 d-flex aling-items-center">
                      <label
                        htmlFor="#time-picker"
                        className=" col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.lableTime")}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <div className="time-picker">
                        {this.props.restaurantTime !== undefined &&
                        this.props.restaurantTime.days_allow !== undefined ? (
                          <Form.Select
                            name="reservation_time"
                            value={values.reservation_time}
                            onChange={this.handleTimeChange}
                            className="form-control reservation_time"
                            disabled={true}
                          >
                            {this.props.restaurantTime.time_slot.map(
                              (r, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={r.time}
                                    /* selected={
                                      r.time === values.reservation_time
                                        ? "selected"
                                        : ""
                                    } */
                                  >
                                    {r.time}
                                  </option>
                                );
                              }
                            )}
                          </Form.Select>
                        ) : (
                          ""
                        )}
                        {/* <TimePicker
                          type="text"
                          name="reservation_time"
                          value={moment(values.reservation_time, format)}
                          onChange={this.handleTimeChange}
                          isinvalid={errors.reservation_time ? 1 : 0}
                      
                          defaultValue={moment(format)}
                          format={format}
                          size="large"
                        /> */}

                        <Form.Control.Feedback type="invalid">
                          {this.props.t(errors.reservation_time)}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row form-group-calender mb-20">
                    <div className="col-sm-3 d-flex aling-items-center">
                      <label
                        className="col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.lableStatus")}
                      </label>
                    </div>
                    <div className="col-sm-9">
                    { this.props.table_id.length > 0 ? (
                      <Form.Select
                        name="status"
                        className="form-control px-15"
                        id="exampleFormControl"
                        defaultValue={1}
                        value={values.status}
                        onChange={this.handleChangeGuestInfo}
                        isinvalid={errors.status ? 1 : 0}
                      >
                        <option value={1} > {this.props.t("reservation.addForm.status.confirmed")} </option>
                        <option value={2} > {this.props.t("reservation.addForm.status.notConfirmed")}</option>
                        </Form.Select>
                        ) : (
                          <Form.Select
                            name="status"
                            className="form-control px-15"
                            id="exampleFormControl"
                            defaultValue={3}
                            value={this.props.table_id.length === 0 && values.status === 1 ? 3 : values.status}
                            onChange={this.handleChangeGuestInfo}
                            isinvalid={errors.status ? 1 : 0}
                          >
                         <option value={3} > {this.props.t("reservation.addForm.status.waitingList")}</option>
                          <option value={20} > {this.props.t("reservation.addForm.status.notAssigned")}</option>
                        </Form.Select>
                      
                          )
                        }
                        
                      
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-1"></hr>
              <div className="form-group row form-group-calender mb-20">
                <div className="col-sm-3 d-flex aling-items-center">
                  <label
                    htmlFor="#time-picker"
                    className=" col-form-label color-dark fs-14 fw-700 align-center"
                  >
                    {this.props.t("reservation.addForm.lablePerson")}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row form-group-calender">
                    <div className="col-sm-4 d-flex aling-items-center">
                      <label
                        htmlFor="datepicker8"
                        className=" col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.lableAdult")}
                      </label>
                    </div>
                    <div className="col-sm-4">
                      <div className="position-relative">
                        <input
                          type="text"
                          name="adult"
                          value={values.adults}
                          // onChange={this.handleChangePerson}
                          min={0}
                          max={10}
                          isinvalid={errors.adults ? 1 : 0}
                          className="form-control  ih-medium ip-light radius-xs b-light px-15"
                          placeholder="2"
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.props.t(errors.adults)}
                        </Form.Control.Feedback>
                      </div>
                    </div>

                    <div className="col-sm-3 pl-0">
                      <a href="#!" style={{ top: "15px", right: "21px" }}>
                        <i
                          id="info_QR-Code_Icon"
                          onClick={(e) => this.PlusHandle(e, values.adults)}
                          className="la la-angle-up la-2x"
                          name="adult"
                          value="adult"
                          style={{ cursor: "pointer", color: "#9299b8" }}
                        ></i>
                      </a>

                      <hr className="hr-1"></hr>

                      <a href="#!" style={{ bottom: "21px", right: "21px" }}>
                        <i
                          id="info_QR-Code_Icon"
                          onClick={(e) => this.MinusHandle(e, values.adults)}
                          className="la la-angle-down la-2x"
                          name="adult"
                          value="adult"
                          style={{ cursor: "pointer", color: "#9299b8" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row form-group-calender">
                    <div className="col-sm-4">
                      <div className="checkbox-theme-default custom-checkbox ">
                        <input
                          name="ehp"
                          className="checkbox"
                          type="checkbox"
                          id="check-un1"
                          onChange={this.HandleONchecked}
                          isinvalid={errors.ehp ? 1 : 0}
                          checked={this.state.ehp === 1}
                        />
                        <label htmlFor="check-un1">
                          <span className="checkbox-text">HP</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {child.indexOf("0-1") > -1 ? (
                  <div className="col-md-6">
                    <div className="form-group row form-group-calender">
                      <div className="col-sm-4 d-flex aling-items-center">
                        <label
                          htmlFor="datepicker8"
                          className=" col-form-label color-disabled fs-14 fw-500 align-center"
                        >
                          {this.props.t("reservation.addForm.lableBaby")}
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <div className="position-relative">
                          <input
                            type="text"
                            name="children_1"
                            defaultValue={values.children_1}
                            // onChange={this.handleChangePerson}
                            min={0}
                            max={10}
                            isinvalid={errors.children_1 ? 1 : 0}
                            className="form-control  ih-medium ip-light radius-xs b-light px-15"
                            placeholder="2"
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.props.t(errors.children_1)}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0">
                        <a href="#!" style={{ top: "15px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            onClick={(e) =>
                              this.PlusHandle(e, values.children_1)
                            }
                            className="la la-angle-up la-2x"
                            name="children_1"
                            value="children_1"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>

                        <hr className="hr-1"></hr>

                        <a href="#!" style={{ bottom: "21px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            onClick={(e) =>
                              this.MinusHandle(e, values.children_1)
                            }
                            className="la la-angle-down la-2x"
                            name="children_1"
                            value="children_1"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {child.indexOf("2-6") > -1 ? (
                  <div className="col-md-6">
                    <div className="form-group row form-group-calender">
                      <div className="col-sm-4 d-flex aling-items-center">
                        <label
                          htmlFor="datepicker8"
                          className=" col-form-label color-disabled fs-14 fw-500 align-center"
                        >
                          {this.props.t("reservation.addForm.lableBaby3")}
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <div className="position-relative">
                          <input
                            type="text"
                            name="children_2"
                            min={0}
                            max={10}
                            defaultValue={values.children_2}
                            // onChange={this.handleChangePerson}
                            isinvalid={errors.children_2 ? 1 : 0}
                            className="form-control  ih-medium ip-light radius-xs b-light px-15"
                            placeholder="4"
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.props.t(errors.children_2)}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div className="col-sm-3 pl-0">
                        <a href="#!" style={{ top: "15px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            onClick={(e) =>
                              this.PlusHandle(e, values.children_2)
                            }
                            className="la la-angle-up la-2x"
                            name="children_2"
                            value="children_2"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>

                        <hr className="hr-1"></hr>

                        <a href="#!" style={{ bottom: "21px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            onClick={(e) =>
                              this.MinusHandle(e, values.children_2)
                            }
                            className="la la-angle-down la-2x"
                            name="children_2"
                            value="children_2"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {child.indexOf("7-12") > -1 ? (
                  <div className="col-md-6">
                    <div className="form-group row form-group-calender">
                      <div className="col-sm-4 d-flex aling-items-center">
                        <label
                          htmlFor="datepicker8"
                          className=" col-form-label color-dark fs-14 fw-500 align-center"
                        >
                          {this.props.t("reservation.addForm.lableKid")}
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <div className="position-relative">
                          <input
                            type="text"
                            name="children_3"
                            min={0}
                            max={10}
                            defaultValue={values.children_3}
                            // onChange={this.handleChangePerson}
                            isinvalid={errors.children_3 ? 1 : 0}
                            className="form-control  ih-medium ip-light radius-xs b-light px-15"
                            placeholder="7"
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.props.t(errors.children_3)}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-sm-3 pl-0">
                        <a href="#!" style={{ top: "15px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            onClick={(e) =>
                              this.PlusHandle(e, values.children_3)
                            }
                            className="la la-angle-up la-2x"
                            name="children_3"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>

                        <hr className="hr-1"></hr>

                        <a href="#!" style={{ bottom: "21px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            className="la la-angle-down la-2x"
                            onClick={(e) =>
                              this.MinusHandle(e, values.children_3)
                            }
                            name="children_3"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {child.indexOf("13-16") > -1 ? (
                  <div className="col-md-6">
                    <div className="form-group row form-group-calender">
                      <div className="col-sm-4 d-flex aling-items-center">
                        <label
                          htmlFor="datepicker8"
                          className=" col-form-label color-dark fs-14 fw-500 align-center"
                        >
                          {this.props.t("reservation.addForm.lableKid11")}
                        </label>
                      </div>
                      <div className="col-sm-4">
                        <div className="position-relative">
                          <input
                            type="text"
                            name="children_4"
                            min={0}
                            max={10}
                            defaultValue={values.children_4}
                            // onChange={this.handleChangePerson}
                            isinvalid={errors.children_4 ? 1 : 0}
                            className="form-control  ih-medium ip-light radius-xs b-light px-15"
                            placeholder="11"
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.props.t(errors.children_4)}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div className="col-sm-3 pl-0">
                        <a href="#!" style={{ top: "15px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            className="la la-angle-up la-2x"
                            onClick={(e) =>
                              this.PlusHandle(e, values.children_4)
                            }
                            name="children_4"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>

                        <hr className="hr-1"></hr>

                        <a href="#!" style={{ bottom: "21px", right: "21px" }}>
                          <i
                            id="info_QR-Code_Icon"
                            className="la la-angle-down la-2x"
                            onClick={(e) =>
                              this.MinusHandle(e, values.children_4)
                            }
                            name="children_4"
                            style={{ cursor: "pointer", color: "#9299b8" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <hr className="hr-1"></hr>
              <div className="form-group row form-group-calender mb-20">
                <div className="col-sm-3 d-flex aling-items-center">
                  <label
                    htmlFor="#time-picker"
                    className=" col-form-label color-dark fs-14 fw-700 align-center"
                  >
                    {this.props.t("reservation.addForm.lableAppliesTo")}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group row form-group-calender">
                    {/* <div className="col-sm-3 d-flex">
                      <label
                        htmlFor="datepicker8"
                        className=" col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {" "}
                        {this.props.t("reservation.addForm.lableLengthOfStay")}
                      </label>
                    </div> */}
                    <div className="col-sm-9">
                      <div className="position-relative">
                        <div className="checkbox-theme-default custom-checkbox ">
                          <input
                            onChange={this.HandleONchecked}
                            name="length_of_stay"
                            className="checkbox"
                            type="checkbox"
                            id="check-u1"
                            value={values.length_of_stay}
                            isinvalid={errors.length_of_stay ? 1 : 0}
                            checked={this.state.length_of_stay !== 0}
                          />
                          <label htmlFor="check-u1">
                            <span className="checkbox-text text-dark fs-14 fw-500">
                              {this.props.t("reservation.addForm.whole_shift")}
                            </span>
                          </label>
                        </div>

                        <Form.Control.Feedback type="invalid">
                          {this.props.t(errors.length_of_stay)}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                { this.props.table_id.length === 0 ? (
                  <div className="form-group row form-group-calender">
                    <div className="col-sm-3 d-flex">
                      <label
                        htmlFor="datepicker8"
                        className=" col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.lableRepetation")}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <div className="position-relative">
                   
                        <select
                          name="repeating_day"
                          className="form-control px-15"
                          id="exampleFormControl"
                          value={values.repeating_day}
                          onChange={this.onChangeRepeatingDays}
                          isinvalid={errors.repeating_day ? 1 : 0}
                        >
                    
                          <option key={0} value="1">
                            unique
                          </option>
                          {menuItems.length > 0
                            ? menuItems.map((r, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={r}
                                    /* selected={
                                      r === values.repeating_day
                                        ? "selected"
                                        : ""
                                    } */
                                  >
                                    {r} Days
                                  </option>
                                );
                              })
                            : ""}
                          
                        </select>
                         <Form.Control.Feedback type="invalid">
                          {this.props.t(errors.repeating_day)}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </div>
          ) :(
            ""
          )
  }
                </div>
              </div>
              <hr className="hr-1"></hr>
              <div className="sidebar-background-color p-3">
                <div className="form-group row form-group-calender mb-20">
                  <div className="col-sm-3 d-flex aling-items-center">
                    <label
                      htmlFor="#time-picker"
                      className="col-form-label color-dark fs-14 fw-700 align-center"
                    >
                      {this.props.t("reservation.addForm.lableGuestdetails")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="element-text2"
                      onChange={this.handleNameChange}
                      placeholder={this.props.t(
                        "reservation.addForm.lableExistingSearch"
                      )}
                    />
                    {guests.list ? (
                      <div className={"dropdown-list " + dnoneClass}>
                        <div
                          id="searchList"
                          className="dropdown-default dropdown-bottomLeft"
                        >
                          {guests.list.map((r, index) => {
                            return (
                              <a
                                className="dropdown-item"
                                href="#!"
                                key={index}
                                onClick={(e) => this.AutoFillFrom(e, r.id)}
                              >
                                {r.first_name} {r.last_name}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-1">
                    <i
                      id="info_QR-Code_Icon"
                      data-toggle="modal"
                      data-target="#myModal"
                      className="fa fa-info-circle mt-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-3">
                    <select
                      name="title"
                      className="form-control px-15"
                      id="exampleFormControl"
                      value={values.title}
                      onChange={handleChange}
                      isinvalid={errors.title ? 1 : 0}
                    >
                      <option key={0} value="0">
                        {this.props.t("guest.guesttitleOption.mr")}
                      </option>
                      <option key={1} value="1">
                        {this.props.t("guest.guesttitleOption.mrs")}
                      </option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.title)}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="first_name"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="firstname"
                      placeholder={this.props.t(
                        "reservation.addForm.lablefirst"
                      )}
                      value={values.first_name}
                      onChange={this.handleChangeGuestInfo}
                      isinvalid={errors.first_name ? 1 : 0}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.first_name)}
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-sm-6">
                    <input
                      name="last_name"
                      type="text"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="lastname"
                      placeholder={this.props.t(
                        "reservation.addForm.lablelast"
                      )}
                      value={values.last_name}
                      onChange={this.handleChangeGuestInfo}
                      isinvalid={errors.last_name ? 1 : 0}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.last_name)}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="email"
                      placeholder={this.props.t(
                        "reservation.addForm.lableEmail"
                      )}
                      onChange={this.handleChangeGuestInfo}
                      value={values.email}
                      isinvalid={errors.email ? 1 : 0}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.email)}
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="phone"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="phone"
                      placeholder={this.props.t(
                        "reservation.addForm.lablePhone"
                      )}
                      onChange={this.handleChangeGuestInfo}
                      value={values.phone}
                      isinvalid={errors.phone ? 1 : 0}
                    />
                    <Form.Control.Feedback type="invalid">
                      {this.props.t(errors.phone)}
                    </Form.Control.Feedback>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="room_no"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="element-text4"
                      placeholder={this.props.t(
                        "reservation.addForm.lableRoom"
                      )}
                      onChange={this.handleChangeGuestInfo}
                      value={values.room_no}
                      isinvalid={errors.room_no ? 1 : 0}
                    />
                  </div>
                  <div className="col-sm-3">
                    <input
                      name="zip_code"
                      type="text"
                      className="form-control ih-medium ip-light radius-xs b-light px-15"
                      id="element-text3"
                      placeholder={this.props.t(
                        "reservation.addForm.lablezipcode"
                      )}
                      onChange={this.handleChangeGuestInfo}
                      value={values.zip_code}
                      isinvalid={errors.zip_code ? 1 : 0}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-8">
                    <div className="form-group form-element-textarea mb-20">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        name="remarks"
                        rows={3}
                        placeholder={this.props.t(
                          "reservation.addForm.lableGastInfo"
                        )}
                        onChange={this.handleChangeGuestInfo}
                        value={values.remarks}
                        isinvalid={errors.remarks ? 1 : 0}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-8">
                    <div className="checkbox-theme-default custom-checkbox ">
                      <input
                        onChange={this.HandleONchecked}
                        name="save_guest"
                        className="checkbox"
                        type="checkbox"
                        id="check-un4"
                        isinvalid={errors.save_guest ? 1 : 0}
                        checked={this.state.save_guest === 1}
                      />
                      <label htmlFor="check-un4">
                        <span className="checkbox-text fw-700">
                          {this.props.t("reservation.addForm.lableSaveGuest")}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {this.props.confirmationEmail  ?
                <div className="form-group row form-group-calender mb-20">
                    <div className="col-sm-4 d-flex aling-items-center">
                      <label
                        className="col-form-label color-dark fs-14 fw-500 align-center"
                      >
                        {this.props.t("reservation.addForm.lableconfirmation")}
                      </label>
                    </div>
                    <div className="col-sm-3">                   
                        <Form.Select
                          name="confirmation_email"
                          className="form-control px-15"
                          id="exampleFormControl"
                          defaultValue={0}
                          value={values.confirmation_email}
                          onChange={handleChange}
                          isinvalid={errors.confirmation_email ? 1 : 0}
                        >
                        <option value={0} > {this.props.t("reservation.addForm.confirmation.no")} </option>
                        <option value={1} > {this.props.t("reservation.addForm.confirmation.email")} </option>
                        <option value={2} > {this.props.t("reservation.addForm.confirmation.sms")}</option>
                        </Form.Select>
                    </div>
                  </div>
                  :""
                }


                <div className="form-group row form-group-calender mb-20">
                  <div className="col-sm-3 d-flex aling-items-center">
                    <label
                      htmlFor="#time-picker"
                      className="col-form-label color-dark fs-14 fw-700 align-center"
                    >
                      {this.props.t("reservation.addForm.notes")}
                    </label>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label
                      htmlFor="#time-picker"
                      className=" col-form-label color-dark fs-14 fw-500 align-center"
                    >
                      {this.props.t("reservation.addForm.pms_notes")}
                      <br />
                      {this.props.t("reservation.addForm.pms_notes_notice")}
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group form-element-textarea mb-20">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        name="note"
                        rows={3}
                        placeholder={this.props.t(
                          "reservation.addForm.pms_notes"
                        )}
                        onChange={handleChange}
                        value={values.pms_notes}
                        isinvalid={errors.pms_notes ? 1 : 0}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-4">
                    <label
                      htmlFor="#time-picker"
                      className=" col-form-label color-dark fs-14 fw-500 align-center"
                    >
                      {this.props.t("reservation.addForm.local_notes")}
                    </label>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group form-element-textarea mb-20">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        name="note_local"
                        rows={3}
                        placeholder={this.props.t(
                          "reservation.addForm.local_notes"
                        )}
                        onChange={handleChange}
                        value={values.local_notes}
                        isinvalid={errors.local_notes ? 1 : 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row py-3 px-3">
                <div className="col-md-6">
                  <Button
                    className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15"
                    type="submit"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    {this.props.t("button.save")}
                  </Button>
                </div>
                <div className="col-md-6">
                  <a
                    href="#!"
                    className="btn btn-light btn-default btn-squared btdrawer-close text-capitalize float-right lh-normal px-40 py-15 "
                    onClick={this.formResetAfterSubmit}
                  >
                    <span>{this.props.t("button.cancel")}</span>
                  </a>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;
  return {
    user,
    message,
    reservationsDateSettings: state.reservationsDateSettings,
    guests: state.guests,
    table_ids: state.table_id,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    createReservation,
    updateReservations,
    getReservation,
    findGuestByKeyword,
    //deleteAllTutorials,
  })
)(Addreservation);
