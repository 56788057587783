import axios from "axios";
import authHeader from "./auth-header";
import configData from "../config.json";
//const API_URL = "http://localhost:8080/api/test/";
const apiEndpoint = configData.apiUrl + "/restaurant-table";

class RestaurantTableService {
  getAll(page = "", perPage = "", id) {
    return axios.get(apiEndpoint + `?page=${page}&per_page=${perPage}&restaurant_id=${id}`, {
      headers: authHeader(),
    });
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }

  getAllExternal(page = "", perPage = "", id, clientid) {
    return axios.get(apiEndpoint + `-external?page=${page}&per_page=${perPage}&restaurant_id=${id}&client_id=${clientid}`, );
    //return axios.get(apiEndpoint, { headers: authHeader() } );
  }
  getUserApi(page, countPerPage) {
    //return axios.get('https://reqres.in/api/users?page='+page+'&per_page='+countPerPage+'&delay=1' + "", { headers: authHeader() });
    //return axios.get(apiEndpoint, { headers: authHeader() } );

    return axios
      .get(
        "https://reqres.in/api/users?page=" +
          page +
          "&per_page=" +
          countPerPage +
          "&delay=1" +
          "",
        {}
      )
      .then((response) => {
        //console.log(response.data.data);
        if (response.data.data) {
          //localStorage.setItem("user", JSON.stringify(response.data.data));
          return response.data;
        }
      })
      .catch((error) => {
        console.log("Custom Error", error);
      });
  }

  getRecord(id) {
    return axios.get(apiEndpoint + `/${id}`, { headers: authHeader() });
  }

  getPdfLink(id){
    return axios.get(apiEndpoint + `/generate-pdf/${id}`, { headers: authHeader() });
  }

  getUserRole(){
    return axios.get(apiEndpoint + `/roles`, { headers: authHeader() });
  }

  insertTableMap(data){
    return axios.post(apiEndpoint + "/upload-table", data, { headers: authHeader(), "Content-Type": "multipart/form-data",  });
  }

  create(data) {
    return axios.post(apiEndpoint + "", data, { headers: authHeader() });
  }

  update(id, data) {
    return axios.put(apiEndpoint + `/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(apiEndpoint + `/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return axios.delete(apiEndpoint, { headers: authHeader() });
  }

  findByTitle(title) {
    return axios.get(apiEndpoint + `?title=${title}`, {
      headers: authHeader(),
    });
  }
}

export default new RestaurantTableService();