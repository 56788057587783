import { UPDATE_PASSWORD, SET_MESSAGE, UPDATE_PASSWORD_FAIL } from "./types";

import UserService from "../services/user.service";
// import { _pre } from "../utils/vlogs";
import { toast } from "react-toastify";
import CommonHelper from "../utils/CommonHelper";
export const updatePassword = (data) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: "",
  });
  return UserService.updatePassword(data).then(
    (data) => {
      // _pre("in action auth-user.js -> updatePassword then ", data);
      toast.success(data.data.message);
      dispatch({
        type: UPDATE_PASSWORD,
        payload: data.data,
      });

      /* const resMessage = {
        message: data.data.message,
        type: CommonHelper.getMsgType("S"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      }); */
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      // _pre("  action auth-user.js -> updatePassword -> error ", error);
      dispatch({
        type: UPDATE_PASSWORD_FAIL,
      });
      const resMessage = {
        message: message,
        type: CommonHelper.getMsgType("E"),
      };
      dispatch({
        type: SET_MESSAGE,
        payload: resMessage, //message,
      });

      return Promise.reject();
    }
  );
};
