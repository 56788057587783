import {
    CREATE_RESTAURANT,
    RETRIEVE_RESTAURANTS,
    UPDATE_RESTAURANT,
    DELETE_RESTAURANT,
    // DELETE_ALL_RATES,
  } from "../actions/types";
  
  const initialState = [];
  
  function restaurantReducer(restaurants = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_RESTAURANT:
        return [restaurants, payload];
  
      case RETRIEVE_RESTAURANTS:
        return payload;
  
      case UPDATE_RESTAURANT:
        return [];
        // rates.map((rate) => {
        //   if (rate.id === payload.id) {
        //     return {
        //       ...rate,
        //       ...payload,
        //     };
        //   } else {
        //     return rate;
        //   }
        // });
  
      case DELETE_RESTAURANT:
        return [payload]; //return guests.filter(({ id }) => id !== payload.id);
  
    //   case DELETE_ALL_RATES:
    //     return [];
  
      default:
        return restaurants;
    }
  }
  
  export default restaurantReducer;
  
  