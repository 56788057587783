import React, { Component, createRef  } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Formik } from "formik";
import * as yup from "yup";
import CommonHelper from "../../utils/CommonHelper";
import { DatePicker } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { Form, Button, InputGroup, Modal , Row, Accordion, Container, Col} from "react-bootstrap";
import { clearMessage } from "../../actions/message"
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import {
  _generateTablesForReservation,
  _getArrayOfTableOption,
  // _getDateBlocked,
  _getformatDate,
} from "../../utils/vlogs";
import { Oval } from "react-loader-spinner";
import { retrieveRestaurantsTablesExternal } from "../../actions/restaurant-tables";
import { retrieveRestaurantsExternal, getRestaurantExternal } from "../../actions/restaurant";
import { retrieveRestaurantsHours } from "../../actions/restaurant-hours";
import { retrieveConfigurationsExternal } from "../../actions/configuration";
import ReservationCalender from "../../components/reservation/Reservation_calender";
import {
  retrieveReservations,
  addWalkInReservation,
  deleteReservation,
  deleteReservationMultiple,
  retrieveTableReservationsExternal,
  getReservation,
  makeGroupReservation,
  changeStatusReservation,
  getNextPrevreservation,
} from "../../actions/reservation";
import {
  retrieveGuestsQr,
  //deleteAllTutorials,
} from "../../actions/guest-user";
import {
  createNote,
  UpdateNote,
  retriveNotes,
  uploadPdfNotes,
}
from "../../actions/notes";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { getReservationCalenderdatesExternal } from "../../actions/reservation-date-setting";
import { retrieveReservationRestaurantHoursExternal } from "../../actions/reservation-setting";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component"; //{ createTheme }
import Message from "../common/message";
import { compose } from "redux";
import Addreservation from "./add-reservation";
import Updatereservation from "./update-reservation";
import { withTranslation } from "react-i18next";
const ImgSrc = "../../default_table_map.jpg";


class ReservationCopy extends Component {
  constructor(props) {
    super(props);
    // const qsParams = qs.parse(props.location.search);
    this.state = {
      lang:
        localStorage.getItem("lan") === null
          ? "de"
          : localStorage.getItem("lan"),
      table_ids: [],
      list_table_ids: [],
      isCurrent: 0,
      isCurrentTableId: 0,
      restaurant: "",
      isCurrentReservation: 0,
      data: {
        title: "0",
        first_name: "",
        last_name: "",
        zip_code: "",
        phone: "",
        mobile: "",
        email: "",
        remarks: "",
        is_newsletter: "",
      },
      note_text: "",
      isArrayOfTable: [],
      // arrayOfTableNext: [],
      errors: {},
      selectedRestaurantId: 0,
      currentShift: 0,
      currentCategory: "",
      searchKeyword: "",
      attachment_file:"",
      startDate: moment(),
      selectedTime: "",
      endDate: moment(),
      selectedStartDate: moment().format("YYYY-MM-DD"),
      selectedEndDate: "",
      status: null,
      shift_id: 0,
      loading: false,
      loadingTable: false,
      page: 1, //qsParams.page ? qsParams.page : 1,
      perPage: 10,
      open: false,
      openUpdate: false,
      openDeleteModal: false,
      openModalNote: false,
      openModalPdf:false,
      tableMapOpen: true,
      cancel_reason: "",
      initial: "",
      requiredIntial: null,
      tableList: {},
      group_id: "",
      booking_id: "",
      isDateVisible: true,
      isCurrentReservationData: [],
      guestListQr:[],
      buttonText: 'Assign',
      defaultSortingReservation: "",
      defaultSort: "ASC",
      confirmationEmail: false,
    };

  }

  async componentDidMount() {
    // console.log(" Reservation componentDidMount");
    // const query = new URLSearchParams(this.props.location.search);
    //const token = query.get("group_id");
    // console.log("group_id ", token); //123
    await this.fetchConfiguration(this.state.lang);
    let filtered = "";
    let filteredShifts = "";
    let filteredCategory = "";
    let filteredIntial = "";
    let filteredSortingReservation = "";
    let filteredConfirmationReservation = "";
    if (this.props.configurations.list) {
      filtered = this.props.configurations.list.filter((m) =>
        m.slug.includes("STANDARD-RESTAURANT-SELECTION")
      );
      filteredShifts = this.props.configurations.list.filter((m) =>
        m.slug.includes("STANDARD-RESERVATION-STATUS")
      );
      filteredCategory = this.props.configurations.list.filter((m) =>
        m.slug.includes("CHILDREN-CATEGORY")
      );
      filteredIntial = this.props.configurations.list.filter((m) =>
        m.slug.includes("STAFF-INITIALS")
      );
      filteredSortingReservation = this.props.configurations.list.filter((m) =>
        m.slug.includes("DEFAULT-SORTING")
      );

      filteredConfirmationReservation = this.props.configurations.list.filter((m) =>
        m.slug.includes("CONFIRMATION-SMS-EMAIL")
      );
    }
    // console.log(filteredConfirmationReservation, "filteredConfirmationReservation");
    await this.setState({
      selectedRestaurantId: filtered[0].value,
      status: parseInt(filteredShifts[0].value),
      currentCategory: filteredCategory[0].value,
      initial: filteredIntial[0].value,
      requiredIntial: filteredIntial[0].is_enable,
      defaultSortingReservation: filteredSortingReservation[0].value,
      confirmationEmail: filteredConfirmationReservation[0].is_enable ? true : false
      //selectedStartDate :  this.state.startdate.format("YYYY-MM-DD")
    });
    await this.fetchRestaurant();
    await this.fetchRestaurantDateHour();
    // await this.fetchRestaurantHour(); //to get shift name
    await this.fetchRestaurantHourTime();
    await this.fetchRestaurantTable();
    await this.getRecord(filtered[0].value);
    // await this.getDailyNotes(this.state.selectedStartDate, filtered[0].value);
    // await this.getGuestListQR();
    /* 
   
    await this.fetchReservation(
      this.state.page,
      this.state.perPage,
      this.state.startDate
    );
    await this.fetchRestaurantTable("", "", filtered[0].value);
    
    await this.fetchRestaurantHourTime(filtered[0].value, this.state.startDate);

    await this.getRecord(filtered[0].value); */

    //
  }


  
  fetchConfiguration = async (lang) => {
    this.setState({ loading: true });

    let data = {
      restaurant_id:this.props.match.params.restaurantId,
      client_id:this.props.match.params.clientId,
      lang:this.props.match.params.lan,
    }
    await this.props.retrieveConfigurationsExternal(data);
    this.setState({ loading: false });
  };


  fetchRestaurant = async () => {
    let data = {
      restaurant_id:this.props.match.params.restaurantId !== undefined ? this.props.match.params.restaurantId: this.state.selectedRestaurantId,
      client_id:this.props.match.params.clientId,
      lang:this.props.match.params.lan,
    }
    await this.props.retrieveRestaurantsExternal(data);
  };


   //Restaurant wise Date list from selected start Date
   fetchRestaurantDateHour = async () => {
    let data = {
      restaurant_id:this.props.match.params.restaurantId !== undefined ? this.props.match.params.restaurantId: this.state.selectedRestaurantId,
      client_id:this.props.match.params.clientId,
      start_date:this.state.selectedStartDate,
    }
    //alert(this.state.selectedRestaurantId);
    await this.props.getReservationCalenderdatesExternal(
     data
    );
    // this.fetchRestaurantHourTime();
  };

  //Restaurant wise time hours list from selected start Date
  fetchRestaurantHourTime = async () => {

    let data = {
      restaurant_id:this.props.match.params.restaurantId !== undefined ? this.props.match.params.restaurantId: this.state.selectedRestaurantId,
      client_id:this.props.match.params.clientId,
      start_date:this.state.selectedStartDate,
    }
    //let selectedDate = format(new Date(), "yyyy/MM/dd");
    await this.props.retrieveReservationRestaurantHoursExternal(
      data
    );
    // await this.fetchReservation(this.state.page, this.state.perPage);
    // await this.getRecord(this.state.selectedRestaurantId);
    // await this.fetchRestaurantTable();

    if(parseInt(this.props.match.params.restaurantId) !== 0){
      // console.log('ssssssssssss');
    await this.fetchTableReservation();
    }
    // await this.getDailyNotes(this.state.selectedStartDate, this.state.selectedRestaurantId);

    // //check for restaurant timing list in time exist if exist then set standard time other first slot of time
    // let filtered = [];
    // let isResSettingDataAvail = Object.keys(
    //   this.props.reservationsSettings
    // ).length;

    // if (isResSettingDataAvail > 0) {
    //   filtered = this.props.reservationsSettings.time_slot.filter((m) =>
    //     m.time.includes(this.props.reservationsSettings.standard_time)
    //   );
    // }

    // // console.log("reservationsSettings ", this.props.reservationsSettings);

    // let timeSlot = "";
    // let isExistTImeSlot = "";
    // if (isResSettingDataAvail > 0) {
    //   if (this.props.reservationsSettings.time_slot.length > 0) {
    //     timeSlot = this.props.reservationsSettings.time_slot[0].time;
    //   }
    //   isExistTImeSlot = this.props.reservationsSettings.time_slot.filter(
    //     (i) => i.time >  moment.tz("Europe/Berlin").format("HH:mm:ss")
    //   );
    // }

    // // console.log(isExistTImeSlot);
    // if (isExistTImeSlot.length > 0) {
    //   timeSlot = isExistTImeSlot[0].time;
    // } else {
    //   //timeSlot = this.props.reservationsSettings.standard_time + ":00";
    //   timeSlot =
    //     filtered.length > 0
    //       ? this.props.reservationsSettings.standard_time + ":00"
    //       : timeSlot;
    // }

    // this.setState({ selectedTime: timeSlot }, function () {
    //   // console.log(this.state.selectedTime);
    // });
  }


  fetchRestaurantTable = async (page, perPage) => {
    this.setState({ loading: true });
    if(this.state.selectedRestaurantId !== 0){
    await this.props.retrieveRestaurantsTablesExternal(
      "",
      "",
      this.state.selectedRestaurantId,
      this.props.match.params.clientId,
    );
    }
    this.setState({ loading: false });
  };
 

  async getRecord(id) {
    let data = {
      restaurant_id:this.props.match.params.restaurantId !== undefined ? this.props.match.params.restaurantId: this.state.selectedRestaurantId,
      client_id:this.props.match.params.clientId,
    }
    this.setState({
      loading: true,
    });
    this.props
      .getRestaurantExternal(data)
      .then((data) => {
        if (data.status) {
          const { detail: currentRecord } = data.data;
          this.setState({
            restaurant: currentRecord,
          });
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // //console.log(e);
        this.setState({
          loading: false,
        });
      });
  }


  fetchTableReservation = async (data = null) => {
    //alert(" in fetchReservation ");
   

    data = {
      page: 1,
      perPage: 10,
      keyword: this.state.searchKeyword,
      start_date: this.state.startDate.format("YYYY-MM-DD"),
      end_date: this.state.startDate.format("YYYY-MM-DD"),
      time:
        this.state.selectedTime !== ""
          ? this.state.selectedTime
          : this.props.reservationsSettings.standard_time + ":00",
      status: this.state.status,
      restaurant_id: this.props.match.params.restaurantId !== undefined ? this.props.match.params.restaurantId: this.state.selectedRestaurantId,
      client_id:this.props.match.params.clientId,
      shift_id: this.state.currentShift,
    };
    await this.props
      .retrieveTableReservationsExternal(data)
      .then((data) => {
        this.setState({
          tableList: data,
          isArrayOfTable: [],
        });
        //toast.success(this.props.t("messages.delsucmessage"));
        //this.props.history.push("/guests");
        //this.fetchReservation(this.state.page, this.state.perPage);
      })
      .catch((e) => {
        /* this.setState({
          loading: false,
        }); */
        alert("error");
      });

    this.setState({
      //loadingTable: false
      loading: false,
    });
  };


  render() {

    const { loadingTable, tableList , list_table_ids} = this.state;
    const {
      restaurants,
      reservations,
      reservationsSettings,
      reservationDateSettings,
      restaurantHours,
      notes,
      guests,
      // restaurantTables,
      message,
      currentReservation,
    } = this.props;
   console.log(tableList, "aaa");
    return (
        <>
        <h2>Table Reservation</h2>
        <p className="my-2">
        Notes: <br />
- In exceptional cases, you may be offered a different table.<br />
- If you would like to make a reservation after 4 p.m. for today, then call us on Tel:<a className="color-primary" href="tel:0041813842148">+41 81 384 21 48</a>.<br />
- There are always cancellations at short notice. So just give us a call – with a little luck it will work out after all...!<br />
- From 5 people, we ask you to contact us by email <a className="color-primary" href="mailto:scalottas@schweizerhof-lenzerheide.ch">scalottas@schweizerhof-lenzerheide.ch</a> or by phone <a className="color-primary" href="tel:0041813842148">+41 81 384 21 48</a> .<br />
        </p>
        {/* <Container> */}
            <Row>
                <Col>
                    {/* <h3>Basic example</h3> */}
                    
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header >1st date</Accordion.Header>
                                <Accordion.Body>
                                <div className="row px-4 py-3">
                                    <div className="col-lg-4 col-md-5">
                                        <div className="action-btn  form-inline-action d-flex ">
                                    
                                            <Form.Select
                                            name="resturantList"
                                            defaultValue={this.state.selectedRestaurantId}
                                            /* value={this.state.selectedRestaurantId} */
                                            className="form-control col-md-4"
                                            >
                                             {restaurants.list
                                                ? restaurants.list.map((r, index) => {
                                                    return (
                                                      <option
                                                        key={index}
                                                        value={r.id}
                                                        selected={
                                                          this.state.selectedRestaurantId ===
                                                          r.id
                                                            ? "selected"
                                                            : ""
                                                        }
                                                      >
                                                        {r.restaurant_name}
                                                      </option>
                                                    );
                                                  })
                                                : ""}
                                                            </Form.Select>

                                            <Form.Select
                                            name="person"
                                            defaultValue={this.state.selectedRestaurantId}
                                            /* value={this.state.selectedRestaurantId} */
                                            className="form-control col-md-4 ml-3"
                                            >
                                            <option key={1} value={1} selected={this.state.selectedRestaurantId === 1 ? "selected" : ""}> 1 {this.props.t("reservation.addForm.lablePerson")}</option>
                                            <option key={2} value={2} selected={this.state.selectedRestaurantId === 2 ? "selected" : ""}> 2 {this.props.t("reservation.addForm.lablePerson")}</option>
                                            <option key={3} value={3} selected={this.state.selectedRestaurantId === 3 ? "selected" : ""}> 3 {this.props.t("reservation.addForm.lablePerson")}</option>
                                            <option key={4} value={4} selected={this.state.selectedRestaurantId === 4 ? "selected" : ""}> 4 {this.props.t("reservation.addForm.lablePerson")}</option>       
                                            </Form.Select>

                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="row px-4 py-3">
                                    <div className="col-lg-4 col-md-5">
                                    <Calendar 
                                            value={new Date()}
                                            minDate={new Date()}
                                            />
                                          </div>
                                          </div>
                                <div className="row justify-content-center">
                                <div className="map">
                              <div
                                className=""
                                style={{
                                  position: "absolute",
                                  zIndex: 999,
                                  cursor: "pointer",
                                  background: "rgb(255 255 255)",
                                  padding: "6px 0px 6px 18px",
                                  borderRadius: "100%",
                                  top: "47%",
                                  left: "-3%",
                                  right: "0",
                                  width: "50px",
                                  height: "50px",
                                  fontSize: "28px",
                                }}
                              >
                                <a
                                  href="#!"
                                  className="text-dark"
                                  onClick={(e) => this.TableMap(e)}
                                  id="showTableMap"
                                >
                                  <i className="fa fa-angle-right"></i>
                                </a>
                              </div>

                              {this.state.restaurant ? (
                                <div
                                  className="mt-2"
                                  key={2}
                                  ref={(ref) => (this.container = ref)}
                                  style={{
                                    position: "relative",
                                    // border: "solid 2px #000",
                                    display: "inline-block",
                                    border: "2px solid rgb(0, 0, 0)",
                                    overflowX: "auto",
                                  }}
                                >
                                  <img
                                    ref={(ref) => (this.img = ref)}
                                    className="img-fluid"
                                    alt="restaurant"
                                    width="800"
                                    src={this.state.restaurant.table_map}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = ImgSrc;
                                    }}
                                  />
                                  <fieldset className="checkbox-group">
                                  {Object.keys(tableList).length !== 0
                                      ? _generateTablesForReservation(
                                          this.state.isArrayOfTable,
                                          tableList,
                                          this.state.table_ids,
                                          "1",
                                          "",
                                          this.state.isCurrentReservationData,
                                          this.onTableSelectChange,
                                          this.OnNextClick,
                                          this.OnNextPrevClick
                                        )
                                      : ""}
                                  </fieldset>
                                </div>
                              ) : (
                                <Oval
                                  ariaLabel="loading-indicator"
                                  height={100}
                                  width={100}
                                  strokeWidth={5}
                                  strokeWidthSecondary={1}
                                  color="#B54191"
                                  secondaryColor="white"
                                />
                              )}
                            </div>
                                </div>


                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. Personal Information</Accordion.Header>
                                <Accordion.Body>
                                <div className="row justify-content-center">
                                  <div className="col-xl-7 col-lg-8 col-md-12">
                                    <div className="mt-0">
                                      <div className="card border-0">
                                        <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                                        
                                        </div>
                                        <div className="card-body">
                                        <div className="form-group mb-20">
                                    
                                        
                                      </div>
                                        <Formik
                                        validationSchema={this.schema}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        enableReinitialize="true"
                                        onSubmit={(values) => {
                                          console.log(values);
                                          this.doSubmit(values);
                                        }}
                                        initialValues={{
                                            title: this.state.data.title,
                                            first_name: this.state.data.first_name,
                                            last_name: this.state.data.last_name,
                                            phone_number: this.state.data.phone_number,
                                            email: this.state.data.email,
                                            remark: this.state.data.remark,
                                        }}
                                      >
                                        {({
                                          handleSubmit,
                                          handleChange,
                                          handleBlur,
                                          values,
                                          touched,
                                          isValid,
                                          errors,
                                        
                                        }) => (
                                        
                                          <Form noValidate onSubmit={handleSubmit}>
                                          <Row className="mb-3">
                                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t(
                                                  "qrcodeBooking.addformstep3.addForm.lableTitle"
                                                )}
                                              </Form.Label>
                                              <Form.Select
                                                name="title"
                                                className="form-control col-md-8"
                                                onChange={handleChange}
                                                value={values.title}
                                                isInvalid={!!errors.title}
                                              >
                                              <option value="0">{this.props.t("user.addForm.usertitleOption.mr")}</option>
                                              <option value="1">{this.props.t("user.addForm.usertitleOption.mrs")}</option>
                                              </Form.Select>
                                              {console.log(errors)}
                                              <Form.Control.Feedback
                                                className="col-md-8 offset-4"
                                                type="invalid"
                                              >
                                                { this.props.t(errors.title)}
                                              </Form.Control.Feedback>
                                            </Row>
                                            <Row className="mb-3">
                                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t("qrcodeBooking.addformstep3.addForm.lablefirst")}
                                              </Form.Label>
                                              <Form.Control
                                                className="col-md-8"
                                                type="text"
                                                name="first_name"
                                                placeholder={this.props.t(
                                                  "qrcodeBooking.addformstep3.addForm.lablefirst"
                                                )}
                                                value={values.first_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.first_name}
                                              />
                                              <Form.Control.Feedback
                                                className="col-md-8 offset-4"
                                                type="invalid"
                                              >
                                                { this.props.t(errors.first_name)}
                                              </Form.Control.Feedback>
                                            </Row>
                                            <Row className="mb-3">
                                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t("qrcodeBooking.addformstep3.addForm.lablelast")}
                                              </Form.Label>
                                              <Form.Control
                                                className="col-md-8"
                                                type="text"
                                                name="last_name"
                                                placeholder={this.props.t(
                                                  "qrcodeBooking.addformstep3.addForm.lablelast"
                                                )}
                                                value={values.last_name}
                                                onChange={handleChange}
                                                isInvalid={!!errors.last_name}
                                              />
                                              <Form.Control.Feedback
                                                className="col-md-8 offset-4"
                                                type="invalid"
                                              >
                                                  { this.props.t(errors.last_name)}
                                              
                                              </Form.Control.Feedback>
                                            </Row>
                                            
                                            <Row className="mb-3">
                                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t("qrcodeBooking.addformstep3.addForm.lableEmail")}
                                              </Form.Label>
                                              <Form.Control
                                                className="col-md-8"
                                                name="email"
                                                placeholder={this.props.t(
                                                  "qrcodeBooking.addformstep3.addForm.lableEmail"
                                                )}
                                                value={values.email}
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                                type="email"
                                                autoComplete="new-password"
                                              />
                                              <Form.Control.Feedback
                                                className="col-md-8 offset-4"
                                                type="invalid"
                                              >
                                              { this.props.t(errors.email)}
                                              </Form.Control.Feedback>
                                            </Row>
                                            <Row className="mb-3">
                                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t("qrcodeBooking.addformstep3.addForm.lableMobile")}
                                              </Form.Label>
                                              <Form.Control
                                                className="col-md-8"
                                                name="phone_number"
                                                placeholder={this.props.t(
                                                  "qrcodeBooking.addformstep3.addForm.lableMobile"
                                                )}
                                                value={values.phone_number}
                                                onChange={handleChange}
                                                isInvalid={!!errors.phone_number}
                                                type="text"
                                              />
                                              <Form.Control.Feedback
                                                className="col-md-8 offset-4"
                                                type="invalid"
                                              >
                                              { this.props.t(errors.phone_number)}
                                              </Form.Control.Feedback>
                                            </Row>
                                            <Row className="mb-3">
                                            <div className="col-md-4 d-flex align-items-center">
                                              <Form.Label className="color-dark fs-14 fw-600 align-center ant-form-item-required">
                                                {this.props.t("guest.addForm.lableRemarks")}
                                              </Form.Label>
                                            </div>
                                            
                                              <Form.Control className="col-md-8"
                                                name="remarks"
                                                placeholder={this.props.t(
                                                  "guest.addForm.lableRemarks"
                                                )}
                                                value={values.remarks}
                                                onChange={handleChange}
                                                isInvalid={!!errors.remarks}
                                                as="textarea"
                                                rows={3}
                                              />
                                              <Form.Control.Feedback  className="col-md-8 offset-4" type="invalid">
                                                {errors.hint_text}
                                              </Form.Control.Feedback>
                                          
                                          </Row>
                                          

                                                  
                                                                                                
                                            <div className="row mt-4">
                                              <div className="col-md-4 offset-4 p-0">
                                                <button
                                                className="btn btn-primary btn-default btn-squared mr-15 lh-normal px-40 py-15 signIn-createBtn "
                                                disabled={this.state.loading}
                                                >
                                                {this.state.loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>{this.props.t('qrcodeBooking.addformstep3.buttonSave')}</span>
                                                </button>
                                            </div>
                                          </div>
                                          </Form>
                                        )}
                                      </Formik>

                                        
                                        </div>
                                      </div>
                                    {/* <p className="social-connector text-right mb-sm-25 mb-15  mt-sm-30 mt-20">
                                        <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                                    </p> */}
                                    </div>
                                  </div>
                                </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>  
                    
                </Col>
            </Row>
        {/* </Container> */}
    </>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state, "aaa");
  return {
    restaurants: state.restaurants,
    reservations: state.reservations,
    configurations: state.configurations,
    restaurantHours: state.restaurantHours,
    restaurantTables: state.restaurantTables,
    //restaurantTables: state.restaurantTables,
    reservationsSettings: state.reservationsSettings,
    reservationsDateSettings: state.reservationsDateSettings,
    retrieveTableReservations: state.retrieveTableReservations,
    currentReservation: state.currentReservation,
    message: state.message,
    notes:state.notes,
    guests: state.guests,
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, {
    retrieveReservations,
    addWalkInReservation,
    retrieveRestaurantsExternal,
    getRestaurantExternal,
    retrieveConfigurationsExternal  ,
    retrieveRestaurantsHours,
    getReservationCalenderdatesExternal,
    retrieveRestaurantsTablesExternal,
    retrieveReservationRestaurantHoursExternal,
    deleteReservation,
    deleteReservationMultiple,
    retrieveTableReservationsExternal,
    getReservation,
    makeGroupReservation,
    createNote,
    UpdateNote,
    retriveNotes,
    uploadPdfNotes,
    clearMessage,
    retrieveGuestsQr,
    changeStatusReservation,
    getNextPrevreservation,
  })
)(ReservationCopy);
