import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
//import CommonHelper from "./../utils/CommonHelper";
// import Joi from "joi-browser";
// import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Message from "../common/message";
import { Row,  Form } from "react-bootstrap";
//import { updatePassword } from "../../actions/auth-user";
// import { _pre } from "../../utils/vlogs";
//import { ToastContainer, toast } from "react-toastify";
import { toast } from "react-toastify";
import { withTranslation  } from 'react-i18next';
import { verifyCode, getRestaurantByTableId } from "../../actions/qr-booking";
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookie from 'universal-cookie';


class QrBookingStep2 extends Component {
  constructor(props) {
    super(props);
 

    this.state = {
      code: "",
      loading: false,
      restaurant:{},
    };
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      const cookies = new Cookie();
      this.getRecord(parseInt(this.props.match.params.id.substring(4)));
      let status = cookies.get('is_verified');
      let step = cookies.get('step');
      if(status){
        this.props.history.push("/qr-booking/step" + step + "/tid=" +parseInt(this.props.match.params.id.substring(4)));
      }
    }
    //
  }

  getRecord(id){
    this.setState({
      loading: true,
    });
    let values = {
      table_id: id,
    };
    this.props
      .getRestaurantByTableId(values)
      .then((data) => {
        console.log(data.data)
        if (data.status) {
          // const { detail: currentRecord } = data;
          this.setState({
            restaurant: data.data,
          });
        } else {
          toast.error(data.message);
          // this.props.history.push("/restaurants");
        }
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        // console.log(e);
        this.setState({
          loading: false,
        });
      });
  }
  schema =  yup.object().shape({
    
    code: yup.string().required('qrcodeBooking.addformstep2.validation.code'),
 
    });

    async doSubmit(values){
 // console.log(values);
      // console.log(this.props.match.params.id);
      values.reservation_id = parseInt(this.props.match.params.id.substring(4));
      values.phone =  localStorage.getItem("phone");
      //resetForm({ values: "" });
      //console.log(values);
      //this.props.switch();
      //this.props.reservationList();
      //this.props.switch();
      //return true;
      await this.props
        .verifyCode(values)
        .then((data) => {
          // console.log(data, "sssssssssssssss");
          // //console.log(data.status, data.message);
          if (data.status) {
            toast.success(data.message);
            const cookies = new Cookie();
            cookies.set('is_verified', true, { path: '/' })
            cookies.set('step', 3, { path: '/' })
            this.props.history.push("/qr-booking/step3/tid=" +parseInt(this.props.match.params.id.substring(4)));
          } else {
            toast.error(data.message);
            this.props.history.push("/qr-booking/step1/tid=" +parseInt(this.props.match.params.id.substring(4)));
          }
          this.setState({
            loading: false,
          });
        })
        .catch((e) => {
          //console.log(e);
          this.setState({
            loading: false,
          });
          toast.error(e.response.data.message);
          // console.log(e.response.data,"ddddddddddddddddddddd");
        });
      
      

    }
    
 
  render() {
    const { message } = this.props;
    const { restaurant } = this.state;
    console.log(this.state);
    if(restaurant){
    return (
      <div className="signUP-admin1">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 card my-3">
              <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                {/* <div className="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1">
                  <p className="mb-0">
                    <img
                      src="img/logos/logo-header.png"
                      alt="404"
                      className="svg"
                    />
                  </p>
                </div> */}
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-8 col-md-12">
                    <div className="mt-0">
                      <div className="card border-0">
                        <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                          <div className="edit-profile__title">
                            <h6>
                            {this.props.t('qrcodeBooking.step2title')}{" "}
                           
                            </h6>
                            <p className="font-weight-bold">  {this.props.t('qrcodeBooking.table')} {restaurant.table_number} / {restaurant.restaurant_name}</p>
                          </div>
                        </div>
                        <div className="card-body">
                        <div className="form-group mb-20">
                        <Message message={message} />
                        
                       </div>
                        <Formik
                        validationSchema={this.schema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize="true"
                        onSubmit={(values) => {
                            this.doSubmit(values);
                        }}
                        initialValues={{
                            code: this.state.code,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-3">
                          
                              <Form.Label className="col-md-4 color-dark fs-14 fw-600 align-center ant-form-item-required">
                                {this.props.t(
                                  "qrcodeBooking.addformstep2.lablecode"
                                )}
                              </Form.Label>

                              <Form.Control
                                className="col-md-8"
                                type="text"
                                name="code"
                                placeholder={this.props.t(
                                  "qrcodeBooking.addformstep2.lablecode"
                                )}
                                value={values.code}
                                onChange={handleChange}
                                isInvalid={!!errors.code}
                              />
                              <Form.Control.Feedback
                                className="col-md-8 offset-4"
                                type="invalid"
                              >
                                { this.props.t(errors.code)}
                              </Form.Control.Feedback>
                             
                            </Row>
                                                    
                            <div className="row mt-4">
                              <div className="col-md-4 offset-4 p-0">
                                <button
                                className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-40 py-15 signIn-createBtn "
                                disabled={this.state.loading}
                                >
                                {this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>{this.props.t('qrcodeBooking.addformstep2.buttonContinue')}</span>
                                </button>
                            </div>
                          </div>
                          </Form>
                        )}
                      </Formik>

                        
                        </div>
                      </div>
                    {/* <p className="social-connector text-right mb-sm-25 mb-15  mt-sm-30 mt-20">
                        <span>© 2014-2022 by 3W TECHNOLOGIES</span>
                    </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }else{
    return(
      <>
      </>
    )
  }
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { message } = state.message;

  return {
    user,
    message,
  };
}

//export default connect(mapStateToProps)(AddGuestUser);
export default compose(withTranslation() ,connect(mapStateToProps, {
  verifyCode,
  getRestaurantByTableId
  //deleteAllTutorials,
}))(QrBookingStep2);

